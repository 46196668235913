<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="apiMng_apiLogDetail" :checkRole="manageRole" />
      <div class="item_info">
        <div class="list_wrap">
          <div class="table_list">
            <div class="item_table radiusbox data_detail_tbl">
              <table style="margin: 0">
                <colgroup>
                  <col width="15%" />
                  <col width="35%" />
                  <col width="15%" />
                  <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>로그 시간</th>
                    <td>{{ logData.created }}</td>
                    <th>결과</th>
                    <td>{{ logData.apiResultVal }}</td>
                  </tr>
                  <tr>
                    <th>IP</th>
                    <td>{{ logData.ip }}</td>
                    <th>Browser</th>
                    <td>{{ logData.browser }}</td>
                  </tr>
                  <tr>
                    <th>API 명</th>
                    <td>{{ logData.apiName }}</td>
                    <th>API 유형</th>
                    <td>{{ logData.apiType }}</td>
                  </tr>
                  <tr>
                    <th>API 주소</th>
                    <td>{{ logData.apiUrl }}</td>
                    <th>API Logger 주소</th>
                    <td>{{ logData.apiLoggerUrl }}</td>
                  </tr>
                  <tr>
                    <th>Parameter</th>
                    <td>{{ logData.apiParameter }}</td>
                    <th>API 처리 용량</th>
                    <td>{{ logData.apiVolume }}</td>
                  </tr>
                  <tr>
                    <th>사용자 ID</th>
                    <td>{{ logData.memberEmail }}</td>
                    <th>사용자 이름</th>
                    <td>{{ logData.memberName }}</td>
                  </tr>
                  <tr>
                    <th>Division</th>
                    <td>{{ logData.divisionNam }}</td>
                    <th>Team</th>
                    <td>{{ logData.teamName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";

export default {
  name: "apiManageList",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
  },
  data() {
    return {
      logData: {},
    };
  },
  created() {},
  mounted() {
    this.setRole();
    const logId = this.$route.params.logId;

    this.setApiDetail(logId);
  },

  methods: {
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },
    async setApiDetail(logId) {
      try {
        const response = await common.apiGet("apilog/" + logId);
        this.logData = response.data.data;
      } catch (error) {
        console.error("error", error);
      }
    },
    //공유하기 버튼 클릭시 url 복사
    async clipCopy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.data_detail_tbl {
  tr {
    &:first-child {
      & > th,
      & > td {
        border-top: 0;
      }
    }
    & > th,
    & > td {
      border: 1px solid #e5e9ef;
      &::after {
        display: none !important;
      }
    }
    & > th {
      border-left: 0;
    }
    & > td {
      text-align: left;
      padding: 20px !important;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
</style>
