<template>
  <div
    :id="
      otherProp != null ? 'timelineSection_' + otherProp.key : 'timelineSection'
    "
  >
    <div class="radiusbox list_box">
      <div class="title_box">
        <strong>선택 구간</strong>
      </div>
      <div class="mando_table_wrap">
        <table class="mando_table select_section">
          <colgroup>
            <col style="width: 55px" />
            <col style="width: 100px" />
            <col style="width: 100px" />
            <col style="width: 50px" />
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>Start time</th>
              <th>End time</th>
              <th></th>
            </tr>
          </thead>
          <tbody ref="recListWrap">
            <tr
              class=""
              v-for="(item, index) in recList"
              :key="index"
              :data-start-value="item.start_time_value"
              :data-end-value="item.end_time_value"
            >
              <!-- :data-st="item.start_time" -->
              <td
                @click="selectStartTime($event)"
                :style="{ color: item.record_color }"
              >
                <div
                  class="list_item_icon"
                  :style="{ borderColor: item.record_color }"
                >
                  <div :style="{ borderLeftColor: item.record_color }"></div>
                </div>
                {{ item.no }}
              </td>
              <td @click="selectStartTime($event)">
                {{ item.start_timestamp }}
              </td>
              <td @click="selectStartTime($event)">
                {{ item.end_timestamp }}
              </td>
              <td>
                <button
                  type="button"
                  class="btn_evt_group"
                  @click="btnEvtGroup($event)"
                ></button>
                <ul class="evt_btn_box radiusbox">
                  <li
                    class="copy_item"
                    @click="
                      copyTextTime($event, item.start_time, item.end_time)
                    "
                  >
                    <button type="button">키 복사</button>
                  </li>

                  <li class="delete_item">
                    <button type="button" @click="deleteDriRec(item.no)">
                      삭제
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineSection",
  computed: {},
  props: {
    otherProp: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      color: [
        "#80BDFF",
        "#409DFF",
        "#187BFF",
        "#0F5CBF",
        "#073E80",
        "#B7A1E0",
        "#9371D1",
        "#6F42C1",
        "#533191",
        "#382261",
        "#EE9AA2",
        "#E56874",
        "#DC3545",
        "#A52833",
        "#6E1A23",
        "#94D3A2",
        "#5EBD74",
        "#27A745",
        "#1F7D35",
        "#145423",
        "#FDBE89",
        "#FE9F4F",
        "#FD7E14",
        "#BE5F0F",
        "#7F3F0B",
        "#DDD8D6",
        "#CBC5C2",
        "#BAB1AD",
        "#8C8582",
        "#5D5957",
        "#B388F9",
        "#8C4CF5",
        "#660FF2",
        "#4D0DB6",
        "#330879",
        "#F49FC6",
        "#EE6EA9",
        "#E83E8C",
        "#AE2F69",
        "#741F46",
        "#FEE083",
        "#FED145",
        "#FEC106",
        "#C09106",
        "#806106",
        "#90E4CB",
        "#5AD7B1",
        "#20C997",
        "#189771",
        "#10654C",
        "#8BD1DC",
        "#51B9CA",
        "#17A2B8",
        "#117A8A",
        "#0D525B",
      ],
      timeSet: {
        max: "",
        HH: "00",
        MM: "00",
        SS: "00",
        ss: "00",
      },
      recList: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.emitter.on("recList", (a) => {
      console.log(a);
      this.recList = a;
    });
  },
  methods: {
    btnEvtGroup(e) {
      const $target = e.target;
      if ($target.classList.contains("active")) {
        $target.classList.remove("active");
        $target.nextElementSibling.style.display = "none";
      } else {
        $target.classList.add("active");
        $target.nextElementSibling.style.display = "block";
      }
    },
    //-- 선택 구간 복사
    copyTextTime(e, st, et) {
      e.target
        .closest(".evt_btn_box")
        .previousElementSibling.classList.remove("active");
      e.target.closest(".evt_btn_box").style.display = "none";
      const textToCopy = "Start Time : " + st + ", End Time : " + et;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
    async deleteDriRec(itemId) {
      /*
      this.recList.forEach((element) => {
        if (element.no != itemId) {
          this.recList.push(element);
        }
      });
      */
      this.recList.splice(itemId - 1, 1);
      this.recList.forEach((element) => {
        if (element.no >= itemId) {
          element.no--;
        }
      });
      this.sectionCnt--;
      const vm = this;
      const $recListWrap = vm.$refs.recListWrap;
      const $recListGB = $recListWrap.querySelectorAll(".btn_evt_group");
      const $recListBB = $recListWrap.querySelectorAll(".evt_btn_box");
      $recListGB.forEach((element) => {
        element.classList.remove("active");
      });
      $recListBB.forEach((element) => {
        element.style.display = "none";
      });
    },
    // --- 선택 구간 선택 시작 시간 이동
    selectStartTime(e) {
      const startTime = e.target.closest("tr").getAttribute("data-start-value");
      this.emitter.emit("selecTime", startTime);
    },
    timeStringToMilliseconds(timeString) {
      // HH:MM:SS:ss > ss
      if (timeString != undefined) {
        const [hours, minutes, seconds, milliseconds] = timeString
          .split(":")
          .map(Number);
        const totalMilliseconds =
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000 +
          milliseconds * 10;
        return totalMilliseconds;
      }
    },
  },
};
</script>
<style scoped lang="scss">
div {
  &.list_box {
    width: 100%;
    &.task_list {
      table {
        table-layout: fixed;
      }
    }
    .mando_table_wrap {
      margin: 0;
      height: 268px;
    }
  }
  &:nth-child(1) {
    /*margin-bottom: 20px;*/
  }
  &:nth-child(2) {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  &:nth-child(3) {
    // margin-right: 20px;
  }
  &:nth-child(4) {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .title_box {
    width: 100%;
    height: 40px;
    position: relative;
    box-sizing: border-box;
    padding: 20px 20px 0;
    strong {
      font-weight: $bold;
    }
    .setting_icon {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
  .mando_table {
    th {
      &:first-child {
        min-width: 100px;
      }
    }
  }
  .select_section {
    table-layout: fixed;
    th,
    td {
      padding: 10px 0;
    }
    td {
      position: relative;
      vertical-align: middle;
      &:first-child {
        font-weight: $bold;
      }
      .btn_evt_group {
        width: 18px;
        height: 18px;
        background-size: cover;
        position: relative;
        top: -2px;
        margin-left: 0;
        &.active {
          background-image: url($baseURL + "/common/moremenu_list_click.svg");
        }
      }
      .evt_btn_box {
        right: 10px;
      }
      .list_item_icon {
        width: 13px;
        height: 13px;
        display: inline-block;
        border: 1px solid;
        border-color: #000;
        border-radius: 7px;
        position: relative;
        top: 2px;
        overflow: hidden;
        & > div {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          border-left: 4px solid #000;
          border-right: 3px solid transparent;
          position: absolute;
          top: 4px;
          left: 5px;
        }
      }
    }
  }
}
</style>
