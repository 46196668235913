<template>
  <div class="table">
    <table class="mando_table">
      <colgroup></colgroup>
      <thead>
        <tr>
          <template v-if="tblThData && tblThData.length > 0">
            <th v-for="(item, index) in tblThData" :key="index">
              <div class="col_name">
                {{ item }}
              </div>
            </th>
          </template>
          <template v-else>
            <th>No Data</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="tblTdData && tblTdData.length > 0">
          <tr v-for="(item_01, index_01) in tblTdData" :key="index_01">
            <td v-for="(item_02, index_02) in item_01" :key="index_02">
              {{ item_02 }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>No data</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableController",
  computed: {},
  props: {
    tblData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      tblAllData: [],
      tblThData: [],
      tblTdData: [],
    };
  },
  watch: {
    tblData() {
      this.createTable();
    },
  },
  created() {},
  mounted() {
    if (this.tblData != null) {
      this.createTable();
    }
  },
  methods: {
    createTable() {
      this.tblAllData = this.tblData;
      //console.log("tblAllData :: ", this.tblAllData);
      this.tblThData = this.tblAllData[0];
      this.tblAllData.shift();
      this.tblTdData = this.tblAllData;
    },
  },
};
</script>
<style scoped lang="scss">
.table {
  .mando_table {
    position: static !important;
    thead {
      th {
        border-top: 1px solid #e5e9ef;
      }
    }
  }
}
</style>
