<template>
<div class="toggle_background" :style="{background:bgColor}">
  <div class="first">
    <div class="fir_1">{{labelText}}</div>
    <div class="fir_2">
      <VueToggles
      :value="value"
      :height="30"
      :width="90"
      checkedText="On"
      uncheckedText="Off"
      :checkedBg="OnColor"
      :uncheckedBg="OffColor"
      @click="value = !value"
      style="flex:1"
      />
    </div>
  </div>
  <div class="second">{{contentText}}</div>
</div>
</template>

<script>
import { VueToggles } from "vue-toggles";

export default {
    components: {
        VueToggles
     },
     props: {
      otherProp: {
        type: Object,
        required: true,
      },
      readOnlyProp:{
        type: Boolean,
        require:true,
      },
      handlerList:{
        type:Object,
        require:true
      },
      componentIndex:{
        type:Number
      },
      keyValueList:{
        type:Object
      },
      keyValueType:{
        type:String
      }
    },
    data() {
        return {
            labelText:'',
            handlerKey :'',
            color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
            picStartDate: null,
            picEndDate: null,
            start_cal_key:0,
            end_cal_key:0,
            picStartDateCalendar: null,
            picEndDateCalendar: null,
            picColor: {
                startStatus: false,
                endStatus: false,
                active: "#00B4ED",
                default: "#020A0A",
            },
            onlyRead : false,
            remove : false,
            DataMapCateGory:'Data',
            parentHandlerKey : '',
            barKeyValueType:'',
            inputKey:'',
            inputValue:'',
            barKeyValueList:[],
            actionType:'',
            bgColor:'#ff0000',
            bgColorPicker:false,
            OnColor:'#ff0000',
            OnColorPicker:false,
            OffColor:'#ff0000',
            OffColorPicker:false,
            parameter: '',
            api:'',
            scriptCode:'',
            isOn: false,
            contentText:''

        };
    },
    mounted(){
        const vm = this;
        const prop = this.otherProp;
        vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
        vm.handlerKey = prop.handlerKey; //handlerKey
        if(vm.parentHandlerKey!==undefined){
          vm.parentHandlerKey = prop.parentHandlerKey; // 부모키 
        }
        if(prop.actionType!=null){
          vm.actionType = prop.actionType;
        }
        if(prop.bgColor!=null){
          vm.bgColor = prop.bgColor;
        }
        if(prop.OnColor != null){
          vm.OnColor = prop.OnColor;
        }
        if(prop.OffColor !=null){
          vm.OffColor = prop.OffColor;
        }
        if(prop.parameter!=null){
          vm.parameter = prop.parameter
        }
        if(prop.api!=null){
          vm.api = prop.api;
        }
        if(prop.scriptCode!=null){
          vm.scriptCode = prop.scriptCode;
        }
        if(prop.labelText!=''){
          vm.labelText = prop.labelText;
        }
        if(prop.contentText){
          vm.contentText = prop.contentText;
        }

   
    },
    methods:{
      updateParent(event){
        this.$emit('updateParent',{index:this.componentIndex, value:event.target.value})
        console.log(this.handlerList)
     },
      labelTextChange(event){
      this.labelText =  event.target.value;
      const item  = this.otherProp;
      item.labelText = this.labelText;
      },
      removeItem(){
        this.$emit('removeItem');
      },
      setRepresent(){
        this.isOn = !this.isOn
      }
    },
    watch:{
      actionType(){
       const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      },
      bgColor(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
          
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      
      },
      OnColor(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      
      },
      OffColor(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      

      },
      parameter(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      

      },
      api(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      

      },
      scriptCode(){
        const object = {
          actionType: this.actionType,
          bgColor : this.bgColor,
          OnColor : this.OnColor,
          OffColor : this.OffColor,
          parameter : this.parameter,
          api : this.api ,
          scriptCode: this.scriptCode,
          contentText:this.contentText,
          lbaelText: this.labelText
        }
        this.$emit('updateButton',{ index:this.componentIndex ,value:object})
      

      },
    }
}
</script>

<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height:100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    margin-bottom:15px;
    width: 100%;
    height: 100%;
    display: flex;
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first{
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }
}
.labelText{
    width: 50%;
    height: 100%;
    line-height: 45px;
    box-sizing: border-box;
    border: 1px solid #D5DAE3;
    border-radius: 10px;
    background-color: #fff;
    font-size: 15px;
    padding: 10px 15px;
}

.range{
    width: 25%;
    height: 100%;
    line-height: 45px;
    box-sizing: border-box;
    border: 1px solid #D5DAE3;
    border-radius: 10px;
    background-color: #fff;
    font-size: 15px;
    padding: 10px 15px;
}
.toggle_background{
  flex: 1;
}
.first{
  width: 100%;
  
}
.second{
  margin-top: 5%;
  width: 100%;
  
}
.fir_1{
  text-align: center;
}
.fir_2{

  margin-left: 10%;
}
</style>


