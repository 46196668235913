<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_modelPerformanceMonitoring"
        :name="'[TASK_AD] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <!-- <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li> -->
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div class="item_search">
          <select v-model="edgeId" @change="chgEdgeId">
            <option value="0">Edge ID</option>
            <option
              v-for="(item, index) in searchData"
              :key="index"
              :value="item.edge_id"
            >
              {{ item.edge_id }}
            </option>
          </select>
          <select v-model="searchSite">
            <option value="" selected>Site</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.site"
            >
              {{ item.site }}
            </option>
          </select>
          <select v-model="searchWc">
            <option value="">WC</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.wc"
            >
              {{ item.wc }}
            </option>
          </select>
          <select v-model="searchModel">
            <option value="">Model</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.models"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <select v-model="searchPoint">
            <option value="">Point</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.points"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <button type="button" class="mcbtn" @click="setMonitoringData">
            선택
          </button>
          <a href="#" class="mcbtn" v-if="selectedId !== 0">재배포</a>
        </div>
        <div id="monCont" class="contents">
          <div class="radiusbox">
            <div class="mando_table_wrap">
              <table class="mando_table">
                <thead>
                  <tr>
                    <th>Pipeline</th>
                    <th>직행률</th>
                    <th>운영 시간</th>
                    <th>Last Run Date</th>
                    <th>Last Update</th>
                    <th>상세 보기</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="monitoringData && monitoringData.length > 0">
                    <tr
                      v-for="(item, index) in monitoringData"
                      :key="index"
                      style="cursor: pointer"
                      @click="setPipelineId(item.pipeline_id)"
                    >
                      <td>{{ item.pipeline_name }}</td>
                      <td>{{ transNumberFormat(item.fpy * 100) }}%</td>
                      <td>
                        {{
                          item.operation_time
                            .replace("D", "일")
                            .replace("H", "시간")
                            .replace("M", "분")
                            .replace("S", "초")
                        }}
                      </td>
                      <td>{{ item.last_run_date }}</td>
                      <td>{{ item.update_date }}</td>
                      <td>
                        <button
                          class="mcbtn"
                          @click="goPage($event, item.pipeline_id)"
                        >
                          이동
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="6">데이터가 없습니다.</td>
                  </template>
                </tbody>
              </table>
              <div class="list_wrap" style="margin-top: -10px">
                <div class="table_list">
                  <div class="list_table_footer">
                    <!-- <div>
                      <div>Rows per page</div>
                      <select name="" id="">
                        <option value="">10</option>
                        <option value="">30</option>
                        <option value="">50</option>
                        <option value="">100</option>
                      </select>
                    </div> -->
                    <PaginationUi
                      :totalItems="totalItems"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="onPageChanged"
                    ></PaginationUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import common from "@/assets/js/common";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import apiVision from "@/assets/js/apiVision";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    PaginationUi,
  },
  data() {
    return {
      taskId: "",
      prjId: "",
      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      edgeDeviceList: [],
      edgeId: 0,
      searchData: [],
      searchSite: "",
      searchWc: "",
      searchModel: "",
      searchPoint: "",

      findSearchSite: "",
      findSearchWc: "",
      findSearchModel: "",
      findSearchPoint: "",

      monitoringData: [],

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,
      selectedId: 0,
    };
  },
  computed: {},
  async mounted() {
    const loading = this.$refs.lodingWrap;
    loading.style.display = "block";
    // Role
    await this.setRole();
    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();
    this.setTaskInfo();
    await this.getEdgeDeviceList();
    await this.getSearchItem();
    await this.getMonitoringData();
    loading.style.display = "none";
  },
  methods: {
    goPage(e, id) {
      console.log(id);
      e.stopPropagation();
    },
    setPipelineId(id) {
      this.selectedId = id;
    },
    transNumberFormat(targetNumber) {
      const number = targetNumber;
      let transNumber = number;
      const splitNum = number.toString().split(".")[1];
      if (splitNum != undefined && splitNum.length > 4) {
        transNumber = number.toFixed(5);
      }
      return transNumber;
    },
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getMonitoringData();
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    // ----- API\
    async setMonitoringData() {
      if (!this.searchSite) {
        alert("Site를 선택해 주세요.");
        return false;
      }

      if (!this.searchWc) {
        alert("WC를 선택해 주세요.");
        return false;
      }

      if (!this.searchModel) {
        alert("Model을 선택해 주세요.");
        return false;
      }

      if (!this.searchPoint) {
        alert("Point를 선택해 주세요.");
        return false;
      }
      this.findSearchSite = this.searchSite;
      this.findSearchWc = this.searchWc;
      this.findSearchModel = this.searchModel;
      this.findSearchPoint = this.searchPoint;
      await this.getMonitoringData();
    },
    async getMonitoringData() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";

      /*
      const param = {
        site: 6,
        wc: 8,
        point: "P3",
        model: "4",
        offset: 0,
        limit: 10,
      };

      */
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      const param = {
        site: this.findSearchSite,
        wc: this.findSearchWc,
        point: this.findSearchModel,
        model: this.findSearchPoint,
        offset: offset,
        limit: limit,
      };

      try {
        const response = await apiVision.post(
          "/vision/vision_ml_performance_data/",
          param
        );
        this.monitoringData = [...response.data.data];
        this.totalItems = response.data.total;
        this.listOffset = response.data.current_offset;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      loading.style.display = "none";
    },
    async getSearchItem() {
      const array = [];
      for (let item of this.edgeDeviceList) {
        array.push(item.id);
      }
      const param = {
        edgeids: array,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_check_edge/",
          param
        );
        this.searchData = [...response.data.edge];
        console.log("this.searchData :: ", this.searchData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },
    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.projectData = await getPjtData.project;
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_search {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 12px 0 20px;
  select {
    min-width: 170px;
    background-position: center right 15px;
  }
  button {
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
}
.contents {
  .mando_table {
    position: static;
    a,
    button {
      display: inline-block;
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .list_wrap {
    .table_list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: none;
      .list_table_footer {
      }
    }
  }
}
</style>
