<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="sysOps_portlet" :checkRole="manageRole" />
      <div class="port_wrap" v-show="isloaded">
        <div class="top_control">
          <select
            name="prjType"
            ref="prjType"
            :value="prjData.prjType"
            @change="setPrjType($event)"
          >
            <option value="" disabled selected>선택해주세요.</option>
            <option
              v-for="(item, index) in prjTypeList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <select @change="(e) => (customYn = e.target.value)">
            <option :value="null">Layout 개인화 가능여부</option>
            <option :value="'Y'">Layout 개인화 가능</option>
            <option :value="'N'">Layout 개인화 불가능</option>
          </select>
          <input
            type="text"
            :value="templateName"
            @change="(e) => (templateName = e.target.value)"
            placeholder="템플릿 저장 이름을 입력해주세요."
            class="tempname"
          />
          <button class="final_save" @click="finalSave">레이아웃 저장</button>
          <button
            class="load_btn"
            @click="
              () => {
                onload = true;
                isloaded = false;
              }
            "
          >
            Load
          </button>
        </div>
        <div class="component_pannel">
          <button @click="() => addItem(Component1)" class="mcbtn">
            Filter
          </button>
          <button @click="() => addItem(Component2)" class="mcbtn">
            Button
          </button>
          <button @click="() => addItem(Component3)" class="mcbtn">Map</button>
          <button @click="() => addItem(Component4)" class="mcbtn">
            Label
          </button>
          <button @click="() => addItem(Component5)" class="mcbtn">
            Timeline
          </button>
          <button @click="() => addItem(Component6)" class="mcbtn">
            Remote
          </button>
          <button @click="() => addItem(Component7)" class="mcbtn">
            Video
          </button>
          <button @click="() => addItem(Component8)" class="mcbtn">
            Chart
          </button>
          <button @click="() => addItem(Component9)" class="mcbtn">
            Table
          </button>
        </div>
        <div class="rightCell">
          <grid-layout
            v-model:layout.sync="layout"
            :col-num="8"
            :row-height="30"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :vertical-compact="true"
            :use-css-transforms="true"
          >
            <grid-item
              v-for="item in layout"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :key="item.key"
            >
              <span class="text">{{ itemTitle(item) }}</span>
              <span class="remove" @click="removeItem(item.key)">x</span>
              <span class="setting_button" @click="settingOpen(item)"
                >설정</span
              >
              <component
                v-if="item && item.i && item.key"
                :is="
                  item.i === 'Filter'
                    ? 'FilterComponent'
                    : item.i === 'Button'
                    ? 'ButtonComponent'
                    : item.type === 'Map'
                    ? 'MapController'
                    : item.type === 'Label'
                    ? 'LabelComponent'
                    : item.type === 'Timeline'
                    ? 'TimelineController'
                    : item.type === 'Remote'
                    ? 'RemoteController'
                    : item.type === 'Video'
                    ? 'VideoController'
                    : item.type === 'Chart'
                    ? 'ChartController'
                    : item.type === 'Table'
                    ? 'TableController'
                    : null
                "
                :key="item.key"
                :interface="item"
                :otherProp="item"
              ></component>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <div class="setting_pop">
        <div class="setting_pop_cell">
          <div class="setting_pop_cell_x" @click="optionClose">X</div>
          <h4>{{ comp_name }}</h4>

          <component
            :is="
              this.settingRender === 'Filter'
                ? 'FilterComponentSetting'
                : this.settingRender === 'Button'
                ? 'ButtonComponentSetting'
                : this.settingRender === 'Map'
                ? 'MapControllerSetting'
                : this.settingRender === 'Label'
                ? 'LabelCompentSetting'
                : this.settingRender === 'Timeline'
                ? 'TimelineControllerSetting'
                : this.settingRender === 'Remote'
                ? 'RemoteControllerSetting'
                : this.settingRender === 'Video'
                ? 'VideoControllerSetting'
                : this.settingRender === 'Chart'
                ? 'ChartControllerSetting'
                : this.settingRender === 'Table'
                ? 'TableControllerSetting'
                : null
            "
            :interface="1"
            :otherProp="2"
          ></component>

          <div class="setting_pop_cell_bottom_button_box">
            <button>수정</button><button @click="optionClose">취소</button>
          </div>
        </div>
      </div>
      <div class="list_wrap" v-show="onload">
        <div class="table_list">
          <div class="table_set">
            <div class="cont_count">
              <span ii="cont_num">{{ allData.length }}</span> Templates
            </div>
            <div>
              <select
                style="width: 585px; background-position: 555px center"
                name="prjType"
                ref="prjType"
                :value="prjData.prjType"
                @change="setPrjType($event)"
              >
                <option value="">Project Type을 선택해주세요.</option>
                <option
                  v-for="(item, index) in prjTypeList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mando_table_wrap">
            <table class="mando_table">
              <!-- <colgroup>
                                <col style="width: 80%;">
                                <col style="width: 20%;">
                            </colgroup> -->
              <thead>
                <tr>
                  <th>
                    <div class="col_name">Template Name</div>
                  </th>
                  <th>
                    <div class="col_name">Project Type</div>
                  </th>
                  <th>
                    <div class="col_name">작성자</div>
                  </th>
                  <th>
                    <div class="col_name">Team</div>
                  </th>
                  <th>
                    <div class="col_name">Create Date</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="m_pointer"
                  v-for="(item, index) in dataListSliced"
                  :key="index"
                  @click="loadingTemplate(item)"
                >
                  <td>
                    {{
                      item.name === "" || item.name === null
                        ? "Saved Data"
                        : item.name
                    }}
                  </td>
                  <td>
                    {{
                      prjTypeList.find((ele) => ele.id === Number(item.type))
                        ?.name || ""
                    }}
                  </td>
                  <td>
                    {{ item.createdNm == null ? "김만도" : item.createdNm }}
                  </td>
                  <td>
                    {{
                      item.createdTeamNm == null
                        ? " M2MCx / System"
                        : item.createdTeamNm
                    }}
                  </td>
                  <td>{{ item.created }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list_table_footer">
            <PaginationUi
              :totalItems="totalItems"
              :itemsPerPage="itemsPerPage"
              @page-changed="onPageChanged"
            ></PaginationUi>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import { GridLayout, GridItem } from "vue-grid-layout-v3";
import FilterComponent from "../templateComponent/filter.vue";
import FilterComponentSetting from "../templateComponent/filter_setting.vue";
import ButtonComponent from "../templateComponent/button.vue";
import ButtonComponentSetting from "../templateComponent/button_setting.vue";
import MapController from "@/components/MapController2.vue";
import MapControllerSetting from "@/components/MapControllerSetting.vue";
import TimelineController from "@/components/TimelineController2.vue";
import TimelineControllerSetting from "@/components/TimelineControllerSetting.vue";
import TimelineSection from "@/components/TimelineSection2.vue";
import ChartController from "@/components/ChartController2.vue";
import ChartControllerSetting from "@/components/ChartcontrollerSetting.vue";
import RemoteController from "@/components/RemoteController2.vue";
import RemoteControllerSetting from "@/components/RemoteControllerSetting.vue";
import VideoController from "@/components/VideoController2.vue";
import VideoControllerSetting from "@/components/VideoControllerSetting.vue";
import TableController from "@/components/TableController2.vue";
import TableControllerSetting from "@/components/TableControllerSetting.vue";
import PaginationUi from "@/components/PagenationUi.vue";

export default {
  name: "PortletTemplate",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    GridLayout,
    GridItem,
    FilterComponent,
    FilterComponentSetting,
    ButtonComponent,
    ButtonComponentSetting,
    PaginationUi,
    MapController,
    MapControllerSetting,
    TimelineController,
    TimelineControllerSetting,
    TimelineSection,
    ChartController,
    ChartControllerSetting,
    RemoteController,
    RemoteControllerSetting,
    VideoController,
    VideoControllerSetting,
    TableController,
    TableControllerSetting,
  },
  data() {
    const filterList = [];
    const filterRowData = [];
    return {
      filterList,
      filterRowData,
      layout: [],
      draggable: true,
      resizable: true,
      index: 0,
      totalItems: 100,
      itemsPerPage: 10,
      currentPage: 1,

      Component1: "Filter",
      Component2: "Button",
      Component3: "Map",
      Component4: "Label",
      Component5: "Timeline",
      Component6: "Remote",
      Component7: "Video",
      Component8: "Chart",
      Component9: "Table",

      comp_name: "",
      settingRender: "",

      baseUrl: "https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com",
      customYn: null,

      vessle: [],
      allData: [],
      dataList: [],
      dataListSliced: [],
      prjTypeList: [],

      prjData: {
        prjId: "",
        prjType: "",
      },
      loadpop: true,
      didopen: false,
      templateName: "",
      isloaded: false,
      onload: true,
    };
  },
  mounted() {
    this.setRole();
    this.getTemplate();
    this.setPrjTypeList(this.getPrjTypeData());
  },
  methods: {
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },
    loadingTemplate(item) {
      this.$router.push({
        path: "/sysOps/portleTemplateDetail",
        query: { id: item.id },
      });
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.displayDataPerPage(this.dataList);
    },
    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.dataListSliced = rowData.slice(startIndex, endIndex);
    },
    async getTemplate(param = null) {
      try {
        const response = await common.apiGet("/template", param);
        console.log(response.data.data);
        const dataList = response.data.data;
        if (
          typeof dataList !== "undefined" &&
          dataList !== null &&
          dataList !== ""
        ) {
          this.dataList = dataList;
          this.allData = dataList;
        }
        this.currentPage = 1;
        this.totalItems = this.dataList.length;
        this.displayDataPerPage(this.dataList);

        // //let number = 0;
        // //console.log(JSON.parse(response.data.data[number].components));
        // this.allData.push(...response.data.data);
        // this.dataList.push(...response.data.data);
        // //this.layout.push(...JSON.parse(response.data.data[number].components));
        // this.test();
        // return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjTypeData() {
      try {
        const response = await common.apiGet("/project-type");
        console.log(response.data);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setPrjTypeList(prjTypeList) {
      prjTypeList.then((list) => {
        const dataList = [];
        list.forEach((element) => {
          dataList.push({
            id: element.projectTypeId,
            name: element.name,
          });
        });
        this.prjTypeList = dataList;
        console.log(this.prjTypeList);
      });
    },
    async setPrjType(event) {
      this.prjData["prjType"] = event.target.value;
      if (event.target.value !== "") {
        this.getTemplate({ type: event.target.value });
      } else {
        this.getTemplate();
      }
    },
    test() {
      console.log(this.allData);
    },
    async finalSave() {
      if (this.customYn === null) {
        alert("layout 개인화 여부를 선택해 주세요.");
        return false;
      }
      if (this.templateName === "") {
        alert("저장할 템플릿 이름을 입력해주세요.");
        return false;
      }
      let data = {
        name: this.templateName,
        customYn: this.customYn,
        components: JSON.stringify(this.layout),
      };
      console.log(data);
      try {
        const response = await common.apiPost("/templet", data);
        console.log(response);
        alert("저장되었습니다.");
        this.getTemplate();
        return response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    itemTitle(item) {
      let result = item.i;
      if (item.static) {
        result += " - Static";
      }
      return result;
    },
    optionClose() {
      document.querySelector(".setting_pop").style.display = "none";
    },
    addItem: function (title) {
      const leng = this.layout.length;
      //w,h 는 각 컴포넌트별로 크기를 결국 분류해야한다.
      this.layout.push({
        x: (this.layout.length % 2) * 4,
        y: Math.floor(this.layout.length / 2) * 5,
        w: 4,
        h: 5,
        i: title,
        key: leng + "-" + Math.floor(Date.now()),
      });
    },
    removeItem: function (key) {
      // key 값과 일치하는 항목의 인덱스를 찾음
      const index = this.layout.findIndex((item) => item.key === key);

      // 유효한 인덱스가 있는 경우 해당 항목을 삭제
      if (index !== -1) {
        this.layout.splice(index, 1);
      }
      // this.layout = this.layout.sort((a,b)=>{
      //   return a.key.localeCompare(b.key);
      // })
      // console.log(this.layout)
    },
    settingOpen(item) {
      this.comp_name = item.type;
      this.settingRender = item.type;
      document.querySelector(".setting_pop").style.display = "block";
    },
    showFilter(event) {
      const _this = event.target;

      const $filterBtn =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
      const $filterWrap = document.querySelector("#filterWrap");
      const $filterInput = $filterWrap.querySelectorAll(".filter_input");

      const hasShowClass = $filterWrap.classList.contains("show");
      const hasActiveClass = _this.classList.contains("active");

      for (var i = 0; i < $filterBtn.length; i++) {
        $filterBtn[i].classList.remove("active");
      }
      _this.classList.add("active");
      if (hasShowClass) {
        if (hasActiveClass) {
          $filterWrap.classList.remove("show");
          _this.classList.remove("active");
        }
      } else {
        $filterWrap.classList.add("show");
      }
      let activeIndex = Array.from($filterBtn).findIndex((element) =>
        element.classList.contains("active")
      );
      // console.log(activeIndex);
      // input
      for (var j = 0; j < $filterInput.length; j++) {
        $filterInput[j].querySelector("div").classList.remove("active");
      }
      // console.log($filterInput[activeIndex]);
      if (activeIndex > -1) {
        $filterInput[activeIndex].querySelector("div").classList.add("active");
      }
    },
    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },

    async addFilter(event) {
      // console.log("addFilter");

      const _this = event.target;
      const $thTag = _this.parentNode.parentNode;
      const $addBtn = $thTag.querySelector("button");
      const $input = $thTag.querySelector("input");
      // input 글자수
      const trimVal = $input.value.trim();

      // 컬럼 이름 가져오기
      const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode
        .querySelector("tr:first-child")
        .querySelectorAll(".btn_filter");
      let activeIndex = Array.from(keyNameArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyName = String(keyNameArr[activeIndex].parentNode.textContent);

      const dataKeyArr =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".input_box");
      const dataKeyindex = Array.from(dataKeyArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyId = String(dataKeyArr[dataKeyindex].getAttribute("data-id"));

      const obj = {};
      // console.log();
      // console.log(this.getAttribute('data-index'))
      // console.log($input);

      window.filterData.taget = _this.getAttribute("data-index");

      if (0 < trimVal.length) {
        obj["keyId"] = keyId;
        obj["value"] = trimVal;
        obj["keyName"] = keyName;
        $input.value = "";
        this.filterList.push(obj);
        this.$refs.filterAllBtn.style.display = "block";
        $addBtn.parentNode.classList.add("disable");
        $addBtn.previousElementSibling.disabled = true;
        this.filterAction();
      }
    },

    filterAction(remove) {
      const vm = this;
      // 필터 리스트
      const _filterLsit = vm.filterList;
      // 테이블 리스트
      let _tableList = vm.dataList;
      if (vm.isfilter) {
        vm.filterDataList.origin = _tableList;
        vm.isfilter = false;
      }

      if (_filterLsit.length > 0) {
        const lodash = require("lodash");

        // 필터 삭제
        if (remove) {
          let _filterDataListOrign = [];
          vm.filterDataList.origin.forEach((item) => {
            _filterDataListOrign.push(item);
          });

          for (var j = 0; j < _filterLsit.length; j++) {
            const searchString = common.getUpper(_filterLsit[j].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[j].keyId;
            const filteredData = lodash.filter(_filterDataListOrign, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
            _filterDataListOrign = filteredData;
          }
          // 필터 추가
        } else {
          for (var i = 0; i < _filterLsit.length; i++) {
            const searchString = common.getUpper(_filterLsit[i].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[i].keyId;
            const filteredData = lodash.filter(_tableList, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
          }
        }

        // console.log("필터 동작 시작");
      } else {
        vm.dataList = vm.filterDataList.origin;
      }
      vm.currentPage = 1;
      vm.totalItems = vm.dataList.length;
      vm.displayDataPerPage(vm.dataList);
    },
  },
};
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}

.port_wrap {
  width: calc(100% - 0px);
  height: 100vh;
  padding: 20px;
  background: #f4f4f4;
  position: relative;
  top: auto;
  left: auto;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #00b4ed;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}

.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #00b4ed;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #00b4ed;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: 00B4ED;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00b4ed;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
  border: 1px solid #d4d4d4 !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
  padding-left: 12px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: 00B4ED;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00b4ed;
}
.vue-grid-item span.setting_button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.final_save {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: 00B4ED;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
}
.load_btn {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: 00B4ED;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
  right: 130px !important;
}
.load_pop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.load_pop .load_pop_cell {
  width: 1000px;
  height: 600px;
  background: white;
  position: relative;
  padding: 20px;
}
.load_pop .load_pop_cell select {
  width: 150px;
  height: 40px;
  border: 1px solid #d4d4d4;
  margin-left: 20px;
  display: inline-block;
  background-position: right 4px center;
  vertical-align: top;
}
.load_pop .load_pop_cell .search {
  display: inline-block;
  width: 240px;
  height: 40px;
  border-radius: 0px;
  padding-right: 60px;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}
.load_pop .load_pop_cell .search input[type="text"] {
  width: 100%;
  height: 38px;
  background: white;
  border: none;
  padding-left: 10px;
}
.load_pop .load_pop_cell .search button {
  width: 60px;
  height: 38px;
  background: #f4f4f4;
  border-left: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}
.load_pop .load_pop_cell .new_open {
  width: 100px;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  float: right;
}
.load_pop .load_pop_cell .new_open:hover {
  background: #00b4ed;
}
.load_pop .load_pop_cell h4 {
  height: 50px;
  line-height: 50px;
  font-size: 45px;
  font-weight: lighter;
  color: black;
  padding: 0px;
  margin: 0px;
  margin-bottom: 7px;
}
.load_pop .load_pop_cell .sc_wrap {
  width: 100%;
  height: 490px;
  background: #f4f4f4;
  overflow: auto;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}
.load_pop .load_pop_cell .sc_wrap:after {
  clear: both;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell {
  width: 100%;
  height: 50px;
  float: left;
  position: relative;
  padding: 0px;
  padding-bottom: 10px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real {
  width: 100%;
  height: 40px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  position: Relative;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real:hover {
  border: 1px solid skyblue;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data:after {
  content: "...New Data";
  font-size: 17px;
  font-weight: bold;
  color: black;
  display: block;
  width: 100%;
  text-align: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved {
  position: Absolute;
  top: 0px;
  text-align: center;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 38px;
  font-size: 17px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-left: 14px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved span {
  display: block;
  width: 200px;
  height: 38px;
  position: absolute;
  text-align: center;
  bottom: 0px;
  right: 0px;
  line-height: 38px;
  font-size: 11px;
  font-weight: normal;
  color: gray;
}
.load_pop .load_pop_cell .load_pop_cell_x {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: -25px;
  z-index: 22;
  background: #444;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
}
.load_pop .load_pop_cell .load_pop_cell_x:hover {
  background: black;
}
.tempname {
  width: 200px;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 14px;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.list_wrap {
  position: relative;
}
.list_wrap .table_list {
  height: 715px;
}
</style>
