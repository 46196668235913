<template>
  <div class="component">
    <div class="divide" v-for="item in components" :key="item.key">
      <component
        v-if="item && item.key && item.type && !item.remove"
          :is="
            item.type === 'Toggle'
              ? 'buttonToggleView'
              : item.type === 'Normal'
              ? 'buttonNormalView'
              : null
              " 
        :otherProp="item"
        :newWPx="otherProp.newWPx"
        :readOnlyProp="true"
        :handlerList="handlerList"
      ></component>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import common from "@/assets/js/common";
import buttonToggleView from '../componentType/buttonToggleView.vue';
import buttonNormalView from '../componentType/buttonNomalView.vue';

export default {
  name: "ButtonComponent",
  components:{
     buttonToggleView,
     buttonNormalView
  },
    props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    editYn: {
      type: Object,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      divisionList: [],
      division: {},
      components: [],
      layoutComponent: {},
    };
  },
  mounted(){
    this.setRole();
    this.getDivisionList();
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);
  },

  methods: {
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },
    async getDivisionList() {
      const response = await common.apiGet("/division");
      this.divisionList = response.data.data;
      //여기에서 가져와야하는데..
    },
    setDivision(event) {
      this.division["name"] = event.target.value;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.comp_line {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 40px;
  flex-direction: row;
}
.comp_line .data_key {
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  padding-right: 20px;
}
.comp_line .data_val {
  flex-grow: 4;
}
.comp_line .data_val select {
  width: 100%;
  height: 40px;
  background-position: right 10px center;
}
</style>

<style>
.datePeriod {
  width: 100%;
  height: 40px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  position: relative;
  padding-right: 80px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: black;
  text-align: Center;
}
.datePeriod button.dateon {
  width: 60px;
  height: 38px;
  border: none;
  border-left: 1px solid #d4d4d4;
  background: #f4f4f4;
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.datePeriod input[type="text"] {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
}
.divider {
  width: 100%;
  height: 1px;
  background: #d4d4d4;
  margin-top: 20px;
  margin-bottom: 20px;
}
.component {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
.v3dp__datepicker {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
  display: inline-block;
  vertical-align: middle;
}
.v3dp__input_wrapper {
  width: 100% !important;
}
.v3dp__input_wrapper input[type="text"] {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #d4d4d4 !important;
}
</style>
<style>
.component_button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
  background: 00B4ED;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: white;
}
.component_button:hover {
  background: #00B4ED;
}
</style>
