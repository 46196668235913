<template>
  <div class="setting_pop_cell_contents_wrap">
    <div class="input-block">
      <table>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <label :for="'input-' + index" class="label">{{
              item.label
            }}</label>
          </td>
          <td>
            <template v-if="index === items.length - 1">
              <div style="display: flex; justify-content: center; gap: 10px">
                <ComponentTypeList @component-type="handleSelectedValue" />
                <div class="btnStyle">
                  <button
                    type="button"
                    class="mcbtn"
                    :class="{ clicked: isClicked }"
                    @click="handleClick"
                  >
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </button>
                </div>
              </div>
            </template>
            <template v-if="index !== items.length - 1">
              <input
                type="text"
                :id="'input-' + index"
                v-model="item.value"
                class="input"
              />
            </template>
          </td>
        </tr>
      </table>
    </div>
    <div class="divide">
      <div class="btn_area">
        <div class="first">
          <input
            type="checkbox"
            id="childUseButton"
            v-model="childUseButton"
            @change="filterStyleChange"
          />
          <label for="childUseButton"></label>
          <span style="margin-left: 5px">childUseButton</span>
        </div>
        <div class="second">
          <input
            type="checkbox"
            id="childBoxLayer"
            v-model="childBoxLayer"
            @change="filterStyleChange"
          />
          <label for="childBoxLayer"></label>
          <span style="margin-left: 5px">childBoxLayer</span>
        </div>
        <div class="second">
          <input
            type="checkbox"
            id="dataSetLayer"
            v-model="filterDataSet"
            @change="filterStyleChange"
          />
          <label for="dataSetLayer"></label>
          <span style="margin-left: 5px">dataSet</span>
        </div>
      </div>
    </div>
    <div
      class="divide"
      v-for="(item, index) in components"
      :key="item.key"
      style="width: calc(100% - 27px)"
    >
      <component
        v-if="item && item.key && item.type && !item.remove"
        :is="
          item.type === 'calendar'
            ? 'newDatePickerVue'
            : item.type === 'select'
            ? 'SelectBar'
            : item.type === 'search'
            ? 'SearchBar'
            : item.type === 'select_value'
            ? 'SelectValueBar'
            : item.type === 'range'
            ? 'rangeBarVue'
            : null
        "
        @updateValue="updateItem(index, $event)"
        @remove-item="removeItem(index)"
        :otherProp="item"
        :handlerList="handlerList"
        :readOnlyProp="false"
        :componentIndex="index"
        :keyValueList="item.keyValueList"
        :keyValueType="item.keyValueType"
        @updateParent="updateParent"
        @updateRange="updateRanged"
        @updateCalendar="updateCalendarChange"
        :range="item.range"
        :dataSet="dataSet"
        @selectUpdateDataSet="selectUpdateDataSet"
        @selectDataMapCategory="selectDataMapCategory"
        :dataSetComponent="dataSetComponent"
      ></component>
    </div>
    <div></div>
  </div>
</template>

<script>
import ComponentTypeList from "./componentTypeList.vue";
import CalendarBar from "../componentType/calendar_bar.vue";
import SelectBar from "../componentType/select_bar.vue";
import newDatePickerVue from "../componentType/newDatePicker.vue";
import SearchBar from "../componentType/search_bar.vue";
import SelectValueBar from "../componentType/selectValueBar.vue";
import _ from "lodash";
import rangeBarVue from "../componentType/rangeBar.vue";

export default {
  name: "FilterComponentSetting",
  components: {
    ComponentTypeList,
    CalendarBar,
    newDatePickerVue,
    SelectBar,
    SearchBar,
    SelectValueBar,
    rangeBarVue,
  },

  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex: {
      type: Number,
      required: true,
    },
    handlerList: {
      //handler
      type: Object,
      required: true,
    },
    useButton: {
      type: Boolean,
    },
    boxLayer: {
      type: Boolean,
    },
    dataSet: {
      type: Object,
    },
    dataSetComponent: {
      type: Object,
    },
  },

  data() {
    return {
      items: [
        { label: "Component ID", value: "" },
        { label: "Display Title", value: "" },
        { label: "Controller 추가", value: {} },
      ],
      isClicked: false,
      componentType: "",
      components: [],
      layoutComponent: {},
      childUseButton: false,
      childBoxLayer: false,
      filterDataSet: false,
    };
  },

  mounted() {
    // 전달받은 otherProp 확인
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);
    const components_cp = this.components;
    for (let i = 0; i < components_cp.length; i++) {
      let valueStart = components_cp[i].valueStart;
      let valueEnd = components_cp[i].valueEnd;
      if (valueStart !== null) {
        if (typeof valueStart === "string") {
          valueStart = new Date(valueStart);
        }
      }
      if (valueEnd !== null) {
        if (typeof valueEnd === "string") {
          valueEnd = new Date(valueEnd);
        }
      }
      console.log(components_cp[i]);
    }

    this.components = _.cloneDeep(components_cp);
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    this.childUseButton = this.layoutComponent.useButton;
    this.childBoxLayer = this.layoutComponent.boxLayer;
    this.filterDataSet = this.layoutComponent.filterDataSet;
    console.log("filter_setting");
    console.log(this);
  },

  methods: {
    selectUpdateDataSet({ index, value, value2 }) {
      this.components[index].dataSet = value;
      this.components[index].selectDataTitle = value2;
    },
    selectDataMapCategory({ index, value }) {
      this.components[index].dataMapCategory = value;
    },
    handleClick() {
      this.isClicked = true;
      setTimeout(() => {
        // 200ms 후에 isClicked를 false로 설정하여 스타일을 원래대로 되돌림
        this.isClicked = false;
      }, 100);
      //
      if (this.componentType === "") {
        alert("componentType이 설정되지 않았습니다.");
      } else {
        //type , value, scope_start scope_end label_text
        const type = this.componentType;
        const handlerCount = String(this.handlerList.length + 1).padStart(
          3,
          "0"
        );
        const component = {
          type: type,
          labelText: "",
          value: "",
          valueStart: "",
          valueEnd: "",
          key: this.components.length + 1 + "-" + Math.floor(Date.now()),
          handlerKey: "Handler" + handlerCount,
          remove: false,
          child: {},
          parentHandlerKey: "",
          controlHandlerLabelText: "",
          keyValueList: [],
          keyValueType: "",
          parameter: "",
          range: {
            startRange: 0,
            endRange: 0,
            step: 0,
          },
        };
        this.components.push(component);
        this.$emit("updateHandler", component);
      }
    },
    handleSelectedValue(value) {
      // 선택된 option의 value 값을 부모로 emit
      this.componentType = value;
    },
    updateItem(index, newValue) {
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    removeItem(index) {
      this.$emit("removeHandler", this.components[index]);
      this.components.splice(index, 1); // 해당 인덱스의 객체를 배열에서 제거
    },
    updateParent({ index, value }) {
      this.components[index].parentHandlerKey = value;
      this.components = _.cloneDeep(this.components);
    },
    updateRanged({ index, value }) {
      this.components[index].range = value;
      this.components = _.cloneDeep(this.components);
    },
    filterStyleChange() {
      this.$nextTick(() => {
        console.log(this.childBoxLayer);
        console.log(this.childUseButton);
        this.layoutComponent.boxLayer = this.childBoxLayer;
        this.layoutComponent.useButton = this.childUseButton;
        this.layoutComponent.filterDataSet = this.filterDataSet;
        this.layoutComponent = _.cloneDeep(this.layoutComponent);
      });
    },
    updateCalendarChange({ index, value }) {
      console.log(index);
      console.log(value);
      if (value != null) {
        this.components[index].startDate = value.startDate;
        this.components[index].startViewDate = value.startViewDate;
        this.components[index].endDate = value.endDate;
        this.components[index].endViewDate = value.endViewDate;
        this.components[index].calenderType = value.calenderType;
        this.components[index].singleDate = value.singleDate;
        this.components[index].singleViewDate = value.singleViewDate;
        this.components[index].period = value.period;
        this.components[index].picEndDate = value.picEndDate;
        this.components[index].picEndDateCalendar = value.picEndDateCalendar;
        console.log(this.components[index]);
        this.components = _.cloneDeep(this.components);
      }
    },
  },

  watch: {
    components() {
      this.layoutComponent.components = this.components;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.port_wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #00b4ed;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}

.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #00b4ed;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #00b4ed;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: center;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: 00B4ED;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00b4ed;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
  border: 1px solid #d4d4d4 !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: 00B4ED;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00b4ed;
}
.vue-grid-item span.setting_button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 여러 행이 있을 경우 간격을 위한 속성 */
}

.checkbox-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* 각 체크박스 간격 */
}

.checkbox-item {
  display: inline-flex; /* 자식 요소에 맞춰 크기 조정 */
  align-items: center; /* 체크박스와 레이블을 수직 중앙 정렬 */
  gap: 8px; /* 체크박스와 레이블 사이 간격 */
}

.checkbox-item div {
  display: inline-block; /* div가 자식 요소의 크기를 가지도록 설정 */
  width: auto; /* div가 자식 요소 크기에 맞춰짐 */
  height: auto; /* div가 자식 요소 크기에 맞춰짐 */
}
</style>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

.cover-block,
.input-block {
  flex: 1;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.label,
.input {
  text-align: center;
}

table {
  margin-bottom: 10px;
}

td {
  padding: 5px;
  text-align: center;
}

.btnStyle {
  align-items: center;
}
.btnStyle button {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.divide {
  /* position: relative; */
  /* display: flex; */
  /* width: 100%; */
  /* height: 100%; */
}
.divide .btn_area {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-bottom: -12px;
}
.first {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.second {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.input-block {
  table {
    tr {
      td {
        &:nth-child(1) {
          background-color: #00b4ed;
          label {
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
