<template>
  <div
    :id="'video_0' + this.otherProp.key"
    class="video_wrap radiusbox"
    style="height: 100%; width: 100%"
  >
    <video
      :id="'video_' + otherProp.key"
      class="video"
      ref="video"
      :src="src !== '' ? src : uploadSrc !== '' ? uploadSrc : ''"
      playsinline
      muted
      preload
      style="width: 100%; height: 100%"
      :controls="controlYN ? controlYN : controls"
    ></video>
  </div>
</template>

<script>
export default {
  name: "VideoController2",
  computed: {},
  props: {
    src: {
      type: String,
      default: "",
    },
    timeDifference: {
      type: Number,
      default: 0,
    },
    whSize: {
      type: String,
      default: "width",
    },
    controls: {
      type: Boolean,
      default: false,
    },
    otherProp: {
      type: Object,
    },
  },
  data() {
    return {
      videoSrc: "",
      videoMinunumFrame: 0.042,
      maxPlaybackRate: 16,
      minPlaybackRate: 0.06,
      uploadSrc: "",
      controlYN: false,
    };
  },
  watch: {
    src() {
      this.videoSrc = this.src;
    },
    timeDifference() {
      //const video = document.getElementById("video");
      const vm = this;
      const video = document.getElementById("video_" + this.otherProp.key);

      if (video.duration > 0) {
        const playbackRate = (video.duration * 1000) / vm.timeDifference;

        if (
          playbackRate > vm.maxPlaybackRate ||
          playbackRate < vm.minPlaybackRate
        ) {
          alert("영상의 배속이 규격을 벗어납니다.");
          vm.videoSrc = "";
        } else {
          video.playbackRate = playbackRate;
        }
      }
    },
  },

  created() {},
  mounted() {
    const vm = this;
    const video = document.getElementById("video_" + this.otherProp.key);
    this.uploadSrc = this.otherProp
      ? this.otherProp.components[0]?.videoSrc
      : "";

    this.controlYN = this.otherProp
      ? this.otherProp.components[0]?.controlYN
      : "";
    video.addEventListener(
      "loadeddata",
      function () {
        const playbackRate = (video.duration * 1000) / vm.timeDifference;
        const param = {
          parentKey: vm.otherProp.parentKey,
          duration: video.duration * 1000,
        };

        vm.emitter.emit("setDuration", param);

        if (vm.timeDifference != null && vm.timeDifference != "") {
          if (
            playbackRate > vm.maxPlaybackRate ||
            playbackRate < vm.minPlaybackRate
          ) {
            alert("영상의 배속이 규격을 벗어납니다.");
            vm.videoSrc = "";
          } else {
            video.playbackRate = playbackRate;
          }
        }
      },
      false
    );

    this.emitter.on("videoTime", (action) => {
      if (
        this.otherProp != undefined &&
        this.otherProp.parentKey !== "" &&
        this.otherProp.parentKey === action.key
      ) {
        const video = document.getElementById("video_" + this.otherProp.key);
        if (action.txt == "fTime") {
          video.currentTime = 0;
          //video.pause();
        } else if (action.txt == "eTime") {
          video.currentTime = video.duration;
          //video.pause();
        } else if (action.txt == "play") {
          if (video.currentTime + this.videoMinunumFrame < video.duration) {
            video.play();
          }
        } else if (action.txt == "pause") {
          //video.pause();
        } else if (action.txt == "back3") {
          video.currentTime =
            (Number(action.val) - Number(action.timeDifference)) / 1000;
          video.playbackRate;
          //video.pause();
        } else if (action.txt == "forward3") {
          const videoTime =
            ((Number(action.val) + Number(action.timeDifference)) / 1000) *
            video.playbackRate;
          if (videoTime > video.duration) {
            video.currentTime = video.duration;
          } else {
            video.currentTime = videoTime;
          }
          //video.pause();
        } else if (action.txt == "mouseup") {
          const videoTime = Number(action.val / 1000) * video.playbackRate;
          video.currentTime = videoTime;
        }

        if (action.txt != "play") {
          video.pause();
        }
      }
    });
    /*
    this.emitter.on("setVideoDuration", (a) => {
      if (this.src != "") {
        video.playbackRate = (video.duration * 1000) / a;
      }
    });
    */
    /*
    video.addEventListener("loadeddata", function (a) {
      alert(video.duration * 1000);
      video.playbackRate = (video.duration * 1000) / a;

      alert(video.playbackRate);
    });
    */
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.video_wrap {
  height: 100%;
  box-sizing: border-box;
  video {
    background: #000;
  }
}
.radiusbox {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(162, 195, 242, 0.2);
  background-color: #fff;
}
.w-100 {
  width: 100%;
}
.h-100 {
  width: 100%;
  height: 100%;
}
</style>
