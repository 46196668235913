<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout pageId="monTASK_mlopsMonitoring" />
      <div class="item_info">
        <div class="search_wrap radiusbox">
          <div class="item_search">
            <select v-model="currentVersion" @change="chgVersion">
              <option value="0">current version</option>
              <option v-for="item in versionList" :key="item.pipelineVersion">
                {{ item.pipelineVersion }}
              </option>
            </select>
          </div>
        </div>
        <div class="dashboard">
          <div class="item">
            <div class="title">파이프라인 상태</div>
            <div class="txt">
              {{
                pipelineRun.runStatus !== ""
                  ? pipelineRun.runStatus.toUpperCase()
                  : "ㅡ"
              }}
            </div>
          </div>
          <div class="item">
            <div class="title">시작 시간</div>
            <div class="txt">
              {{ pipelineRun.runStart !== "" ? pipelineRun.runStart : "ㅡ" }}
            </div>
          </div>
          <div class="item">
            <div class="title">종료 시간</div>
            <div class="txt">
              {{ pipelineRun.runEnd !== "" ? pipelineRun.runEnd : "ㅡ" }}
            </div>
          </div>
        </div>
        <div class="info" v-if="pipelineApprove.pipelineId !== 0">
          <div class="row">
            <div class="title">승인자</div>
            <div class="txt">{{ pipelineApprove.approveUser }}</div>
            <div class="title">승인 상태</div>
            <div class="txt" style="max-width: 150px">
              {{ pipelineApprove.approveResult === "a" ? "승인" : "" }}
            </div>
            <div class="title">승인 일자</div>
            <div class="txt">{{ pipelineApprove.approveResDate }}</div>
          </div>
          <div class="row">
            <div class="title">메시지</div>
            <div class="txtarea">{{ pipelineApprove.approveMsg }}</div>
          </div>
        </div>
        <div id="monCont" class="contents">
          <ul class="list_tab float_box" ref="listTab">
            <li @click="tabEvent(0)" class="active">
              <div>Log</div>
            </li>
            <li @click="tabEvent(1)" v-if="pipelineType == 2">
              <div>Evaluation</div>
            </li>
          </ul>

          <div class="tab_data" ref="listTabBox">
            <div class="data_list_box radiusbox active">
              <div class="list_wrap">
                <div class="table_list">
                  <div class="mando_table_wrap">
                    <table class="mando_table">
                      <colgroup>
                        <col width="30px" />
                        <col width="30px" />
                        <col width="30px" />
                        <col width="30px" />
                        <col width="*" />
                        <col width="200px" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>
                            <div class="col_name">No.</div>
                          </th>
                          <th>
                            <div class="col_name">Type</div>
                          </th>
                          <th>
                            <div class="col_name">Step</div>
                          </th>
                          <th>
                            <div class="col_name">Title</div>
                          </th>
                          <th>
                            <div class="col_name">Message</div>
                          </th>
                          <th>
                            <div class="col_name">Date</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="dataListSliced.length > 0">
                          <tr
                            v-for="(item, index) in dataListSliced"
                            :key="index"
                          >
                            <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                            <td>{{ item.logType }}</td>
                            <td>{{ item.logStep }}</td>
                            <td>{{ item.logTitle }}</td>
                            <td style="text-align: left">{{ item.logMsg }}</td>
                            <td>{{ item.logDate }}</td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr>
                            <td colspan="6" style="height: 50px">
                              데이터가 없습니다.
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <div class="list_table_footer">
                      <PaginationUi
                        :totalItems="totalItems"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="onPageChanged"
                      ></PaginationUi>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="data_list_box radiusbox">
              <div class="eval">
                <div class="tbl_matrix">
                  <div class="row">
                    <div class="col"></div>
                    <div class="col">예측 Negative (ng)</div>
                    <div class="col">예측 Positive (ok)</div>
                  </div>
                  <div class="row">
                    <div class="col">실제 Negative (ng)</div>
                    <div class="col">
                      {{ cfsMastrix.data1 }}
                    </div>
                    <div class="col">
                      {{ cfsMastrix.data2 }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">실제 Positive (ok)</div>
                    <div class="col">
                      {{ cfsMastrix.data3 }}
                    </div>
                    <div class="col">
                      {{ cfsMastrix.data4 }}
                    </div>
                  </div>
                </div>
                <div class="tbl_report">
                  <div class="row">
                    <div class="col"></div>
                    <div class="col">Precision</div>
                    <div class="col">Recall</div>
                    <div class="col">F1-score</div>
                    <div class="col">Support</div>
                  </div>
                  <div class="row">
                    <div class="col">Ng</div>
                    <div class="col">{{ report.ng.precision }}</div>
                    <div class="col">{{ report.ng.recall }}</div>
                    <div class="col">{{ report.ng.f1Score }}</div>
                    <div class="col">{{ report.ng.support }}</div>
                  </div>
                  <div class="row">
                    <div class="col">Ok</div>
                    <div class="col">{{ report.ok.precision }}</div>
                    <div class="col">{{ report.ok.recall }}</div>
                    <div class="col">{{ report.ok.f1Score }}</div>
                    <div class="col">{{ report.ok.support }}</div>
                  </div>
                  <div class="row">
                    <div class="col">Accuracy</div>
                    <div class="col">-</div>
                    <div class="col">-</div>
                    <div class="col">{{ report.accuracy }}</div>
                    <div class="col">-</div>
                  </div>
                  <div class="row">
                    <div class="col">Marco avg</div>
                    <div class="col">{{ report.macroAvg.precision }}</div>
                    <div class="col">{{ report.macroAvg.recall }}</div>
                    <div class="col">{{ report.macroAvg.f1Score }}</div>
                    <div class="col">{{ report.macroAvg.support }}</div>
                  </div>
                  <div class="row">
                    <div class="col">weighted avg</div>
                    <div class="col">{{ report.weightedAvg.precision }}</div>
                    <div class="col">{{ report.weightedAvg.recall }}</div>
                    <div class="col">{{ report.weightedAvg.f1Score }}</div>
                    <div class="col">{{ report.weightedAvg.support }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";

import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import common from "@/assets/js/common";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    PaginationUi,
  },
  data() {
    return {
      // role
      viewRole: false,
      manageRole: false,

      filterList: [],
      filterRowData: [],
      perPageList: [],
      perPage: 0,

      totalItems: 100,
      itemsPerPage: 10,
      currentPage: 1,

      dataList: [],
      dataListSliced: [],

      selectedTab: "inference",
      preSelectedTab: "inference",
      currentVersion: 0,
      versionList: [],
      pipelineId: 0,
      pipelineVersion: 0,
      pipelineType: 0,
      pipelineApprove: {
        approveCommer: null,
        approveMsg: "",
        approveReqDate: "",
        approveResDate: "",
        approveResult: "",
        approveUser: "",
        pipelineId: 0,
        pipelineRun: 0,
        pipelineType: 0,
        pipelineVersion: 0,
      },
      pipelineRun: {
        runStart: "",
        runEnd: "",
        runStatus: "",
      },

      evalData: null,
      cfsMastrix: {
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
      },
      report: {
        ng: {
          f1Score: 0,
          precision: 0,
          recall: 0,
          support: 0,
        },
        ok: {
          f1Score: 0,
          precision: 0,
          recall: 0,
          support: 0,
        },
        macroAvg: {
          f1Score: 0,
          precision: 0,
          recall: 0,
          support: 0,
        },
        weightedAvg: {
          f1Score: 0,
          precision: 0,
          recall: 0,
          support: 0,
        },
        accuracy: 0,
      },
    };
  },
  computed: {},
  async mounted() {
    const loading = this.$refs.lodingWrap;
    loading.style.display = "block";
    this.pipelineId = this.$route.params.id;
    this.pipelineVersion = this.$route.params.version;
    this.pipelineType = this.$route.params.type;
    this.currentVersion = this.pipelineVersion;
    this.pipelineType =
      this.$route.params.type == 1 ? 3 : this.$route.params.type;
    await this.getAllVersion();
    await this.getPipelineData();
    await this.getLogList();
    if (this.pipelineType == 2) {
      await this.getEvalData();
    }
    loading.style.display = "none";
  },
  methods: {
    async chgVersion() {
      const id = this.pipelineId;
      const version = this.currentVersion;
      const type = this.pipelineType;
      if (version != 0 && this.versionList.length > 1) {
        location.href =
          "/monTASK/mlopsMonitoring/" + id + "/" + version + "/" + type;
      }
    },
    async getAllVersion() {
      try {
        const response = await common.apiGet("/pipeline");
        const data = response.data.data;
        const pipelineId = this.pipelineId;
        for (let item of data) {
          if (item.pipelineId == pipelineId) {
            this.versionList.push(item);
          }
        }
        console.log("this.versionList :: ", this.versionList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPipelineData() {
      const param = {
        pipelineId: this.pipelineId,
        pipelineVersion: this.pipelineVersion,
      };
      try {
        const response = await common.apiGet("/pipeline", param);
        const data = response.data.data;
        const apprData = data.pipelineRunApproveResponseDto;
        const runData =
          data.pipelineRunInference !== null
            ? data.pipelineRunInference
            : data.pipelineRunMl !== null
            ? data.pipelineRunMl
            : null;
        console.log("%%%%%%%%%!!!!!! :: ", response.data.data);

        if (apprData !== null) {
          this.pipelineApprove = apprData;
        }
        if (runData !== null) {
          this.pipelineRun = runData;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getEvalData() {
      const param = {
        pipelineId: this.pipelineId,
        pipelineVersion: this.pipelineVersion,
      };
      try {
        const response = await common.apiGet("/pipelinerun/mlmodel", param);
        this.evalData = response.data.data;
        const outputData = this.evalData.runOutput
          .replaceAll("macro avg", "macro_avg")
          .replaceAll("weighted avg", "weighted_avg")
          .replaceAll("f1-score", "f1_score");

        console.log("########### :: ", JSON.parse(outputData));
        console.log(
          "########### :: ",
          JSON.parse(outputData).classification_report.macro_avg.f1_score
        );
        this.cfsMastrix.data1 = JSON.parse(outputData).confusion_mastrix[0][0];
        this.cfsMastrix.data2 = JSON.parse(outputData).confusion_mastrix[0][1];
        this.cfsMastrix.data3 = JSON.parse(outputData).confusion_mastrix[1][0];
        this.cfsMastrix.data4 = JSON.parse(outputData).confusion_mastrix[1][1];

        this.report.ng.f1Score =
          JSON.parse(outputData).classification_report.ng.f1_score;
        this.report.ng.precision =
          JSON.parse(outputData).classification_report.ng.precision;
        this.report.ng.recall =
          JSON.parse(outputData).classification_report.ng.recall;
        this.report.ng.support =
          JSON.parse(outputData).classification_report.ng.support;

        this.report.ok.f1Score =
          JSON.parse(outputData).classification_report.ok.f1_score;
        this.report.ok.precision =
          JSON.parse(outputData).classification_report.ok.precision;
        this.report.ok.recall =
          JSON.parse(outputData).classification_report.ok.recall;
        this.report.ok.support =
          JSON.parse(outputData).classification_report.ok.support;

        this.report.macroAvg.f1Score =
          JSON.parse(outputData).classification_report.macro_avg.f1_score;
        this.report.macroAvg.precision =
          JSON.parse(outputData).classification_report.macro_avg.precision;
        this.report.macroAvg.recall =
          JSON.parse(outputData).classification_report.macro_avg.recall;
        this.report.macroAvg.support =
          JSON.parse(outputData).classification_report.macro_avg.support;

        this.report.weightedAvg.f1Score =
          JSON.parse(outputData).classification_report.weighted_avg.f1_score;
        this.report.weightedAvg.precision =
          JSON.parse(outputData).classification_report.weighted_avg.precision;
        this.report.weightedAvg.recall =
          JSON.parse(outputData).classification_report.weighted_avg.recall;
        this.report.weightedAvg.support =
          JSON.parse(outputData).classification_report.weighted_avg.support;
        this.report.accuracy =
          JSON.parse(outputData).classification_report.accuracy;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getLogList() {
      const param = {
        pipelineId: this.pipelineId,
        pipelineVersion: this.pipelineVersion,
        pipelineType: this.pipelineType,
      };
      try {
        const response = await common.apiGet("/pipelinerun/log", param);
        this.dataList = response.data.data;
        this.currentPage = 1;
        this.totalItems = this.dataList.length;
        this.displayDataPerPage(this.dataList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.displayDataPerPage(this.dataList);
    },

    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.dataListSliced = rowData.slice(startIndex, endIndex);
    },
    tabEvent(index) {
      this.selectedTab = index === 0 ? "inference" : "systemData";
      if (this.preSelectedTab !== this.selectedTab) {
        this.edgeId = 0;
        this.searchSite = "";
        this.searchWc = "";
        this.model = "";
      }
      const $listTab = this.$refs.listTab;
      const $listTabBox = this.$refs.listTabBox;
      let $listTabItem = $listTab.querySelectorAll("li");
      let $listTabBoxItem = $listTabBox.querySelectorAll(".data_list_box");
      for (var i = 0; i < $listTabItem.length; i++) {
        $listTabItem[i].classList.remove("active");
        $listTabBoxItem[i].classList.remove("active");
      }
      $listTabItem[index].classList.add("active");
      $listTabBoxItem[index].classList.add("active");

      this.preSelectedTab = this.selectedTab;
    },
  },
};
</script>
<style scoped lang="scss">
.search_wrap {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  .item_search {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    select {
      min-width: 170px;
      background-position: center right 15px;
    }
    button {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
}
.dashboard {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .item {
    padding: 15px;
    flex: 1;
    &:nth-child(1) {
      background-color: #fff2d9;
      .title {
        background-color: #ffa800;
      }
    }
    &:nth-child(2) {
      background-color: #d9f2e3;
      .title {
        background-color: #02a744;
      }
    }
    &:nth-child(3) {
      background-color: #fde5e3;
      .title {
        background-color: #f15046;
      }
    }
    .title,
    .txt {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 600;
      font-size: 24px;
    }
    .title {
      color: #fff;
      height: 80px;
    }
    .txt {
      background-color: #fff;
      height: 150px;
      margin-top: 15px;
    }
  }
}
.info {
  margin-top: 20px;
  border: 1px solid #d5dae3;
  .row {
    display: table;
    width: 100%;
    height: 40px;
    &:not(:last-child) {
      border-bottom: 1px solid #d5dae3;
    }
    & > div {
      display: table-cell;
      box-sizing: border-box;
      vertical-align: middle;
      &.title {
        width: 100px;
        background-color: #405261;
        color: #fff;
        text-align: center;
      }
      &.txt {
        text-align: center;
        background-color: #fff;
      }
      &.txtarea {
        background-color: #fff;
        padding: 10px;
      }
    }
  }
}
.contents {
  .tab_data {
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 43, 104, 0.2);
    box-sizing: border-box;
    .data_list_box {
      display: none;
      padding: 20px;
      &.active {
        display: block;
      }
      .eval {
        display: flex;
        gap: 20px;
        & > div {
          .row {
            display: flex;
            align-items: center;
          }

          &.tbl_matrix {
            flex: 1;
            .row {
              height: calc(100% / 3);
              border-bottom: 1px solid #d5dae3;
              box-sizing: border-box;
              &:first-child {
                color: #fff;
                font-weight: 600;
                background-color: #405261;
                border-top: 1px solid #d5dae3;
                & > div {
                  color: #fff;
                  font-weight: 600;
                  &:last-child {
                    border-right: 1px solid #405261;
                  }
                }
              }
              & > div {
                width: calc(100% / 3);
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #d5dae3;
                box-sizing: border-box;
                &:nth-child(1) {
                  color: #fff;
                  font-weight: 600;
                  background-color: #405261;
                }
              }
            }
          }
          &.tbl_report {
            .row {
              border-bottom: 1px solid #d5dae3;
              &:first-child {
                color: #fff;
                font-weight: 600;
                background-color: #405261;
                border-top: 1px solid #d5dae3;
                & > div {
                  color: #fff;
                  font-weight: 600;
                  &:last-child {
                    border-right: 1px solid #405261;
                  }
                }
              }
              & > div {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                text-align: center;
                height: 40px;
                border-right: 1px solid #d5dae3;
                box-sizing: border-box;
                &:nth-child(1) {
                  width: 180px;
                  color: #fff;
                  font-weight: 600;
                  background-color: #405261;
                }
              }
            }
          }
        }
      }
    }
  }

  .list_wrap {
    margin-top: -11px;
    .table_list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: none;
    }
  }
}
.mando_table {
  position: static;
}
</style>
