<template>
  <div class="filter_box">
    <!-- <div v-if="readOnlyProp===false" class="date_filter">                                                    -->
    <!-- <div class="date_period_first"> Handler ID</div>
            <div class="date_period_second" ref="handlerKey" > {{handlerKey}}  </div>
        </div> -->
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="first">HtmlBox Type</div>
      <div class="second" style="border-top: 1px solid #d5dae3">htmlBox</div>
    </div>
    <!-- <div class="date_filter" v-if="readOnlyProp === false">
      <div class="first">Data Mapping</div>
      <div class="second">
        <select
          v-model="dataMapCategory"
          @change="dataMapCateGoryChange($event)"
        >
          <option value disabled>선택해주세요</option>
          <option value="Data">Data Set</option>
          <option value="API">Rest API</option>
        </select>
      </div>
    </div>
    <div
      class="date_filter"
      v-if="dataMapCategory === 'Data' && readOnlyProp === false && dataSetComponent.dataSet"
    >
      <div class="first">Data Set Type</div>
      <div class="second">
        <select
          name=""
          id=""
          v-model="selectDataTitle"
          @change="dataSetTitleChange($event)"
          style="width: 120px"
        >
          <option
            v-for="item in dataSetComponent.dataSet"
            :key="item.title"
            :value="item.title"
          >
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="date_filter"
      v-if="
        dataMapCategory === 'Data' &&
        readOnlyProp === false &&
        selectDataTitle !== ''
      "
    >
      <div class="first">Data Type</div>
      <div class="second" v-if="dataSet">
        <select v-model="selectDataSetType" @change="selectDataSetting($event)">
          <option disabled>선택해주세요</option>
          <option v-for="item in dataSetType" :key="item">{{ item }}</option>
        </select>
      </div>
    </div>

    <div
      class="date_filter"
      v-if="filterDataList !== undefined && filterDataList.length > 0"
    >
      <div class="first">Filter Type</div>
      <div class="second">
        <select
          v-model="filterType"
          @change="selectFilterSetting($event)"
          style="width: 120px"
        >
          <option
            v-for="item in filterDataList"
            :key="item.index"
            :value="item.index"
          >
            Filter {{ item.index }}
          </option>
        </select>
      </div>
    </div> -->
    <!-- <div class="date_filter">
        <div class="first">API</div>
        <div class="second">
          <select name="" id="" @change="labelDataSetting($event)">
            <option value="">None</option>
            <option value="dataSet" >/api/test</option>
          </select>     
        </div>     
        </div> -->
    <div
      class="date_filter"
      style="width: 100%; height: 150px; display: block; margin-top: 20px"
    >
      <QuillEditor
        ref="quillEditor"
        @input="onEditorChange"
        v-model="content"
        :options="editorOptions"
      />
    </div>
  </div>

  <!-- <div class="date_filter">                                                   
            <div class="date_period_first"> 제어 Handler </div>
            <div class="date_period_second"> 
              <select v-model="parentHandlerKey" @change="updateParent($event)">
                <option value=""  selected>None</option>
                <option v-for="(item) in handlerList.filter((ele)=>ele.handlerKey!==otherProp.handlerKey)"  :key="item.handlerKey"  :value="item.handlerKey">
                  handlerKey : {{item.handlerKey}}
                </option>
              </select>
            </div>
        </div> -->

  <!-- ColorPicker를 조건부로 렌더링 -->
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@/assets/js/customBlocked";

export default {
  components: {
    QuillEditor,
  },
  props: {
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
    keyValueList: {
      type: Object,
    },
    keyValueType: {
      type: String,
    },
    labelHtml: {
      type: String,
    },
    dataSet: {
      type: Object,
    },
    dataSetComponent: {
      type: Object,
    },
    filterDataList: {
      type: Object,
    },
  },

  data() {
    return {
      // dataMapCategory:"",
      content: "",
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      dataMapCategory: "Data",
      parentHandlerKey: "",
      testData: [],
      components: [{ selectSectionYn: null }],
      editorOptions: {
        theme: "snow", // 'bubble', 'snow', 'core' 중 선택
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["image"],
          ],
        },
      },
      dataSetType: {},
      selectDataTitle: "",
      filterType: "",
      selectDataSetType: "",
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    console.log("-----------label-------------------------------------");
    console.log(prop);
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    if (vm.labelHtml !== undefined) {
      vm.content = vm.labelHtml;
    }
    vm.labelText = prop.labelText;
    vm.parameter = prop.parameter;
    const quillInstance = this.$refs.quillEditor.getQuill();
    if (this.otherProp.labelHtml !== undefined) {
      this.content = prop.labelHtml;
      quillInstance.clipboard.dangerouslyPasteHTML(prop.labelHtml);
    }

    quillInstance.on("text-change", () => {
      this.$emit("changeTextHtml", {
        index: this.componentIndex,
        value: quillInstance.root.innerHTML,
      });
      quillInstance.on("selection-change", (range) => {
        if (range) {
          this.$emit("changeTextHtml", {
            index: this.componentIndex,
            value: quillInstance.root.innerHTML,
          });
        } else {
          console.log("Editor out of focus");
        }
      });
      // 여기서 원하는 함수를 호출하거나 상태를 업데이트
    });

    this.$emit("changeTextHtml", {
      index: this.componentIndex,
      value: prop.labelHtml,
    });
    //handler 제어 할려면 handler ID 값을 따로 저장해두는 곳이 있어야 하지 않나...
    if (this.dataSet !== undefined && this.dataSet !== null) {
      if (this.dataSet.label !== undefined) {
        this.dataSetType = this.dataSet.label.map((ele) => {
          return ele.title;
        });
      }
    }

    if (prop.selectDataTitle2 !== undefined) {
      vm.selectDataTitle = prop.selectDataTitle2;
    }

    if (prop.dataMapCategory !== "") {
      vm.dataMapCategory = prop.dataMapCategory;
    }

    if (prop.filterType !== "") {
      vm.filterType = prop.filterType;
    }

    if (prop.dataSetType !== "") {
      vm.selectDataSetType = prop.dataSetType;
    }
  },
  methods: {
    updateParent(event) {
      this.$emit("updateParent", {
        index: this.componentIndex,
        value: event.target.value,
      });
    },
    labelTextChange(event) {
      this.labelText = event.target.value;
      const item = this.otherProp;
      item.labelText = this.labelText;
    },
    parameterTextChange(event) {
      this.parameter = event.target.value;
      const item = this.otherProp;
      item.parameter = this.parameter;
    },
    removeItem() {
      this.$emit("removeItem");
    },
    labelDataSetting() {},
    onEditorChange(value) {
      this.content = value.target.outerHTML; // content에 강제 반영
      this.$emit("changeTextHtml", {
        index: this.componentIndex,
        value: this.content,
      });
    },
    dataMapCateGoryChange(event) {
      this.dataMapCategory = event.target.value;
      console.log("dataMapChange");
      this.$emit("selectDataMapCategory", {
        index: this.componentIndex,
        value: this.dataMapCategory,
      });
    },
    selectDataSetting(event) {
      const title = event.target.value;
      console.log("selectData");
      console.log(title);
      this.selectDataSet = this.dataSet.label.filter(
        (ele) => ele.title === title
      );

      console.log(this.selectDataSet);
      const format = this.selectDataSet[0].format;
      const title2 = "<h2>" + format.title + "</h2><br>";
      let contents = format.contents;
      let result = title2;
      const type = this.selectDataSet[0].type;
      if (type === "label1") {
        contents = "<span>" + contents + "</span>";
      } else if (type === "label2") {
        contents = "<span>" + contents + "</span>";
      } else if (type === "label3") {
        let content = "";
        for (let i = 0; i < contents.length; i++) {
          content +=
            '<span id="' +
            contents[i].key +
            '">' +
            contents[i].value +
            "</span><br>";
        }
        contents = content;
      } else if (type === "label4") {
        let content = "";
        for (let i = 0; i < contents.length; i++) {
          content +=
            '<span id="' + contents[i] + '">' + contents[i] + "</span><br>";
        }
        contents = content;
      }
      result += contents;
      const quillInstance = this.$refs.quillEditor.getQuill();
      // const title = this.selectDataSet
      quillInstance.clipboard.dangerouslyPasteHTML(result);

      this.$emit("selectUpdateDataSet", {
        index: this.componentIndex,
        value: this.selectDataSetType,
        value2: this.selectDataTitle,
      });
    },
    dataSetTitleChange(event) {
      const title = event.target.value;
      this.$emit("selectdataSettingTitle", {
        index: this.componentIndex,
        value: title,
      });
    },
    selectFilterSetting(event) {
      const filterIndex = event.target.value;
      this.$emit("selectFilterSettingTitle", {
        index: this.componentIndex,
        value: filterIndex,
      });
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
  }
}

.labelText {
  width: 50%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}
// #quillEditor{
//   ::v-deep(.ql-editor){
//       /* 기본적인 bold 스타일 강제 적용 */
//       strong {
//         font-weight: bold !important; /* 강제적으로 굵게 표시 */
//       }

//       /* 기본적인 italic 스타일 강제 적용 */
//       em {
//         font-style: italic !important; /* 강제적으로 기울임 표시 */
//       }
//       strong em, em strong {
//       font-weight: bold !important;
//       font-style: italic !important;
//     }

//   }
// }
</style>
<style scoped lang="scss">
.filter_box {
  height: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
.labelText {
  width: 50%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}

.range {
  width: 25%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.divide {
  min-height: 50px;
  margin: 0;
  &:first-child {
    .second {
      border-top: 1px solid #d5dae3;
    }
  }
  &:last-child {
    .first {
      border: 0;
    }
  }
  .first {
    width: 40%;
    height: 100%;
    background-color: #00b4ed;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 600;
  }
  .second {
    height: 100%;
    border-right: 1px solid #d5dae3;
    border-bottom: 1px solid #d5dae3;
    box-sizing: border-box;
    .btn_plus {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border-radius: 4px;
    }
  }
}
select {
  background-position: center right 15px;
}
.filter_box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    &:first-child {
      .date_period_second {
        border-top: 1px solid #d5dae3;
      }
    }
    .date_period {
      width: 240px;
      display: flex;
      justify-content: flex-end; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      display: flex;
      height: 100%;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      background-color: #00b4ed;
      color: #fff;
      font-weight: 600;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-right: 1px solid #d5dae3;
      border-bottom: 1px solid #d5dae3;
      box-sizing: border-box;
      gap: 10px;
      input[type="text"] {
        padding: 0 10px;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        height: 40px !important;
        box-sizing: border-box;
      }
      select {
        background-position: center right 15px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px; /* label과 input 사이 간격 */
  }
  input[type="number"] {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d5dae3;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
.close {
  font-size: 20px;
  color: #bbb;
}
</style>
