<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_labelMonitoring"
        :name="'[TASK_AD] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <!-- <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li> -->
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div class="item_search">
          <select v-model="edgeId" @change="chgEdgeId">
            <option value="0">Edge ID</option>
            <option
              v-for="(item, index) in searchData"
              :key="index"
              :value="item.edge_id"
            >
              {{ item.edge_id }}
            </option>
          </select>
          <select v-model="searchSite">
            <option value="" selected>Site</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.site"
            >
              {{ item.site }}
            </option>
          </select>
          <select v-model="searchWc">
            <option value="">WC</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.wc"
            >
              {{ item.wc }}
            </option>
          </select>
          <select v-model="searchModel">
            <option value="">Model</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.models"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <select v-model="searchPoint">
            <option value="">Point</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.points"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <!-- <button type="button" class="mcbtn" @click="searchLabelData">
            선택
          </button> -->
        </div>
        <div class="filter_box">
          <div class="date_filter">
            <div class="date_period">
              <div class="date_box">
                <span
                  @click="dateAction('S')"
                  style="
                    display: inline-block;
                    cursor: pointer;
                    padding: 0 10px 0 10px;
                  "
                  :style="picStartTxtStyle"
                  ref="picStartTxt"
                  >{{ picStartDate }}</span
                >
                ~
                <span
                  @click="dateAction('E')"
                  style="
                    display: inline-block;
                    cursor: pointer;
                    padding: 0 0 0 10px;
                  "
                  :style="picEndTxtStyle"
                  ref="picEndTxt"
                  >{{ picEndDate }}</span
                >
              </div>
              <button type="button" ref="datePicToggle"></button>
              <div
                class="calender_box radiusbox"
                ref="calenderBox"
                style="display: none"
              >
                <div
                  class="start_date date_box"
                  ref="startDateBox"
                  style="display: none"
                >
                  <VCalendarUi
                    :dateInterface="'start'"
                    :setDate="'7'"
                    :maxDate="picEndDate"
                    v-model="picStartDateCalendar"
                    @dayclick="onDatePic('S', picStartDateCalendar)"
                  />
                </div>
                <div
                  class="end_date date_box"
                  ref="endDateBox"
                  style="display: none"
                >
                  <VCalendarUi
                    :dateInterface="'end'"
                    :minDate="picStartDate"
                    v-model="picEndDateCalendar"
                    @dayclick="onDatePic('E', picEndDateCalendar)"
                  />
                </div>
              </div>
            </div>
            <div class="search_box">
              <input
                type="text"
                v-model="searchKeyword"
                ref="searchKeyword"
                placeholder="Hsg' Barcode"
              />
            </div>
            <select v-model="searchMC">
              <option value="">판정 결과(MC)</option>
              <option value="ok">ok</option>
              <option value="ng">ng</option>
            </select>
            <select v-model="searchML">
              <option value="">판정 결과(ML)</option>
              <option value="ok">ok</option>
              <option value="ng">ng</option>
              <option value="fail">fail</option>
            </select>
            <select v-model="searchMA">
              <option value="">판정 결과(MA)</option>
              <option value="ok">ok</option>
              <option value="ng">ng</option>
              <option value="NULL">NULL</option>
            </select>
            <button
              type="button"
              class="btn_send mcbtn"
              @click="searchLabelData"
            >
              조회
            </button>
          </div>
        </div>
        <div id="monCont" class="contents">
          <div class="radiusbox">
            <!-- <div class="filter_box">
              <div class="date_filter">
                <div class="date_period">
                  <div class="date_box">
                    <span
                      @click="dateAction('S')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 10px 0 10px;
                      "
                      :style="picStartTxtStyle"
                      ref="picStartTxt"
                      >{{ picStartDate }}</span
                    >
                    ~
                    <span
                      @click="dateAction('E')"
                      style="
                        display: inline-block;
                        cursor: pointer;
                        padding: 0 0 0 10px;
                      "
                      :style="picEndTxtStyle"
                      ref="picEndTxt"
                      >{{ picEndDate }}</span
                    >
                  </div>
                  <button type="button" ref="datePicToggle"></button>
                  <div
                    class="calender_box radiusbox"
                    ref="calenderBox"
                    style="display: none"
                  >
                    <div
                      class="start_date date_box"
                      ref="startDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'start'"
                        :setDate="'7'"
                        :maxDate="picEndDate"
                        v-model="picStartDateCalendar"
                        @dayclick="onDatePic('S', picStartDateCalendar)"
                      />
                    </div>
                    <div
                      class="end_date date_box"
                      ref="endDateBox"
                      style="display: none"
                    >
                      <VCalendarUi
                        :dateInterface="'end'"
                        :minDate="picStartDate"
                        v-model="picEndDateCalendar"
                        @dayclick="onDatePic('E', picEndDateCalendar)"
                      />
                    </div>
                  </div>
                </div>
                <div class="search_box">
                  <input
                    type="text"
                    v-model="searchKeyword"
                    ref="searchKeyword"
                    placeholder="검색어를 입력해 주세요."
                  />
                </div>
                <button type="button" class="btn_send mcbtn">조회</button>
              </div>
            </div> -->
            <div class="mando_table_wrap">
              <table class="mando_table">
                <thead>
                  <tr>
                    <th rowspan="2">Date</th>
                    <th rowspan="2">Hsg'Barcode</th>
                    <th rowspan="2">Model</th>
                    <th rowspan="2">Point</th>
                    <th colspan="3">판정결과</th>
                  </tr>
                  <tr>
                    <th style="min-width: 100px">MC</th>
                    <th>ML</th>
                    <th>MA</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="dataList && dataList.length > 0">
                    <tr
                      v-for="(item, index) in dataList"
                      :key="index"
                      :class="item.isActive ? 'active' : ''"
                      @click="showImg(item.image, item.data_id, index)"
                    >
                      <td>{{ item.create_date.replace("T", " ") }}</td>
                      <td>{{ item.barcode }}</td>
                      <td>{{ item.model }}</td>
                      <td>{{ item.point }}</td>
                      <td>{{ item.mc }}</td>
                      <td>{{ item.ml }}</td>
                      <td>{{ item.ma }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <td colspan="7">데이터가 없습니다.</td>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="list_wrap" style="margin-top: -10px">
              <div class="table_list">
                <div class="list_table_footer">
                  <PaginationUi
                    :totalItems="totalItems"
                    :itemsPerPage="itemsPerPage"
                    @page-changed="onPageChanged"
                  ></PaginationUi>
                </div>
              </div>
            </div>
          </div>
          <div class="radiusbox selected_data">
            <div class="count_wrap">
              <div class="title">
                <div>개수</div>
                <div>OK</div>
                <div>NG</div>
              </div>
              <div class="data">
                <div>{{ dataList.length }}</div>
                <div class="color_01">{{ percentageOK }}%</div>
                <div class="color_02">{{ percentageNG }}%</div>
              </div>
            </div>
            <div class="cropper">
              <cropper ref="cropper" :src="selectedImgSrc" :auto-zoom="true" />
            </div>
            <div class="control">
              <div class="btn">
                <button type="button" @click="showPrevImg" class="mcbtn">
                  <span>◀</span>이전<br />이미지
                </button>
                <button type="button" @click="showNextImg" class="mcbtn">
                  <span>▶</span>다음<br />이미지
                </button>
              </div>
              <div class="judge">
                <div class="left">
                  <div class="title">최종판정(MA)</div>
                  <div class="result">
                    <div
                      class="detm color_01"
                      @click="judgeResult($event, 'ok')"
                    >
                      OK
                    </div>
                    <div
                      class="detm color_02"
                      @click="judgeResult($event, 'ng')"
                    >
                      NG
                    </div>
                  </div>
                </div>
                <div class="right">
                  <button type="button" class="mcbtn" @click="saveResult">
                    판정결과<br />저장하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import common from "@/assets/js/common";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import apiVision from "@/assets/js/apiVision";
import PaginationUi from "@/components/PagenationUi.vue";
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    VCalendarUi,
    Cropper,
    PaginationUi,
  },
  data() {
    return {
      taskId: "",
      prjId: "",
      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      // 기간선택
      selectDuration: 7,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      picStartDateModal: "",
      picEndDateModal: "",
      picColorModal: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      selectDate: "1",

      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,

      edgeDeviceList: [],
      edgeId: 0,
      searchData: [],
      searchSite: "",
      searchWc: "",
      searchModel: "",
      searchPoint: "",

      findSite: "",
      findWC: "",
      findModel: "",
      findPoint: "",
      findSDate: "",
      findEDate: "",
      findEBarcode: "",
      findMC: "",
      findML: "",
      findMA: "",

      searchKeyword: "",
      dataList: [],
      selectedDataId: 0,
      selectedImgSrc: "",

      percentageOK: 0,
      percentageNG: 0,
      determination: "",

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,

      searchMC: "",
      searchML: "",
      searchMA: "",
    };
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picStartTxtStyleModal() {
      return {
        color: this.picColorModal.startStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
    picEndTxtStyleModal() {
      return {
        color: this.picColorModal.endStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
  },
  async mounted() {
    // Role
    this.$refs.lodingWrap.style.display = "block";
    await this.setRole();
    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();
    this.setTaskInfo();
    await this.getEdgeDeviceList();
    await this.getSearchItem();
    this.setDate(this.selectDuration);
    //await this.getDataList();
    //this.countResult();
    this.$refs.lodingWrap.style.display = "none";
  },
  watch: {
    selectDuration() {
      this.setDate(this.selectDuration);
    },
  },
  methods: {
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getDataList();
      this.countResult();
    },
    async searchLabelData() {
      /*
      if (!this.searchSite) {
        alert("Site를 선택해 주세요.");
        return false;
      }

      if (!this.searchWc) {
        alert("WC를 선택해 주세요.");
        return false;
      }

      if (!this.searchModel) {
        alert("Model을 선택해 주세요.");
        return false;
      }

      if (!this.searchPoint) {
        alert("Point를 선택해 주세요.");
        return false;
      }
        */
      this.findSite = this.searchSite;
      this.findWC = this.searchWc;
      this.findModel = this.searchModel;
      this.findPoint = this.searchPoint;
      this.findSDate = this.picStartDate;
      this.findEDate = this.picEndDate;
      this.findEBarcode = this.searchKeyword;
      this.findMC = this.searchMC;
      this.findML = this.searchML;
      this.findMA = this.searchMA;

      await this.getDataList();
      this.countResult();
    },
    countResult() {
      const allCnt = this.dataList.length;
      let ok = 0;
      let ng = 0;
      for (let item of this.dataList) {
        if (item.ma === "ok") {
          ok++;
        } else if (item.ma === "ng") {
          ng++;
        }
      }
      this.percentageOK = ok !== 0 ? (100 * ok) / allCnt : 0;
      this.percentageNG = ng !== 0 ? (100 * ng) / allCnt : 0;
    },
    judgeResult(e, result) {
      document.querySelectorAll(".detm").forEach((e) => {
        e.classList.remove("active");
      });
      e.target.classList.add("active");
      this.determination = result;
    },
    showPrevImg() {
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.selectedDataId === this.dataList[i].data_id) {
          const targetId =
            i > 0
              ? this.dataList[i - 1].data_id
              : this.dataList[this.dataList.length - 1].data_id;

          const targetImg =
            i > 0
              ? this.dataList[i - 1].image
              : this.dataList[this.dataList.length - 1].image;

          this.selectedDataId = targetId;
          this.selectedImgSrc = this.selectedImgReplace(targetImg);

          break;
        }
      }
    },
    showNextImg() {
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.selectedDataId === this.dataList[i].data_id) {
          const targetId =
            i < this.dataList.length - 1
              ? this.dataList[i + 1].data_id
              : this.dataList[0].data_id;
          const targetImg =
            i < this.dataList.length - 1
              ? this.dataList[i + 1].image
              : this.dataList[0].image;

          this.selectedDataId = targetId;
          this.selectedImgSrc = this.selectedImgReplace(targetImg);

          break;
        }
      }
    },

    async showImg(selectedImg, dataId, index) {
      const vm = this;
      this.$refs.lodingWrap.style.display = "block";
      this.selectedDataId = dataId;
      const img = this.selectedImgReplace(selectedImg);
      for (let item of this.dataList) {
        item.isActive = false;
      }
      this.dataList[index].isActive = true;
      return new Promise((resolve) => {
        vm.selectedImgSrc = img;
        resolve();
      }).then(function () {
        vm.$refs.lodingWrap.style.display = "none";
      });
    },
    selectedImgReplace(d) {
      let image_bucket = d.split("/")[2];
      let image_path = "";
      if (document.location.protocol.indexOf("https:") > -1) {
        image_path = d.replace(
          "s3://" + image_bucket + "/",
          "https://" + image_bucket + ".s3.ap-northeast-2.amazonaws.com/"
        );
      } else {
        image_path = d.replace(
          "s3://" + image_bucket + "/",
          "http://" + image_bucket + ".s3-website.ap-northeast-2.amazonaws.com/"
        );
      }
      return image_path;
    },
    chgEdgeId() {
      this.searchSite = "";
      this.searchWc = "";
      this.searchModel = "";
      this.searchPoint = "";
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },

    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    // -------- DATE PICKER END -----

    nowTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // -------- DATE PICKER -----
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    async resetData() {
      this.$refs.lodingWrap.style.display = "block";
      await this.getDataList();
      this.selectedImgSrc = "";
      this.selectedDataId = 0;
      this.countResult();
      document.querySelectorAll(".detm").forEach((e) => {
        e.classList.remove("active");
      });
      this.$refs.lodingWrap.style.display = "none";
    },
    async saveResult() {
      if (this.selectedDataId === 0) {
        alert("판정결과를 저장할 데이터를 선택해 주세요.");
        return false;
      }

      if (this.determination === "") {
        alert("판정을 선택해 주세요.");
        return false;
      }

      const param = {
        data_id: this.selectedDataId,
        determination: this.determination,
      };
      try {
        const response = await apiVision.put(
          "/vision/vision_determine_ma/",
          param
        );
        if (response.status === 200 || response.status === "200") {
          alert("저장되었습니다.");
          this.resetData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getDataList() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      const edgeid = this.edgeId;
      const site = this.findSite;
      const wc = this.findWC;
      const model = this.findModel;
      const point = this.findPoint;
      const barcode = this.findEBarcode;
      const start = this.findSDate;
      const end = this.findEDate;
      const mc = this.findMC;
      const ml = this.findML;
      const ma = this.findMA;
      const param = {
        edgeid: edgeid,
        site: site,
        wc: wc,
        model: model,
        point: point,
        barcode: barcode,
        start: start,
        end: end,
        data_filter: {
          ma: ma,
          mc: mc,
          ml: ml,
        },
        offset: offset,
        limit: limit,
      };
      /*
          const param = {
            edgeid: 1,
            site: 5,
            wc: 7,
            model: "11",
            point: "1",
            barcode: "TE",
        start: "2024-05-28",
        end: "2024-05-30",
        data_filter: {
          ma: "NULL",
          mc: "",
          ml: "fail",
        },
        offset: 0,
        limit: 10,
      };
      */
      try {
        const response = await apiVision.post(
          "/vision/vision_edge_item_detail/",
          param
        );
        this.dataList = [...response.data.data];
        console.log("response.data :: ", response.data);
        this.totalItems = response.data.total;
        this.listOffset = response.data.current_offset;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      loading.style.display = "none";
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];

            console.log("엣지 디바이스 !!! :: ", this.edgeDeviceList);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getSearchItem() {
      const array = [];
      for (let item of this.edgeDeviceList) {
        array.push(item.id);
      }
      const param = {
        edgeids: array,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_check_edge/",
          param
        );
        this.searchData = [...response.data.edge];
        console.log("this.searchData :: ", this.searchData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    // ----- API
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },
    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.projectData = await getPjtData.project;
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_search {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 12px 0 20px;
  select {
    min-width: 170px;
    background-position: center right 15px;
  }
  button {
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
}
.filter_box {
  margin-bottom: 20px;
  .date_filter {
    display: flex;
    align-items: center;
    padding: 0;
    left: 0;
    position: relative;
    & select {
      position: relative;
      background-position: right 10px center;
    }
    &::after {
      display: none;
    }
    .search_box {
      margin-right: 10px;
      input[type="text"] {
        width: 210px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 15px;
        color: rgb(2, 10, 10);
      }
    }
    select {
      min-width: 160px;
    }
  }
}
.contents {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  & > div {
    width: calc(50% - 10px);
    box-sizing: border-box;
    padding-top: 20px;
  }
  /*
  .date_filter {
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative;
    & select {
      position: relative;
      background-position: right 10px center;
    }
    &::after {
      display: none;
    }
    .search_box {
      margin-right: 10px;
      input[type="text"] {
        width: 210px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 15px;
        color: rgb(2, 10, 10);
      }
    }
  }
    */
  .mando_table_wrap {
    margin-top: 20px;
    height: 650px;
    .mando_table {
      tbody {
        tr {
          position: relative;
          cursor: pointer;
          &.active {
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: calc(100% - 8px);
              border: 5px solid #405261;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
      th,
      td {
        border: 1px solid #e5e9ef;
        vertical-align: middle;
        &::after {
          display: none;
        }
      }
    }
  }
  .selected_data {
    padding: 20px;
    .count_wrap {
      & > div {
        display: flex;
        align-items: center;
        height: 35px;
        border-top: 1px solid #d5dae3;
        border-bottom: 1px solid #d5dae3;
        & > div {
          width: calc(100% / 3);
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          border-left: 1px solid #d5dae3;
          border-right: 1px solid #d5dae3;
          &:nth-child(2) {
            border: 0;
          }
        }
      }
      .title {
        background-color: #405261;
        & > div {
          color: #fff;
        }
      }
      .data {
        border-top: 0;
        & > div {
          font-size: 20px;
          font-weight: 600;
          &.color_01 {
            color: #02a744;
          }
          &.color_02 {
            color: #f15046;
          }
        }
      }
    }
    .cropper {
      margin-top: 20px;
    }
    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 74px;
      margin-top: 20px;
      .btn {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 10px;
        & > button {
          height: 100%;
          padding: 0 14px;
          text-align: center;
          box-sizing: border-box;
          border-radius: 10px;
          & > span {
            display: block;
            color: #fff;
            margin-bottom: 5px;
          }
        }
      }
      .judge {
        display: flex;
        align-items: center;
        height: 100%;
        & > div {
          height: 100%;
        }
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 184px;
          border-right: 0;
          text-align: center;
          .title {
            width: 100%;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #405261;
            color: #fff;
          }
          .result {
            display: flex;
            align-items: center;
            flex: 1;
            width: 100%;
            border-left: 1px solid #d5dae3;
            border-bottom: 1px solid #d5dae3;
            & > div {
              position: relative;
              width: 50%;
              height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              box-sizing: border-box;
              cursor: pointer;
              &.color_01 {
                border-right: 1px solid #d5dae3;
                color: #02a744;
              }
              &.color_02 {
                color: #f15046;
              }
              &.active {
                border: 3px solid #405261;
              }
            }
          }
        }
        .right {
          button {
            height: 100%;
            padding: 0 14px;
          }
        }
      }
    }
  }
  .list_wrap {
    margin-top: -11px;
    .table_list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: none;
    }
  }
}
</style>
