<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_systemDataStatus"
        :name="'[TASK_AD] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <!-- <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li> -->
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div class="item_search">
          <select v-model="edgeId" @change="chgEdgeId">
            <option value="0">Edge ID</option>
            <option
              v-for="(item, index) in searchData"
              :key="index"
              :value="item.edge_id"
            >
              {{ item.edge_id }}
            </option>
          </select>
          <select v-model="searchSite">
            <option value="" selected>Site</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.site"
            >
              {{ item.site }}
            </option>
          </select>
          <select v-model="searchWc">
            <option value="">WC</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )"
              :key="index"
              :value="item.wc"
            >
              {{ item.wc }}
            </option>
          </select>
          <select v-model="searchModel">
            <option value="">Model</option>
            <option
              v-for="(item, index) in searchData.filter(
                (e) => e.edge_id === edgeId
              )[0]?.models"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
          <button type="button" class="mcbtn" @click="getSystemStatus">
            선택
          </button>
        </div>
        <div id="monCont" class="contents">
          <div class="radiusbox databox">
            <div class="data data_01">
              <div class="title">수집 데이터</div>
              <div class="txt">
                {{
                  dataStatus.data_count
                    ? dataStatus.data_count.toLocaleString()
                    : 0
                }}
              </div>
            </div>
            <div class="data data_02">
              <div class="title">OK 데이터</div>
              <div class="txt">
                {{
                  dataStatus.ok_count ? dataStatus.ok_count.toLocaleString() : 0
                }}
              </div>
            </div>
            <div class="data data_03">
              <div class="title">NG 데이터</div>
              <div class="txt">
                {{
                  dataStatus.ng_count ? dataStatus.ng_count.toLocaleString() : 0
                }}
              </div>
            </div>
            <div class="data data_04">
              <div class="title">Manual 데이터</div>
              <div class="txt">
                {{
                  dataStatus.manual_count
                    ? dataStatus.manual_count.toLocaleString()
                    : 0
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import common from "@/assets/js/common";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import apiVision from "@/assets/js/apiVision";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
  },
  data() {
    return {
      taskId: "",
      prjId: "",
      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      edgeDeviceList: [],
      edgeId: 0,
      searchData: [],
      searchSite: "",
      searchWc: "",
      searchModel: "",

      dataStatus: {},
    };
  },
  computed: {},
  async mounted() {
    const loading = this.$refs.lodingWrap;
    loading.style.display = "block";
    // Role
    await this.setRole();
    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();
    this.setTaskInfo();
    await this.getEdgeDeviceList();
    await this.getSearchItem();
    loading.style.display = "none";
  },
  methods: {
    chgEdgeId() {
      this.searchSite = "";
      this.searchWc = "";
      this.searchModel = "";
      this.searchPoint = "";
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    // ----- API
    async getSystemStatus() {
      if (!this.searchSite) {
        alert("Site를 선택해 주세요.");
        return false;
      }

      if (!this.searchWc) {
        alert("WC를 선택해 주세요.");
        return false;
      }

      if (!this.searchModel) {
        alert("Model을 선택해 주세요.");
        return false;
      }

      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      const param = {
        site: this.searchSite,
        wc: this.searchWc,
        model: this.searchModel,
      };

      /*
      const param = {
        site: 6,
        wc: 8,
        model: "4",
      };
      */
      try {
        const response = await apiVision.post(
          "/vision/vision_sys_status/",
          param
        );
        this.dataStatus = response.data.data_status;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      loading.style.display = "none";
    },
    async getSearchItem() {
      const array = [];
      for (let item of this.edgeDeviceList) {
        array.push(item.id);
      }
      const param = {
        edgeids: array,
        //edgeids: [1, 2],
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_check_edge/",
          param
        );
        this.searchData = [...response.data.edge];
        console.log("this.searchData :: ", this.searchData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },
    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.projectData = await getPjtData.project;
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.item_search {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 12px 0 20px;
  select {
    min-width: 170px;
    background-position: center right 15px;
  }
  button {
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    padding: 0 15px;
    border-radius: 10px;
    line-height: 37px;
    font-size: 15px;
  }
}
.contents {
  .databox {
    display: flex;
    gap: 20px;
    padding: 20px;
    .data {
      flex: 1;
      box-sizing: border-box;
      padding: 20px;
      & > div {
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        &.title {
          height: 80px;
          color: #fff;
          font-weight: 600;
          font-size: 28px;
        }
        &.txt {
          height: 170px;
          background-color: #fff;
          border-top: 0;
          font-weight: 600;
          font-size: 50px;
        }
      }
      &.data_01 {
        background-color: #fff2d9;
        .title {
          background-color: #ffa800;
        }
      }
      &.data_02 {
        background-color: #d9f2e3;
        .title {
          background-color: #02a744;
        }
      }
      &.data_03 {
        background-color: #fde5e3;
        .title {
          background-color: #f15046;
        }
      }
      &.data_04 {
        background-color: #d9ecf2;

        .title {
          background-color: #00b4ed;
        }
      }
    }
  }
}
</style>
