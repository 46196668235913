<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="monTASK_inferenceStatus"
        :name="'[TASK_AD] ' + taskInfo.taskName"
      />
      <div class="item_info">
        <div class="item_title">
          <strong class="task_project"
            >[PROJECT] {{ projectData.project_name }}</strong
          >
          <div class="item_info_btn_box">
            <button type="button" class="btn_fold" @click="hideProjectInfo">
              {{ foldStatus }}
            </button>
            <button
              type="button"
              class="btn_evt_group"
              @click="hideUtils"
            ></button>
            <ul
              class="evt_btn_box radiusbox"
              :style="{ display: InferenceUtil ? 'block' : 'none' }"
            >
              <li class="download_item">
                <button type="button" @click="makePDF">데이터 다운로드</button>
              </li>
              <li class="share_item">
                <button type="button" @click="clipCoppy">공유하기</button>
              </li>
              <li class="retouch_item" v-if="manageRole">
                <button type="button" @click="taskModify">TASK 수정</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="item_table radiusbox" v-if="visibleProjectInfo">
          <table>
            <thead>
              <tr>
                <th>Project ID</th>
                <th>AWS ID</th>
                <th>PM</th>
                <th>Project Type</th>
                <th>Unit</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ projectData.project_name }}</td>
                <td>{{ projectData.aws_id }}</td>
                <td>{{ projectData.pm }}</td>
                <td>{{ projectData.project_type }}</td>
                <td>{{ projectData.division_name }}</td>
                <td>{{ projectData.last_activity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="item_desc">
            <div class="desc_title">Description</div>
            <div class="desc_txt">{{ projectData.project_description }}</div>
          </div>
        </div>
        <div class="search_wrap">
          <div class="item_search">
            <select v-model="edgeId" @change="chgEdgeId">
              <option value="0">Edge ID</option>
              <option
                v-for="(item, index) in searchData"
                :key="index"
                :value="item.edge_id"
              >
                {{ item.edge_id }}
              </option>
            </select>
            <select v-model="searchSite">
              <option value="" selected>Site</option>
              <option
                v-for="(item, index) in searchData.filter(
                  (e) => e.edge_id === edgeId
                )"
                :key="index"
                :value="item.site"
              >
                {{ item.site }}
              </option>
            </select>
            <select v-model="searchWc">
              <option value="">WC</option>
              <option
                v-for="(item, index) in searchData.filter(
                  (e) => e.edge_id === edgeId
                )"
                :key="index"
                :value="item.wc"
              >
                {{ item.wc }}
              </option>
            </select>
            <select v-model="searchModel">
              <option value="">Model</option>
              <option
                v-for="(item, index) in searchData.filter(
                  (e) => e.edge_id === edgeId
                )[0]?.models"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>

            <!-- <button type="button" class="mcbtn">선택</button> -->
          </div>
          <div class="filter_box">
            <div class="date_filter">
              <div class="date_period">
                <div class="date_box">
                  <span
                    @click="dateAction('S')"
                    style="
                      display: inline-block;
                      cursor: pointer;
                      padding: 0 10px 0 10px;
                    "
                    :style="picStartTxtStyle"
                    ref="picStartTxt"
                    >{{ picStartDate }}</span
                  >
                  ~
                  <span
                    @click="dateAction('E')"
                    style="
                      display: inline-block;
                      cursor: pointer;
                      padding: 0 0 0 10px;
                    "
                    :style="picEndTxtStyle"
                    ref="picEndTxt"
                    >{{ picEndDate }}</span
                  >
                </div>
                <button type="button" ref="datePicToggle"></button>
                <div
                  class="calender_box radiusbox"
                  ref="calenderBox"
                  style="display: none"
                >
                  <div
                    class="start_date date_box"
                    ref="startDateBox"
                    style="display: none"
                  >
                    <VCalendarUi
                      :dateInterface="'start'"
                      :setDate="'7'"
                      :maxDate="picEndDate"
                      v-model="picStartDateCalendar"
                      @dayclick="onDatePic('S', picStartDateCalendar)"
                    />
                  </div>
                  <div
                    class="end_date date_box"
                    ref="endDateBox"
                    style="display: none"
                  >
                    <VCalendarUi
                      :dateInterface="'end'"
                      :minDate="picStartDate"
                      v-model="picEndDateCalendar"
                      @dayclick="onDatePic('E', picEndDateCalendar)"
                    />
                  </div>
                </div>
              </div>
              <!-- <button type="button" class="btn_send mcbtn">조회</button> -->
              <ul>
                <li>
                  <input type="checkbox" id="chk_01" v-model="chkMC" />
                  <label for="chk_01"></label>
                  <span>MC</span>
                </li>
                <li>
                  <input type="checkbox" id="chk_02" v-model="chkML" />
                  <label for="chk_02"></label>
                  <span>ML</span>
                </li>
                <li>
                  <input type="checkbox" id="chk_03" v-model="chkMA" />
                  <label for="chk_03"></label>
                  <span>MA</span>
                </li>
              </ul>
              <button
                type="button"
                class="btn_send mcbtn"
                @click="searchStatus"
              >
                조회
              </button>
            </div>
          </div>
        </div>
        <div id="monCont" class="contents">
          <div class="radiusbox">
            <div class="mando_table_wrap" ref="tblModel">
              <table class="mando_table tbl_top">
                <thead>
                  <tr>
                    <th rowspan="2" style="border-left: 1px solid #00b4ed">
                      Model
                    </th>
                    <th v-if="chkList.includes('MC')" colspan="2">MC</th>
                    <th v-if="chkList.includes('ML')" colspan="2">ML</th>
                    <th v-if="chkList.includes('MA')" colspan="2">MA</th>
                  </tr>
                  <tr>
                    <th v-if="chkList.includes('MC')" style="min-width: 100px">
                      OK
                    </th>
                    <th v-if="chkList.includes('MC')" style="min-width: 100px">
                      NG
                    </th>
                    <th v-if="chkList.includes('ML')" style="min-width: 100px">
                      OK
                    </th>
                    <th v-if="chkList.includes('ML')" style="min-width: 100px">
                      NG
                    </th>
                    <th v-if="chkList.includes('MA')" style="min-width: 100px">
                      OK
                    </th>
                    <th v-if="chkList.includes('MA')" style="min-width: 100px">
                      NG
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-if="searchStatusData && searchStatusData.length > 0"
                  >
                    <tr v-for="(item, index) in searchStatusData" :key="index">
                      <td>
                        <button
                          type="button"
                          class="btn_more"
                          :class="item.btnToggleClass"
                          @click="btnToggle(item)"
                        >
                          <template
                            v-if="item.btnToggleClass === 'btn_more_open'"
                          >
                            <font-awesome-icon :icon="['fas', 'plus']" />
                          </template>
                          <template v-else>
                            <font-awesome-icon :icon="['fas', 'minus']" />
                          </template>
                        </button>
                        {{ modelNum }}
                      </td>
                      <td v-if="chkList.includes('MC')">
                        <template v-if="item.data.mc.ok != null">
                          {{ item.data.mc.ok_rate }}% ({{ item.data.mc.ok }}/{{
                            item.data.mc.ng + item.data.mc.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td v-if="chkList.includes('MC')">
                        <template v-if="item.data.mc.ng != null">
                          {{ item.data.mc.ng_rate }}% ({{ item.data.mc.ng }}/{{
                            item.data.mc.ng + item.data.mc.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td v-if="chkList.includes('ML')">
                        <template v-if="item.data.ml.ok != null">
                          {{ item.data.ml.ok_rate }}% ({{ item.data.ml.ok }}/{{
                            item.data.ml.ng + item.data.ml.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td v-if="chkList.includes('ML')">
                        <template v-if="item.data.ml.ng != null">
                          {{ item.data.ml.ng_rate }}% ({{ item.data.ml.ng }}/{{
                            item.data.ml.ng + item.data.ml.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td v-if="chkList.includes('MA')">
                        <template v-if="item.data.ma.ok != null">
                          {{ item.data.ma.ok_rate }}% ({{ item.data.ma.ok }}/{{
                            item.data.ma.ng + item.data.ma.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td v-if="chkList.includes('MA')">
                        <template v-if="item.data.ma.ng != null">
                          {{ item.data.ma.ng_rate }}% ({{ item.data.ma.ng }}/{{
                            item.data.ma.ng + item.data.ma.ok
                          }})
                        </template>
                        <template v-else> - </template>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="chkList.length * 2 + 1"
                        style="height: 120px"
                      >
                        데이터가 없습니다.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <div class="mando_table_wrap" ref="tblPoint" style="display: none">
              <div class="tbl_custom">
                <div class="row row_title">
                  <div class="txt" style="min-width: 84px">Point</div>
                  <div class="txt" style="min-width: 84px">구분</div>
                  <template v-for="(item, index) in dateRange" :key="index">
                    <div>
                      <div class="txt" style="height: 50%">{{ item }}</div>
                      <div class="flex_box" style="height: 50%">
                        <div class="txt" style="min-width: 84px">OK</div>
                        <div class="txt" style="min-width: 84px">NG</div>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  class="row row_data"
                  v-for="(item, index) in Object.keys(searchPointData)"
                  :key="index"
                >
                  <div style="height: 100%; border-right: 0">{{ item }}</div>
                  <div
                    class="flex_box col"
                    style="
                      max-width: 120px;
                      border-left: 1px solid #d5dae3;
                      border-right: 1px solid #d5dae3;
                    "
                  >
                    <div class="txt_height" v-if="chkList.includes('MC')">
                      MC
                    </div>
                    <div class="txt_height" v-if="chkList.includes('ML')">
                      ML
                    </div>
                    <div class="txt_height" v-if="chkList.includes('MA')">
                      MA
                    </div>
                  </div>
                  <template
                    v-for="(item_01, index_01) in dateRange"
                    :key="index_01"
                  >
                    <div class="flex_box row">
                      <div
                        class="flex_box col"
                        style="border-right: 1px solid #d5dae3"
                      >
                        <div class="txt_height" v-if="chkList.includes('MC')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.mc?.ok_rate
                              ? +searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.mc?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                        <div class="txt_height" v-if="chkList.includes('ML')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.ml?.ok_rate
                              ? searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.ml?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                        <div class="txt_height" v-if="chkList.includes('MA')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.ma?.ok_rate
                              ? searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.ma?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                      </div>
                      <div class="flex_box col">
                        <div class="txt_height" v-if="chkList.includes('MC')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.mc?.ok_rate
                              ? searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.mc?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                        <div class="txt_height" v-if="chkList.includes('ML')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.ml?.ok_rate
                              ? searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.ml?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                        <div class="txt_height" v-if="chkList.includes('MA')">
                          {{
                            searchPointData[item][
                              Object.keys(searchPointData[item]).filter((e) =>
                                e.includes(item_01)
                              )
                            ]?.ma?.ok_rate
                              ? searchPointData[item][
                                  Object.keys(searchPointData[item]).filter(
                                    (e) => e.includes(item_01)
                                  )
                                ]?.ma?.ok_rate + "%"
                              : "-"
                          }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="list_wrap" style="margin-top: -10px">
                <div class="table_list">
                  <div class="list_table_footer">
                    <!-- <div>
                      <div>Rows per page</div>
                      <select name="" id="">
                        <option value="">10</option>
                        <option value="">30</option>
                        <option value="">50</option>
                        <option value="">100</option>
                      </select>
                    </div> -->
                    <PaginationUi
                      :copId="'pagingPoint'"
                      :totalItems="totalItems"
                      :itemsPerPage="itemsPerPage"
                      @page-changed="onPageChanged"
                    ></PaginationUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import common from "@/assets/js/common";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import VCalendarUi from "@/components/VCalendarUi_NEW.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import apiVision from "@/assets/js/apiVision";

export default {
  name: "aaView",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    VCalendarUi,
    PaginationUi,
  },
  data() {
    return {
      taskId: "",
      prjId: "",
      foldStatus: "접기",
      InferenceUtil: false,

      visibleProjectInfo: true,

      projectData: {},

      taskInfo: {
        taskName: "",
        taskType: "",
      },
      projectId: "",

      // role
      viewRole: false,
      manageRole: false,

      // 기간선택
      selectDuration: 7,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      picStartDateModal: "",
      picEndDateModal: "",
      picColorModal: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },

      selectDate: "1",

      picStartDate: null,
      picEndDate: null,
      picStartDateCalendar: null,
      picEndDateCalendar: null,

      edgeDeviceList: [],
      edgeId: 0,
      searchData: [],
      searchSite: "",
      searchWc: "",
      searchModel: "",
      searchPoint: "",

      findSite: "",
      findWC: "",
      findModel: "",
      findSDate: "",
      findEDate: "",
      findMC: false,
      findML: false,
      findMA: false,

      totalItems: 0,
      itemsPerPage: 10,
      listOffset: 0,
      currentPage: 1,
      /*
      totalItemsStatus: 0,
      itemsPerPageStatus: 10,
      listOffsetStatus: 0,
      currentPageStatus: 1,
      */

      searchStatusData: {},
      searchPointData: {},

      chkMA: false,
      chkMC: false,
      chkML: false,
      chkList: [],
      modelNum: 0,

      pointList: [],
      dateRange: [],
    };
  },
  computed: {
    picStartTxtStyle() {
      return {
        color: this.picColor.startStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picEndTxtStyle() {
      return {
        color: this.picColor.endStatus
          ? this.picColor.active
          : this.picColor.default,
      };
    },
    picStartTxtStyleModal() {
      return {
        color: this.picColorModal.startStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
    picEndTxtStyleModal() {
      return {
        color: this.picColorModal.endStatus
          ? this.picColorModal.active
          : this.picColorModal.default,
      };
    },
  },
  async mounted() {
    const loading = this.$refs.lodingWrap;
    loading.style.display = "block";
    // Role
    await this.setRole();
    //  ------ API CALL
    this.prjId = this.$route.query.prjId;
    this.taskId = this.$route.query.taskId;
    this.setPrjView();
    this.setTaskInfo();

    await this.getEdgeDeviceList();
    await this.getSearchItem();
    this.setDate(this.selectDuration);
    loading.style.display = "none";
  },
  methods: {
    async onPageChanged(page) {
      this.currentPage = page;
      await this.getPointData();
    },
    /*
    async onPageChangedStatus(page) {
      this.currentPageStatus = page;
      await this.searchStatus();
    },
    */
    getDateRangeData(param1, param2) {
      //param1은 시작일, param2는 종료일이다.
      let res_day = [];
      let ss_day = new Date(param1);
      let ee_day = new Date(param2);
      while (ss_day.getTime() <= ee_day.getTime()) {
        var _mon_ = ss_day.getMonth() + 1;
        _mon_ = _mon_ < 10 ? "0" + _mon_ : _mon_;
        var _day_ = ss_day.getDate();
        _day_ = _day_ < 10 ? "0" + _day_ : _day_;
        res_day.push(ss_day.getFullYear() + "-" + _mon_ + "-" + _day_);
        ss_day.setDate(ss_day.getDate() + 1);
      }
      this.dateRange = [...res_day];
    },
    async btnToggle(item) {
      if (item.btnToggleClass === "btn_more_open") {
        await this.getPointData();
        item.btnToggleClass = "btn_more_close";
      } else {
        this.searchStatusData.forEach((e) => {
          e.btnToggleClass = "btn_more_open";
        });
        this.$refs.tblPoint.style.display = "none";
      }
    },
    async setRole() {
      const prjId = this.$route.query.prjId;
      this.manageRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type2", prjId));
      this.viewRole =
        (await common.getUserRole("type2")) ||
        (await common.getPrjRole("type1", prjId));

      if (!this.viewRole) {
        common.goHome();
      }
    },
    chgEdgeId() {
      this.searchSite = "";
      this.searchWc = "";
      this.searchModel = "";
      this.searchPoint = "";
    },
    onDatePic(flag, targetDay) {
      const year = targetDay.getFullYear();
      const month = targetDay.getMonth() + 1;
      const day = targetDay.getDate();
      const formattedDate =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day;

      if (flag === "S") {
        this.picStartDate = formattedDate;
        this.$refs.startDateBox.style.display = "none";
        this.picColor.startStatus = false;
      } else if (flag === "E") {
        this.picEndDate = formattedDate;
        this.$refs.endDateBox.style.display = "none";
        this.picColor.endStatus = false;
      }
    },

    setDate(day) {
      const vm = this;
      const stoday = new Date();
      stoday.setDate(stoday.getDate() - day);
      const syear = stoday.getFullYear();
      const smonth = stoday.getMonth() + 1;
      const sday = stoday.getDate();
      const start_formattedDate =
        syear +
        "-" +
        (smonth < 10 ? "0" : "") +
        smonth +
        "-" +
        (sday < 10 ? "0" : "") +
        sday;

      const etoday = new Date();
      const eyear = etoday.getFullYear();
      const emonth = etoday.getMonth() + 1;
      const eday = etoday.getDate();
      const end_formattedDate =
        eyear +
        "-" +
        (emonth < 10 ? "0" : "") +
        emonth +
        "-" +
        (eday < 10 ? "0" : "") +
        eday;
      // console.log(start_formattedDate + ' ~ ' + end_formattedDate);
      vm.picStartDateCalendar = new Date(start_formattedDate);
      vm.picEndDateCalendar = new Date(end_formattedDate);
      vm.picStartDate = start_formattedDate;
      vm.picEndDate = end_formattedDate;
      window.selectDate.startDate = start_formattedDate;
      window.selectDate.endDate = end_formattedDate;

      this.selectDate = day;
    },
    // -------- DATE PICKER END -----

    nowTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // -------- DATE PICKER -----
    dateAction(StartEnd) {
      if (StartEnd === "S") {
        const display = this.$refs.startDateBox.style.display;

        if (display === "none") {
          this.$refs.endDateBox.style.display = "none";
          this.$refs.startDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = true;
          this.picColor.endStatus = false;
        } else {
          this.picColor.startStatus = false;
          this.$refs.startDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      } else {
        const display = this.$refs.endDateBox.style.display;
        if (display === "none") {
          this.$refs.startDateBox.style.display = "none";
          this.$refs.endDateBox.style.display = "block";
          this.$refs.calenderBox.style.display = "block";
          this.picColor.startStatus = false;
          this.picColor.endStatus = true;
        } else {
          this.picColor.endStatus = false;
          this.$refs.endDateBox.style.display = "none";
          this.$refs.calenderBox.style.display = "none";
        }
      }
    },
    async getPointData() {
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      const param = {
        /*
        site: 6,
        wc: 8,
        model: "4",
        point: ["P2", "P3"],
        start: "2024-09-04",
        end: "2024-09-05",
        data_filter: {
          ma: this.findMA,
          mc: this.findMC,
          ml: this.findML,
        },
        offset: offset,
        limit: limit,
        */

        site: this.findSite,
        wc: this.findWC,
        model: this.findModel,
        point: this.pointList,
        start: this.findSDate,
        end: this.findEDate,
        data_filter: {
          ma: this.findMA,
          mc: this.findMC,
          ml: this.findML,
        },
        offset: offset,
        limit: limit,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_inference_status_detail/",
          param
        );

        this.searchPointData = response.data.data;
        this.getDateRangeData(param.start, param.end);
        this.pointList = Object.keys(this.searchPointData);
        this.modelNum = this.searchModel;
        this.$refs.tblPoint.style.display = "block";
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.$refs.tblPoint.style.display = "block";
      loading.style.display = "none";
    },
    async searchStatus() {
      if (!this.searchSite) {
        alert("Site를 선택해 주세요.");
        return false;
      }

      if (!this.searchWc) {
        alert("WC를 선택해 주세요.");
        return false;
      }

      if (!this.searchModel) {
        alert("Model을 선택해 주세요.");
        return false;
      }

      if (!this.chkMC && !this.chkMA && !this.chkML) {
        alert("최소 1개 이상의 항목을 체크해 주세요.");
        return false;
      }
      const loading = this.$refs.lodingWrap;
      loading.style.display = "block";
      //const offset = (this.currentPage - 1) * this.itemsPerPage;
      //const limit = this.itemsPerPage;

      this.findSite = this.searchSite;
      this.findWC = this.searchWc;
      this.findModel = this.searchModel;
      this.findSDate = this.picStartDate;
      this.findEDate = this.picEndDate;
      this.findMA = this.chkMA;
      this.findMC = this.chkMC;
      this.findML = this.chkML;

      const param = {
        /*
        site: 6,
        wc: 8,
        model: ["4"],
        start: "2024-09-04",
        end: "2024-09-05",
        data_filter: {
          mc: "true",
          ml: "true",
          ma: "true",
        },
        offset: 0,
        limit: 100,
        */

        site: this.findSite,
        wc: this.findWC,
        model: [this.findModel],
        start: this.findSDate,
        end: this.findEDate,
        data_filter: {
          mc: this.findMC,
          ml: this.findML,
          ma: this.findMA,
        },
        offset: 0,
        limit: 100,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_inference_status/",
          param
        );
        this.chkList = [];
        if (this.chkMA) {
          this.chkList.push("MA");
        }
        if (this.chkMC) {
          this.chkList.push("MC");
        }
        if (this.chkML) {
          this.chkList.push("ML");
        }
        this.searchStatusData = Object.values(response.data.data_status);

        this.searchStatusData.forEach((e) => {
          e.btnToggleClass = "btn_more_open";
        });

        this.modelNum = this.searchModel;
        this.$refs.tblPoint.style.display = "none";
        console.log("%%%%%%%%%% :: ", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      //this.$refs.tblModel.style.display = "block";
      loading.style.display = "none";
    },
    async getSearchItem() {
      const array = [];
      for (let item of this.edgeDeviceList) {
        array.push(item.id);
      }
      const param = {
        edgeids: array,
      };
      try {
        const response = await apiVision.post(
          "/vision/vision_check_edge/",
          param
        );
        this.searchData = [...response.data.edge];
        console.log("this.searchData :: ", this.searchData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getEdgeDeviceList() {
      try {
        // 프로젝트에 속한 Edge Device 정보
        await common
          .apiGet("/edge-device/project/" + this.$route.query.prjId)
          .then((r) => {
            console.log("0. monitoring-task 에 속한 edge-device 정보");
            console.log(r.data.data);

            this.edgeDeviceList = [...r.data.data];
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjInfo(prjId) {
      try {
        const response = await common.apiGet("/project/" + prjId);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getTaskData(taskId, viewRole) {
      try {
        const response = await common.apiGet(
          `/monitoring-task/${taskId}?viewRole=${viewRole}`
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async setTaskInfo() {
      const taskId = this.taskId;
      const taskInfo = await this.getTaskData(taskId, this.viewRole);
      if (
        typeof taskInfo !== "undefined" &&
        taskInfo !== null &&
        taskInfo !== ""
      ) {
        this.taskInfo = taskInfo;
      }
    },
    async setPrjView() {
      const getPjtData = await this.getPrjInfo(this.prjId);
      this.projectData = await getPjtData.project;
    },

    async hideProjectInfo() {
      if (this.visibleProjectInfo == true) {
        this.visibleProjectInfo = false;
        this.foldStatus = "펼치기";
      } else {
        this.visibleProjectInfo = true;
        this.foldStatus = "접기";
      }
    },

    hideUtils() {
      console.log(this.InferenceUtil);
      if (this.InferenceUtil == true) {
        this.InferenceUtil = false;
      } else if (this.InferenceUtil == false) {
        this.InferenceUtil = true;
      }
    },

    async getProjectData() {
      const urlParams = new URLSearchParams(location.search);

      const projectId = urlParams.get("prjId");

      try {
        const response = await this.getData(`project/${projectId}`);
        this.projectData = response.project;
      } catch (error) {
        console.log(error);
      }
    },

    async taskModify() {
      if (this.manageRole) {
        const taskId = this.$route.query.taskId;
        const prjId = this.$route.query.prjId;
        const taskTypeId = this.$route.query.taskTypeId;
        const query = {
          prjId: prjId,
          taskId: taskId,
          taskTypeId: taskTypeId,
        };
        // console.log(taskId);
        try {
          this.$router.push({
            name: "AddSmartFactoryTask",
            query,
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },

    async clipCoppy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },

    //---API-------
    async getDataWithParams(url, params) {
      try {
        const response = await common.apiGet(`/${url}`, {
          params: params,
        });
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData(url) {
      try {
        const response = await common.apiGet(`/${url}`);
        return response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMemberRole() {
      let response;
      try {
        let url = `/member/check-role`;
        response = await common.apiGet(url);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
    async getProjectRole() {
      let response;
      try {
        let checkProjectRoleUrl = `/member/check-project-role/${this.projectId}`;
        response = await common.apiGet(checkProjectRoleUrl);
        return response.data.data;
      } catch (error) {
        response = null;
        console.error("error", error);
        return response;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.search_wrap {
  display: flex;
  margin-top: 12px;
  .item_search {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    select {
      min-width: 170px;
      background-position: center right 15px;
    }
    button {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: relative !important;
    & select {
      position: relative;
      background-position: right 10px center;
    }
    &::after {
      display: none;
    }
    .search_box {
      margin-right: 10px;
      input[type="text"] {
        width: 210px;
        border: 1px solid #d5dae3;
        background-color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 15px;
        color: rgb(2, 10, 10);
      }
    }
    ul {
      display: flex;
      gap: 10px;
      margin-left: 10px;
      li {
        span {
          position: relative;
          top: 1px;
          margin-left: 5px;
          vertical-align: top;
        }
      }
    }
    .btn_send {
      margin-left: 15px;
    }
  }
}
.contents {
  .mando_table_wrap {
    padding: 20px;
    box-sizing: border-box;
  }
  .tbl_custom {
    width: 100%;
    overflow-x: auto;
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      & > div {
        flex: 1;
        text-align: center;
        &:first-child {
          max-width: 120px;
        }
        &:nth-child(2) {
          max-width: 120px;
        }
      }
      &.row_title {
        background-color: #405261;
        height: 94px;
        & > div {
          &:not(:last-child) {
            border-right: 1px solid #d5dae3;
          }
          height: 100%;
        }
        .txt {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: #fff;
          width: 100%;
          font-weight: 600;
          background-color: #405261;
        }
        .flex_box {
          display: flex;
          align-items: center;
          border-top: 1px solid #d5dae3;
          & > div {
            width: 50%;
            border-right: 1px solid #d5dae3;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
      &.row_data {
        border-left: 1px solid #d5dae3;
        border-right: 1px solid #d5dae3;
        min-height: 50px;
        border-bottom: 1px solid #d5dae3;
        box-sizing: border-box;
        & > div {
          flex: 1;
          &:not(:last-child) {
            border-right: 1px solid #d5dae3;
          }
          height: 100%;
        }
        .txt {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: #fff;
          font-weight: 600;
        }
        .flex_box {
          align-items: center;

          & > div {
            width: 100%;

            &:last-child {
              border-right: 0;
              border-bottom: 0;
            }
            & > div {
              &:last-child {
                border-bottom: 0;
              }
            }
          }
          &.col {
            max-width: none;
            & > div {
              flex: 1;
              border-right: 0;
            }
          }
          .txt_height {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-height: 40px;

            &:not(:last-child) {
              border-bottom: 1px solid #d5dae3;
            }
          }
        }
      }
    }
  }
  .mando_table {
    position: static;
    margin: 0;
    th,
    td {
      border: 1px solid #d5dae3;
      vertical-align: middle;
    }
    th {
      &::after {
        display: none;
      }
    }
    a {
      display: inline-block;
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
    &.tbl_top {
      th {
        background-color: #00b4ed;
        color: #fff;
        font-weight: 600;
      }
    }
    &.tbl_bottom {
      th {
        background-color: #405261;
        color: #fff;
        font-weight: 600;
      }
    }
    .btn_more {
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      margin-right: 5px;
      border-radius: 6px;
      &.btn_more_open {
        background-color: #02a744;
      }
      &.btn_more_close {
        background-color: #f15046;
      }
    }
  }
  .list_wrap {
    margin-top: -11px;
    .table_list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: none;
    }
  }
}
</style>
