<template>
  <div class="setting_pop_cell_contents_wrap">
    <div class="input-block" style="margin: 0">
      <div class="divide">
        <div class="first">Component ID</div>
        <div class="second">{{ items[0].value }}</div>
      </div>
    </div>
    <div class="divide">
      <div class="first" style="border-bottom: 1px solid #d5dae3">
        Display title
      </div>
      <div class="second">Label</div>
    </div>

    <div class="divide" style="margin-top: 20px">
      <LabelBar
        :otherProp="otherProp"
        :handlerList="handlerList"
        :readOnlyProp="false"
        :componentIndex="0"
        :labelHtml="labelHtml"
        @updateValue="updateItem(index, $event)"
        @remove-item="removeItem(index)"
        @updateButton="updateButtonValue"
        @updateParent="updateParent"
        :dataSet="dataSet"
        @changeTextHtml="updateLabel"
        :dataSetComponent="dataSetComponent"
        :filterDataList="filterDataList"
        @selectUpdateDataSet="selectUpdateDataSet"
        @selectDataMapCategory="selectDataMapCategory"
        @selectdataSettingTitle="selectDataSettingTitle2"
        @selectFilterSettingTitle="selectFilterSettingTitle2"
      ></LabelBar>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LabelBar from "../componentType/labe_bar.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "ButtonComponentSetting",
  components: {
    LabelBar,
  },
  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex: {
      type: Number,
      required: true,
    },
    handlerList: {
      //handler
      type: Object,
      required: true,
    },
    dataSet: {
      type: Object,
    },
    dataSetComponent: {
      type: Object,
    },
    filterDataList: {
      type: Object,
    },
  },
  data() {
    return {
      items: [
        { label: "Component ID", value: "" },
        { label: "Display Title", value: "" },
        { label: "Controller 추가", value: {} },
      ],
      showcolorpicker: false,
      showcolorpicker2: false,
      buttomType: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,

      buttonData: [
        {
          bgcolor: "00B4ED",
          hovercolor: "#00B4ED",
          btntext: "버튼",
        },
      ],
      nowindex: 0,
      componentType: "",
      components: [],
      layoutComponent: {},
      component: {},
      testData: [],
    };
  },
  mounted() {
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    const type = this.componentType;
    const handlerCount = String(this.handlerList.length + 1).padStart(3, "0");
    const component = {
      type: type,
      labelText: "",
      value: "",
      valueStart: "",
      valueEnd: "",
      labelData: "",
      key: this.components.length + 1 + "-" + Math.floor(Date.now()),
      handlerKey: "Handler" + handlerCount,
      remove: false,
      child: {},
      selectSectionYn: "",
      parentHandlerKey: "",
      controlHandlerLabelText: "",
      keyValueList: [],
      keyValueType: "",
      parameter: "",
      range: {
        startRange: 0,
        endRange: 0,
        step: 0,
      },
    };
    this.component = component;
    console.log(this.otherProp);
    this.component = Object.assign({}, component, this.otherProp);
    this.$emit("updateHandler", this.component);
    this.component = _.cloneDeep(this.component);
  },
  methods: {
    changeColor(color) {
      this.buttonData[this.nowindex].bgcolor = color.hex;
    },
    changeColor2(color) {
      this.buttonData[this.nowindex].hovercolor = color.hex;
    },
    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    handleSelectedValue(value) {
      // 선택된 option의 value 값을 부모로 emit
      this.componentType = value;
    },
    updateParent({ index, value }) {
      this.components[index].parentHandlerKey = value;
      this.components = _.cloneDeep(this.components);
    },
    updateItem(index, newValue) {
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    removeItem(index) {
      this.$emit("removeHandler", this.components[index]);
      this.components.splice(index, 1); // 해당 인덱스의 객체를 배열에서 제거
    },
    updateButtonValue({ index, value }) {
      const object = value;
      this.components[index] = Object.assign(
        {},
        this.components[index],
        object
      );
      console.log(this.components[index]);
    },
    updateLabel({ index, value }) {
      console.log(index);
      this.labelData = value;
      this.component.labelData = value;
      this.layoutComponent.labelData = value;
    },
    selectUpdateDataSet({ index, value, value2 }) {
      const a = index;
      const b = value2;
      a + b;
      this.layoutComponent.dataSetType = value;
      this.layoutComponent = _.cloneDeep(this.layoutComponent);
    },
    selectDataMapCategory({ index, value }) {
      index;
      this.layoutComponent.dataMapCategory = value;
      this.layoutComponent = _.cloneDeep(this.layoutComponent);
    },
    selectDataSettingTitle2({ index, value }) {
      index;
      this.layoutComponent.selectDataTitle2 = value;
      this.layoutComponent = _.cloneDeep(this.layoutComponent);
    },
    selectFilterSettingTitle2({ index, value }) {
      index;
      this.layoutComponent.filterType = value;
      this.layoutComponent = _.cloneDeep(this.layoutComponent);
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.port_wrap {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px);
  position: absolute;
  top: 80px;
  left: 20px;
  background: white;
  padding: 20px;
  border: 1px solid #d4d4d4;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 40px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
.port_wrap .component_pannel button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px);
  top: 80px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #00b4ed;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}

.control_pannel button {
  border: none;
  background: 00B4ED;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}
.top_control {
  width: calc(100% - 40px);
  height: 50px;
  top: 20px;
  left: 20px;
  background: white;
  border: 1px solid #d4d4d4;
  position: Absolute;
  padding: 10px;
  text-align: left;
}
.top_control select {
  width: auto;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding: 0px;
  padding-left: 14px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  padding-right: 40px;
  background-position: right 10px center;
  border-radius: 0px;
  margin-right: 10px;
}
.top_control button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
  border-radius: 4px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 10px;
}
.top_control button:hover {
  background: #00b4ed;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: Absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  color: black;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.setting_pop_cell .setting_pop_cell_x:hover {
  background: #00b4ed;
  color: white;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: normal;
  color: black;
  width: 100%;
  height: 30px;
  line-height: 25px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0px;
  margin-bottom: 20px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: 00B4ED;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00b4ed;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: 00B4ED;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
  border: 1px solid #d4d4d4 !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 4px;
  position: absolute;
  background: 00B4ED;
  color: white;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00b4ed;
}
.vue-grid-item span.setting_button:hover {
  background: #00b4ed;
}
.port_wrap .rightCell {
  overflow: auto;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}
.component_button {
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
  background: #00b4ed;
  border-radius: 4px;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: white;
}
.component_button:hover {
  background: #00b4ed;
}
</style>
<style>
.hu-color-picker {
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
}
.hu-color-picker.light {
  background: #f7f8f9;
}
.hu-color-picker.light .color-show .sucker {
  background: #eceef0;
}
.hu-color-picker.light .color-type .name {
  background: #e7e8e9;
}
.hu-color-picker.light .color-type .value {
  color: #666;
  background: #eceef0;
}
.hu-color-picker.light .colors.history {
  border-top: 1px solid #eee;
}
.hu-color-picker canvas {
  vertical-align: top;
}
.hu-color-picker .color-set {
  display: flex;
}
.hu-color-picker .color-show {
  margin-top: 8px;
  display: flex;
}
.hu-color-picker .saturation {
  position: relative;
  cursor: pointer;
}
.hu-color-picker .saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.hu-color-picker .color-alpha {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.hu-color-picker .color-alpha .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.hu-color-picker .sucker {
  width: 30px;
  fill: #9099a4;
  background: #2e333a;
  cursor: pointer;
  transition: all 0.3s;
}
.hu-color-picker .sucker.active,
.sucker:hover {
  fill: #1593ff;
}
.hue {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.hue .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.hu-color-picker .colors {
  padding: 0;
  margin: 0;
}
.hu-color-picker .colors.history {
  margin-top: 10px;
  border-top: 1px solid #2e333a;
}
.hu-color-picker .colors .item {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 10px 0 0 10px;
  border-radius: 3px;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
}
.hu-color-picker .colors .item:nth-child(8n + 1) {
  margin-left: 0;
}
.hu-color-picker .colors .item:hover {
  transform: scale(1.4);
}
.hu-color-picker .colors .item .alpha {
  height: 100%;
  border-radius: 4px;
}
.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.hu-color-picker .color-type {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
}
.hu-color-picker .color-type .name {
  width: 60px;
  height: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  background: #252930;
}
.hu-color-picker .color-type .value {
  flex: 1;
  height: 30px;
  min-width: 100px;
  padding: 0 12px;
  border: 0;
  color: #fff;
  background: #2e333a;
  box-sizing: border-box;
}
.hu-color-picker .color-set {
  display: flex;
}
.hu-color-picker canvas {
  vertical-align: top;
}
.hu-color-picker .saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.hu-color-picker .color-alpha {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.hu-color-picker .color-show {
  margin-top: 8px;
  display: flex;
}

.hu-color-picker .color-type {
  display: none;
}
.hu-color-picker .colors {
  display: none;
}
.plus {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.plus.clicked {
  background-color: #007bff; /* 클릭 시 버튼 색상 변경 */
  color: white; /* 클릭 시 글자 색상 변경 */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

.cover-block,
.input-block {
  flex: 1;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.label,
.input {
  text-align: center;
}

table {
  margin-bottom: 10px;
}

td {
  padding: 5px;
  text-align: center;
}

.btnStyle {
  display: flex;
}

.divide {
  /* position: relative; */
  display: flex;
  /* width: 100%; */
  /* height: 100%; */
}
.first {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.second {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
<style scoped lang="scss">
.filter_box {
  height: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
.labelText {
  width: 50%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}

.range {
  width: 25%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.divide {
  min-height: 50px;
  margin: 0;
  &:first-child {
    .second {
      border-top: 1px solid #d5dae3;
    }
  }
  &:last-child {
    .first {
      border: 0;
    }
  }
  .first {
    width: 40%;
    background-color: #00b4ed;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 600;
  }
  .second {
    border-right: 1px solid #d5dae3;
    border-bottom: 1px solid #d5dae3;
    .btn_plus {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border-radius: 4px;
    }
  }
}
select {
  background-position: center right 15px;
}
.filter_box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    &:first-child {
      .date_period_second {
        border-top: 1px solid #d5dae3;
      }
    }
    .date_period {
      width: 240px;
      display: flex;
      justify-content: flex-end; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      display: flex;
      height: 100%;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      background-color: #00b4ed;
      color: #fff;
      font-weight: 600;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-right: 1px solid #d5dae3;
      border-bottom: 1px solid #d5dae3;
      box-sizing: border-box;
      gap: 10px;
      input[type="text"] {
        padding: 0 10px;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        height: 40px !important;
        box-sizing: border-box;
      }
      select {
        background-position: center right 15px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px; /* label과 input 사이 간격 */
  }
  input[type="number"] {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d5dae3;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
.close {
  font-size: 20px;
  color: #bbb;
}
</style>
