<template>
  <div id="gnb">
    <div class="gnb_fold">
      <div class="profile_box">
        <div class="profile_img">
          <img v-bind:src="userData.profileImage" alt="user images" />
        </div>
      </div>
      <nav>
        <button class="lnb" type="button" @click="gnbOpen(null)">home</button>
        <ul>
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            @click="gnbOpen($event)"
            :class="item.id"
          >
            <button type="button">{{ index }} {{ item.text }}</button>
          </li>
        </ul>
        <div class="logout">
          <button type="button" @click="gnbOpen(null)">logout</button>
        </div>
      </nav>
    </div>
    <div class="gnb_open">
      <div class="profile_box">
        <div class="profile_img">
          <img v-bind:src="userData.profileImage" alt="user images" />
        </div>
        <div class="profile_txt">
          <strong class="profile_name">{{ userData.name }}</strong>
          <span class="profile_team">{{ userData.teamName }}</span>
          <span class="login_history"
            >최근 로그인 :
            <span class="login_date">{{ userData.lastLogin }}</span></span
          >
        </div>
      </div>
      <nav>
        <div class="quick_btn">
          <a href="/home">home</a>
          <a href="/projectOps/projectList">datalist</a>
          <a href="/showActivityLog">history</a>
        </div>
        <ul>
          <li v-for="(item, index) in opneList" :key="index" :class="item.id">
            <div
              class="one_depth"
              @click="accordionEvent(index, item, $event)"
              :ref="item.id"
            >
              <button type="button"></button>
              <span>{{ item.text }}</span>
            </div>
            <ul class="two_depth">
              <li v-for="(subItem, index) in item.subItem" :key="index">
                <button @click="redirectToLink(subItem)" :ref="subItem.id">
                  {{ subItem.name }}
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <div class="logout" @click="logoutAction()">
          <button type="button"></button>
          <span>Log out</span>
        </div>
      </nav>
      <button type="button" class="close_btn" @click="gnbClose()"></button>
    </div>
  </div>
  <div class="gnbdim"></div>
</template>

<script>
import gsap from "gsap";
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import common from "@/assets/js/common";
// import Cookies from 'js-cookie';
// import jwtDecode from 'jwt-decode';
export default {
  name: "GlobalNavBar",
  computed: {
    ...mapGetters(["getUserData"]),
  },
  setup() {
    const menuItems = [
      {
        text: "프로젝트 운영",
        id: "projectOps",
        subItem: [
          {
            id: "projectOps_home",
            name: "1. My 프로젝트(Home)",
            url: "/home",
          },
          {
            id: "projectOps_list",
            name: "2. 프로젝트 목록",
            url: "/projectOps/projectList",
          },
          {
            id: "projectOps_add",
            name: "3. 프로젝트 생성",
            url: "/projectOps/addProject",
          },
        ],
      },
      {
        text: "TASK / APP 운영",
        id: "monTask",
        subItem: [
          {
            id: "monTask_dataList",
            name: "디바이스 데이터 목록",
            url: "/monTask/dataList",
          },
          {
            id: "monTask_monTaskList",
            name: "전체 TASK 목록",
            url: "/monTASK/monTaskList",
          },
          {
            id: "monTask_monTaskList2",
            name: "Template Task 목록",
            url: "/monTASK/monTaskList2",
          },
          {
            id: "monTask_vehicleDashboard",
            name: "Vehicle Contents APP 목록",
            url: "/vehicleDashboard",
          },
          {
            id: "monTask_mlopsList",
            name: "MLOps APP 목록",
            url: "/monTASK/mlopsList",
          },
          {
            id: "monTASK_vehicleTaskList",
            name: "Vehicle TASK 목록",
            url: "/monTASK/vehicleTaskList",
          },
          {
            id: "monTASK_robotTaskList",
            name: "Robot TASK 목록",
            url: "/monTASK/robotTaskList",
          },
          {
            id: "monTASK_smartFactoryTaskList",
            name: "Smart Factory TASK 목록",
            url: "/monTASK/smartFactoryTaskList",
          },
        ],
      },

      {
        text: "시스템 운영",
        id: "sysOps",
        subItem: [
          // {
          //     name: 'Data Lake 관리',
          //     url: ''
          // },
          {
            id: "sysOps_portlet",
            name: "1. Template 관리",
            url: "/sysOps/portleTemplate",
          },
          {
            id: "sysOps_teamList",
            name: "2. Team 관리",
            url: "/sysOps/teamList",
          },
          {
            id: "sysOps_divisionList",
            name: "3. Division 관리",
            url: "/sysOps/divisionList",
          },
          {
            id: "sysOps_memberList",
            name: "4. 사용자 정보 관리",
            url: "/sysOps/memberList",
          },

          {
            id: "sysOps_codeManage",
            name: "5. 공통코드 관리",
            url: "/sysOps/codeManage",
          },
          {
            id: "sysOps_siteSetList",
            name: "6. (R) 작업 Site 관리",
            url: "/sysOps/siteSetList",
          },
          {
            id: "sysOps_topicSetList",
            name: "7. (R) Data Topic Set 관리",
            url: "/sysOps/topicSetList",
          },
          {
            id: "sysOps_actMsnSetList",
            name: "8. (R) Action/Mission 관리",
            url: "/sysOps/actMsnSetList",
          },
          {
            id: "sysOps_systemManagement",
            name: "9. System Management",
            url: "/sysOps/systemManagement",
          },
        ],
      },
      {
        text: "데이터 API 관리",
        id: "apiMng",
        subItem: [
          {
            id: "apiMng_apiDashboard",
            name: "1. API 모니터링 대시보드",
            url: "/apiMng/apiDashboard",
          },
          {
            id: "apiMng_apiManageList",
            name: "2. API 관리",
            url: "/apiMng/apiManageList",
          },
          {
            id: "apiMng_apiLogList",
            name: "3. API 로그",
            url: "/apiMng/apiLogList",
          },
        ],
      },

      {
        text: "장치/배포 관리",
        id: "deviceNDelivery",
        subItem: [
          {
            id: "deviceNDelivery_edgeDeviceList",
            name: "1. 디바이스 관리",
            url: "/deviceNDelivery/edgeDeviceList",
          },
          {
            id: "deviceNDelivery_fleetGroupList",
            name: "2. Fleet Group 관리",
            url: "/deviceNDelivery/fleetGroupList",
          },
          // {
          //     name: 'OTA 배포 관리',
          //     url: ''
          // }
        ],
      },
      {
        text: "커뮤니케이션",
        id: "communication",
        subItem: [
          {
            id: "communication_message",
            name: "1. 메시지 보관함",
            url: "/commu/message",
          },
          {
            id: "communication_notice",
            name: "2. 공지사항 관리",
            url: "/commu/notice",
          },
          {
            id: "communication_terms",
            name: "3. 약관 관리",
            url: "/commu/terms",
          },
          {
            id: "communication_cs",
            name: "4. CS 관리",
            url: "/commu/cs",
          },
        ],
      },
    ];

    return {
      menuItems,
    };
  },
  data() {
    const userData = {};
    return {
      userData,
      opneList: this.menuItems,
    };
  },
  mounted() {
    const _userCK = JSON.stringify(Cookies.get("user"));
    const _userData = JSON.parse(_userCK);

    this.userData = JSON.parse(_userData);
    if (this.userData.profileImage == null) {
      this.userData.profileImage =
        "https://d2rsr3zb8mvgil.cloudfront.net/images/member/member_sample.png";
    }
    this.setGnb();
  },
  methods: {
    ...mapActions(["login", "logout"]),
    async logoutAction() {
      console.log("logout");
      await this.logout();
      this.$router.push("/login");
    },
    async setGnb() {
      if (!(await common.getUserRole("type4"))) {
        this.removeGnb("sysOps_memberList");
      }
      if (!(await common.getUserRole("type2"))) {
        this.removeGnb("projectOps_add");
        this.removeGnb("sysOps");
        this.removeGnbFold("sysOps");
      }
      if (!(await common.getUserRole("type3"))) {
        this.removeGnb("sysOps_divisionList");
      }
    },
    removeGnb(id) {
      const targetList = this.$refs[id];
      targetList.forEach((item) => {
        const target = item;
        const parent = target.parentNode;

        parent.remove(target);
      });
    },
    removeGnbFold(id) {
      let targetList = document.querySelectorAll(".gnb_fold");
      let liList = Array.from(targetList[0].querySelectorAll("nav > ul > li"));

      let sysOpsIdx = liList.findIndex((item) => item.className === id);

      if (sysOpsIdx !== -1) {
        let updatedLiList = liList.filter((item, index) => index !== sysOpsIdx);

        targetList[0].querySelector("nav > ul").innerHTML = "";
        updatedLiList.forEach((item) =>
          targetList[0].querySelector("nav > ul").appendChild(item)
        );
      }
    },
    gnbOpen(target) {
      let $gnbFold = document.querySelector(".gnb_fold");
      let $gnbOpen = document.querySelector(".gnb_open");
      let $closeBtn = document.querySelector(".close_btn");
      let $gnbOpenList = $gnbOpen.querySelectorAll("nav > ul > li");
      if (target !== null) {
        const clickedElement = target.target;
        let parentElement;
        let index;
        if (clickedElement.tagName.toLowerCase() === "li") {
          parentElement = clickedElement.parentNode;
          index = Array.from(parentElement.children).indexOf(clickedElement);
        } else {
          parentElement = clickedElement.parentNode.parentNode;
          index = Array.from(parentElement.children).indexOf(
            clickedElement.parentNode
          );
        }
        if (index !== null) {
          $gnbOpenList[index]
            .querySelector(".one_depth")
            .parentNode.classList.add("active");
          $gnbOpenList[index].querySelector(
            ".one_depth"
          ).parentNode.style.height = "auto";
          $gnbOpenList[index]
            .querySelector(".one_depth")
            .classList.add("active");
          $gnbOpenList[index]
            .querySelector(".one_depth")
            .nextSibling.classList.add("active");
        }
      }
      gsap.to("#gnb", {
        onStart: () => {
          $gnbFold.style.display = "none";
          $closeBtn.style.display = "block";
          $gnbOpen.style.display = "block";
        },
        duration: 0.3,
        width: 300,
        onComplete: () => {
          $gnbOpen.style.display = "block";
        },
      });
    },
    gnbClose() {
      let $gnbFold = document.querySelector(".gnb_fold");
      let $gnbOpen = document.querySelector(".gnb_open");
      let $closeBtn = document.querySelector(".close_btn");
      let $gnbList = $gnbOpen.querySelectorAll("nav > ul > li > .one_depth");

      $gnbList.forEach((element) => {
        element.classList.remove("active");
        element.parentNode.classList.remove("active");
        element.parentNode.style.height = "60px";
      });
      gsap.to("#gnb", {
        onStart: () => {
          $gnbFold.style.display = "block";
          $closeBtn.style.display = "none";
          $gnbOpen.style.display = "none";
        },
        duration: 0.3,
        width: 80,
      });
    },

    accordionEvent(index, item, event) {
      const id = item.id;
      const $gnbOpen = document.querySelector(".gnb_open");
      const $gnbList = $gnbOpen.querySelectorAll("nav > ul > li");
      //const $gnbList = $gnbOpen.querySelectorAll("nav > ul > li > .one_depth");

      let _this = event.currentTarget;

      console.log(id);
      console.log(_this);

      let hasClass = _this.classList.contains("active");
      if (!hasClass) {
        $gnbList.forEach((element) => {
          element.classList.remove("active");
          element.childNodes.forEach((elementChild) => {
            elementChild.classList.remove("active");
          });
        });

        gsap.to(".gnb_open > nav > ul > li", {
          onStart: () => {
            _this.parentNode.classList.add("active");
          },
          duration: 0.3,
          height: 60,
        });
        gsap.to(_this.parentNode, {
          duration: 0.3,
          height: "auto",
        });
        _this.classList.add("active");
        _this.nextSibling.classList.add("active");
      } else if (hasClass) {
        gsap.to(_this.parentNode, {
          onStart: () => {
            _this.parentNode.classList.remove("active");
            _this.classList.remove("active");
            _this.nextSibling.classList.remove("active");
          },
          duration: 0.3,
          height: "70",
        });
      }
    },
    async redirectToLink(item) {
      const url = item.url;
      const id = item.id;
      if (id == "projectOps_add" || id == "sysOps_teamList") {
        const checkRole = await common.getUserRole("type2");
        if (!checkRole) {
          return;
        }
      } else if (id == "sysOps_divisionList") {
        const checkRole = await common.getUserRole("type3");
        if (!checkRole) {
          return;
        }
      } else if (id == "sysOps_memberList") {
        const checkRole = await common.getUserRole("type4");
        if (!checkRole) {
          return;
        }
      }
      window.location.href = url;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#gnb {
  height: 100vh;
  background-color: $gnbColor;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
  //
  .gnb_fold {
    display: block;
    width: 80px;
    height: 100%;
    .profile_box {
      width: 100%;
      height: 79px;
      background-color: $mainColor;
      .profile_img {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 25px;
        box-sizing: border-box;
        border: 2px solid $whiteColor;
        position: relative;
        top: 15px;
        left: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    nav {
      button {
        width: 80px;
        height: 60px;
        background-color: $gnbColor;
        padding: 0px;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: -9999px;
      }
      .lnb {
        background-image: url($baseURL + "common/left_home.svg");
        &:hover {
          background-color: #0099d3;
        }
      }
      .logout {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        padding-left: 20px;
        cursor: pointer;
        button {
          width: 42px;
          height: 42px;
          border-radius: 21px;
          background-image: url($baseURL + "common/leftm_logout.svg");
        }
        &:hover {
          button {
            background-color: #60cff2;
          }
        }
      }
      ul {
        li {
          width: 100%;
          height: 70px;
          display: flex;
          justify-content: center;
          justify-items: center;
          align-items: center;
          cursor: pointer;
          button {
            width: 42px;
            height: 42px;
            border-radius: 21px;
          }
          &.projectOps {
            button {
              background-image: url($baseURL + "common/leftm_project.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
          &.monTask {
            button {
              background-image: url($baseURL + "common/leftm_task.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
          &.sysOps {
            button {
              background-image: url($baseURL + "common/leftm_system.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
          &.deviceNDelivery {
            button {
              background-image: url($baseURL + "common/leftm_data.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
          &.communication {
            button {
              background-image: url($baseURL + "common/leftm_distribution.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
          &:nth-child(6) {
            button {
              background-image: url($baseURL + "common/leftm_communi.svg");
            }
            &:hover {
              button {
                background-color: #60cff2;
              }
            }
          }
        }
      }
    }
  }
  //
  .gnb_open {
    display: none;
    width: 300px;
    height: 100%;
    .profile_box {
      width: 100%;
      height: 79px;
      background-color: $mainColor;
      position: relative;
      .profile_img {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 25px;
        box-sizing: border-box;
        border: 2px solid $whiteColor;
        position: absolute;
        top: 15px;
        left: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .profile_txt {
        width: 275px;
        padding-top: 10px;
        padding-left: 80px;
        box-sizing: border-box;
        strong {
          width: 100%;
          overflow: hidden;
          display: block;
          font-size: 17px;
          font-weight: $bold;
          color: $whiteColor;
          line-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span {
          display: block;
          color: $whiteColor;
          line-height: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            display: inline;
          }
        }
        .login_history {
          font-size: 13px;
        }
      }
    }
    nav {
      div > button {
        width: 100%;
        height: 60px;
        background-color: $gnbColor;
        padding: 0px;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
      }
      .quick_btn {
        height: 60px;
        a {
          float: left;
          width: 100px;
          height: 60px;
          overflow: hidden;
          text-indent: -9999px;
          background-position: center;
          background-repeat: no-repeat;
          box-sizing: border-box;
          &:nth-child(1) {
            background-image: url($baseURL + "common/left_home.svg");
            border-right: 1px solid $mainColor;
          }
          &:nth-child(2) {
            background-image: url($baseURL + "common/left_datalist.svg");
            border-left: 1px solid $mainColor;
            border-right: 1px solid $mainColor;
          }
          &:nth-child(3) {
            background-image: url($baseURL + "common/left_history.svg");
            border-left: 1px solid $mainColor;
          }
          &:hover {
            background-color: #0099d3;
          }
        }
        ::after {
          display: block;
          content: "";
          clear: both;
        }
      }
      .logout {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        box-sizing: border-box;
        cursor: pointer;
        button {
          width: 42px;
          height: 42px;
          border-radius: 21px;
          background-image: url($baseURL + "common/leftm_logout.svg");
        }
        &:hover {
          button {
            background-color: #60cff2;
          }
        }
        span {
          color: $whiteColor;
          display: inline-block;
          padding-left: 6px;
          white-space: nowrap;
        }
      }
      & > ul {
        & > li {
          height: 60px;
          overflow: hidden;
        }
        li {
          width: 100%;
          cursor: pointer;
          div > button {
            width: 42px;
            height: 42px;
            border-radius: 21px;
            position: absolute;
          }
          .one_depth {
            width: 100%;
            height: 70px;
            line-height: 70px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 20px;
            position: relative;
            & > span {
              display: inline-block;
              padding-left: 50px;
              font-size: $fontMenuTitle;
              color: $whiteColor;
              font-weight: $bold;
              &::after {
                display: block;
                content: "";
                width: 12px;
                height: 7px;
                position: absolute;
                top: 25px;
                right: 20px;
                background-image: url($baseURL + "common/leftm_arrowdown.svg");
                background-repeat: no-repeat;
              }
            }
            &.active {
              background-color: $whiteColor;
              span {
                color: $mainColor;
                &::after {
                  background-image: url($baseURL + "common/leftm_arrowup.svg");
                  background-repeat: no-repeat;
                }
              }
            }
          }
          .two_depth {
            background-color: #ccf0fb;
            padding-bottom: 10px;
            li {
              width: 100%;
              height: 50px;
              & > button {
                width: 100%;
                height: 100%;
                text-align: left;
                font-size: $fontMenuTitle2;
                background-color: #ccf0fb;
                margin-left: 60px;
              }
              &.active {
                & > button {
                  position: relative;
                  font-weight: $bold;
                  color: $mainColor;
                  // &::after{
                  //     display: block;
                  //     content: "";
                  //     width: 5px;
                  //     height: 9px;
                  //     position: absolute;
                  //     top: 20px;
                  //     right: 24px;
                  //     background-image: url($baseURL+"common/leftm_arrow2.svg");
                  //     background-repeat: no-repeat;
                  // }
                }
              }
            }
          }
          &:hover {
            .one_depth > button {
              background-color: #60cff2;
            }
            .one_depth.active > button {
              background-color: $whiteColor;
            }
          }
          &.projectOps {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_project.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_project_press.svg");
            }
          }
          &.monTask {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_task.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_task_press.svg");
            }
          }
          &.sysOps {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_system.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_system_press.svg");
            }
          }
          &.apiMng {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_task.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_task_press.svg");
            }
          }
          &.deviceNDelivery {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_data.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_data_press.svg");
            }
          }
          &.communication {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_distribution.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_distribution_press.svg");
            }
          }
          &:nth-child(6) {
            .one_depth > button {
              background-image: url($baseURL + "common/leftm_communi.svg");
            }
            .one_depth.active > button {
              background-color: $whiteColor;
              background-image: url($baseURL + "common/leftm_communi_press.svg");
            }
          }
        }
      }
    }
    .close_btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background-color: transparent;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 18px;
        left: 7px;
        width: 50%;
        height: 2px;
        background-color: $whiteColor;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  #gnb {
    width: 300px !important;
    .gnb_fold {
      display: none !important;
    }
    .gnb_open {
      display: block !important;
      .close_btn {
        display: none !important;
      }
    }
  }
}
</style>
