<template>
  <div class="setting_pop_cell_contents_wrap">
    <div class="input-block">
      <div class="divide">
        <div class="first">Component ID</div>
        <div class="second">{{ items[0].value }}</div>
      </div>
      <!-- <div class="divide">
        <div class="first">선택구간</div>
      </div> -->
      <div class="divide" v-if="dataSetData && filterDataList.length > 0">
        <div class="first">Filter type</div>
        <div class="second">
          <select v-model="filterType" @change="filterSettingTitle2($event)">
            <option
              v-for="item in filterDataList"
              :key="item.index"
              :value="item.index"
            >
              Filter {{ item.index }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="remote_setting">
    <div class="row">
      <ul>
        <li>
          <div class="btn_set btn_03"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[0].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[0].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_05"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[1].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[1].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_06"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[2].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[2].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_02"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[3].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[3].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_07"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[4].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[4].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_04"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[5].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[5].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_09"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[6].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[6].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
        <li>
          <div class="btn_set btn_10"></div>
          <div class="txt">Real Key</div>
          <input
            v-model="remoteKey[7].key"
            type="text"
            maxlength="1"
            @input="inputTxt"
          />
          <select v-model="remoteKey[7].useYn">
            <option value="" disabled>사용여부</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </li>
      </ul>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "RemoteControllerSetting",
  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    nowIndex: {
      type: Number,
      required: true,
    },
    handlerList: {
      //handler
      type: Object,
      required: true,
    },
    filterDataList: {
      type: Object,
    },
  },
  data() {
    return {
      items: [
        { label: "Component ID", value: "" },
        { label: "Display Title", value: "" },
        { label: "Controller 추가", value: {} },
      ],
      nowindex: 0,
      componentType: "",
      components: [],
      layoutComponent: {},
      dataSetData: undefined,
      remoteKey: [
        {
          kind: "keyUp",
          key: "w",
          useYn: "Y",
        },
        {
          kind: "keyLeft",
          key: "a",
          useYn: "Y",
        },
        {
          kind: "keyRight",
          key: "d",
          useYn: "Y",
        },
        {
          kind: "keyDown",
          key: "x",
          useYn: "Y",
        },
        {
          kind: "keyUpLeft",
          key: "q",
          useYn: "Y",
        },
        {
          kind: "keyUpRight",
          key: "e",
          useYn: "Y",
        },
        {
          kind: "keyDownLeft",
          key: "z",
          useYn: "Y",
        },
        {
          kind: "keyDownRight",
          key: "c",
          useYn: "Y",
        },
      ],
    };
  },
  async mounted() {
    const vm = this;
    const prop = this.otherProp;
    console.log("$$$$$$$$$$ :: ", prop);
    if (this.otherProp.remoteKey != undefined) {
      this.remoteKey = [...this.otherProp.remoteKey];
    }
    this.$refs.lodingWrap.style.display = "block";

    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);

    if (this.components[this.nowindex]?.displayTitle != undefined) {
      this.displayTitle = this.components[this.nowindex]?.displayTitle;
    } else {
      this.displayTitle = "";
    }
    const components_cp = this.components;
    for (let i = 0; i < components_cp.length; i++) {
      let valueStart = components_cp[i].valueStart;
      let valueEnd = components_cp[i].valueEnd;
      if (valueStart !== null) {
        if (typeof valueStart === "string") {
          valueStart = new Date(valueStart);
        }
      }
      if (valueEnd !== null) {
        if (typeof valueEnd === "string") {
          valueEnd = new Date(valueEnd);
        }
      }
    }

    this.components = _.cloneDeep(components_cp);
    this.items[0].value = this.layoutComponent.key;
    this.items[1].value = this.layoutComponent.type;
    const type = this.componentType;
    const handlerCount = String(this.handlerList.length + 1).padStart(3, "0");
    const component = {
      type: type,
      labelText: "",
      value: "",
      valueStart: "",
      valueEnd: "",
      key: this.components.length + 1 + "-" + Math.floor(Date.now()),
      handlerKey: "Handler" + handlerCount,
      remove: false,
      child: {},
      parentHandlerKey: "",
      controlHandlerLabelText: "",
      keyValueList: [],
      keyValueType: "",
      parameter: "",
      range: {
        startRange: 0,
        endRange: 0,
        step: 0,
      },
    };
    this.components.push(component);
    this.component = component;
    this.$emit("updateHandler", component);
    this.component = _.cloneDeep(this.component);
    this.$refs.lodingWrap.style.display = "none";

    this.dataSetData = this.dataSet;
    if (prop.filterType !== "") {
      vm.filterType = prop.filterType;
    }
  },
  methods: {
    inputTxt() {
      const param = [
        {
          kind: "keyUp",
          key: this.remoteKey[0].key,
          useYn: this.remoteKey[0].useYn,
        },
        {
          kind: "keyLeft",
          key: this.remoteKey[1].key,
          useYn: this.remoteKey[1].useYn,
        },
        {
          kind: "keyRight",
          key: this.remoteKey[2].key,
          useYn: this.remoteKey[2].useYn,
        },
        {
          kind: "keyDown",
          key: this.remoteKey[3].key,
          useYn: this.remoteKey[3].useYn,
        },
        {
          kind: "keyUpLeft",
          key: this.remoteKey[4].key,
          useYn: this.remoteKey[4].useYn,
        },
        {
          kind: "keyUpRight",
          key: this.remoteKey[5].key,
          useYn: this.remoteKey[5].useYn,
        },
        {
          kind: "keyDownLeft",
          key: this.remoteKey[6].key,
          useYn: this.remoteKey[6].useYn,
        },
        {
          kind: "keyDownRight",
          key: this.remoteKey[7].key,
          useYn: this.remoteKey[7].useYn,
        },
      ];
      this.components[0].remoteKey = param;
      this.components = _.cloneDeep(this.components);
    },
    updateParent({ index, value }) {
      this.components[index].parentHandlerKey = value;
      this.components = _.cloneDeep(this.components);
    },
    updateItem(index, newValue) {
      this.components.splice(index, 1, newValue); // 배열의 값 변경
    },
    removeItem(index) {
      this.$emit("removeHandler", this.components[index]);
      this.components.splice(index, 1); // 해당 인덱스의 객체를 배열에서 제거
    },
    filterSettingTitle2() {
      // const value = event.target.value;
      this.layoutComponent.filterType = this.filterType;
      this.layoutComponent = _.cloneDeep(this.layoutComponent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.divide {
  min-height: 50px;
  margin: 0;
  &:first-child {
    .second {
      border-top: 1px solid #d5dae3;
    }
  }
  &:last-child {
    .first {
      border: 0;
    }
  }
  .first {
    width: 40%;
    background-color: #00b4ed;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 600;
  }
  .second {
    border-right: 1px solid #d5dae3;
    border-bottom: 1px solid #d5dae3;
  }
}
.remote_setting {
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      input {
        flex: 1;
        border: 1px solid #d5dae3;
        max-width: 300px;
        height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 10px;
      }
      .btn_set {
        width: 50px;
        height: 50px;
        background-color: #d9d9d9;
        border-radius: 10px;
      }
    }
  }
}
select {
  background-position: center right 15px;
}
.btn_01 {
  background-image: url($baseURL + "/arrow/redo_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}
.btn_02 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}
.btn_03 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}
.btn_04 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(1);
}
.btn_05 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
.btn_06 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}
.btn_07 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
.btn_08 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}
.btn_09 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1) scaleY(-1);
}
.btn_10 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(1) scaleY(-1);
}
.btn_11 {
  background-image: url($baseURL + "/arrow/forward_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(270deg);
}
.btn_12 {
  background-image: url($baseURL + "/arrow/forward_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}
</style>
