<template>
    <div class="table_wrap">
        <div v-html="filter"></div>
        <div class="ql-container ql-snow">
          <div class="ql-editor"  v-html="labelHtml">
          </div>
      </div>
     </div>
</template>

<script>
export default {
    components: {

     },
     props: {
      otherProp: {
        type: Object,
      },
      readOnlyProp:{
        type: Boolean,
      },
      handlerList:{
        type:Object,
        require:true
      },
      componentIndex:{
        type:Number
      },
      filterData:{
        type:Object
      }
      
    },
    data() {
        return {
            labelText:'',
            handlerKey :'',
            color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
            picStartDate: null,
            picEndDate: null,
            start_cal_key:0,
            end_cal_key:0,
            picStartDateCalendar: null,
            picEndDateCalendar: null,
            picColor: {
                startStatus: false,
                endStatus: false,
                active: "#00B4ED",
                default: "#020A0A",
            },
            onlyRead : false,
            remove : false,
            DataMapCateGory:'Data',
            parentHandlerKey : '',
            parameter:'',
            labelHtml:'',
            filter:''
        };
    },
    mounted(){
      const vm = this;
      const prop = this.otherProp;
      vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
      vm.handlerKey = prop.handlerKey; //handlerKey
      if(vm.parentHandlerKey!==undefined){
        vm.parentHandlerKey = prop.parentHandlerKey; // 부모키 
      }
      vm.labelText = prop.labelText;
      console.log('label')
      console.log(prop);
      console.log(this)
      if(prop.labelHtml!==undefined){
        vm.labelHtml = prop.labelHtml;
      }

      if(prop.filterData!==undefined){
        let result = "";
        const filterComponent = prop.filterData.components;
        for(let i=0;i<filterComponent.length;i++){
          const filter = filterComponent[i];
          result += 'type : '+filter.type+'<br>'; 
          if(filter.type==='calendar'){
            if(filter.calenderType ==='period'){
              result += 'date : '+filter.startDate+'~'+filter.endDate+'<br>';
            }else{
              result += 'date :'+filter.singleDate+'<br>';
            } 
          }
        }
        this.filter = result;
      }


  
    },
    methods:{

    },
    watch:{
    }
}
</script>

<style scoped lang="scss">
        .table_wrap{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
                justify-content: center;
    align-items: center;
    text-align: center;
            .title_box{
                height: 45px;
                position: relative;    
                margin-bottom: 20px;
                .search_from{
                    position: absolute !important;
                    padding-right: 40px !important;
                    .btn_search{
                        width: 40px;
                    }
                }
            }
        }
</style>


