<template>
  <div v-if="readOnlyProp === false" style="float: right">
    <input type="hidden" v-model="remove" />
    <button type="button" class="close" @click="removeItem">
      <font-awesome-icon :icon="['fas', 'xmark']" />
    </button>
  </div>
  <div class="filter_box">
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="date_period_first">Handler ID</div>
      <div class="date_period_second" ref="handlerKey">{{ handlerKey }}</div>
    </div>
    <div v-if="readOnlyProp === false" class="date_filter">
      <div class="date_period_first">Button Type</div>
      <div class="date_period_second">Toggle</div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">제어 Handler</div>
      <div class="date_period_second">
        <select v-model="parentHandlerKey" @change="updateParent($event)">
          <option value="" selected>None</option>
          <option
            v-for="item in handlerList.filter(
              (ele) => ele.handlerKey !== otherProp.handlerKey
            )"
            :key="item.handlerKey"
            :value="item.handlerKey"
          >
            handlerKey : {{ item.handlerKey }}
          </option>
        </select>
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Display Title</div>
      <div class="date_period_second">
        <input type="text" v-model="labelText" />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Contents Text</div>
      <div class="date_period_second">
        <input type="text" v-model="contentText" />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Bg Color</div>
      <div class="date_period_second">
        <input
          type="text"
          v-model="bgColor"
          readonly
          style="width: 88px; margin: 0"
        />
        <input type="color" v-model="bgColor" />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">On Color</div>
      <div class="date_period_second">
        <input
          type="text"
          v-model="OnColor"
          readonly
          style="width: 88px; margin: 0"
        />
        <input type="color" v-model="OnColor" />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Off Color</div>
      <div class="date_period_second">
        <input
          type="text"
          v-model="OffColor"
          readonly
          style="width: 88px; margin: 0"
        />
        <input type="color" v-model="OffColor" />
      </div>
    </div>
    <div class="date_filter">
      <div class="date_period_first">Action Type</div>
      <div class="date_period_second">
        <select
          v-model="actionType"
          @change="(e) => (calenderType = e.target.value)"
        >
          <option value="" disabled>Select</option>
          <option value="script">Script</option>
          <option value="rest">REST API</option>
          <option value="trigger">Component Trigger</option>
        </select>
      </div>
    </div>
    <div class="date_filter" v-if="actionType === 'script'">
      <div class="date_period_first">Script Code</div>
      <div class="date_period_second">
        <input type="text" v-model="scriptCode" />
      </div>
    </div>
    <div class="date_filter" v-if="actionType === 'rest'">
      <div class="date_period_first">API</div>
      <div class="date_period_second">
        <input type="text" v-model="api" />
      </div>
    </div>
    <div class="date_filter" v-if="actionType === 'rest'">
      <div class="date_period_first">Parameter</div>
      <div class="date_period_second">
        <input type="text" v-model="parameter" />
      </div>
    </div>
  </div>
  <!-- ColorPicker를 조건부로 렌더링 -->
</template>

<script>
export default {
  components: {},
  props: {
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
    keyValueList: {
      type: Object,
    },
    keyValueType: {
      type: String,
    },
  },
  data() {
    return {
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      DataMapCateGory: "Data",
      parentHandlerKey: "",
      barKeyValueType: "",
      inputKey: "",
      inputValue: "",
      barKeyValueList: [],
      actionType: "",
      bgColor: "#ff0000",
      bgColorPicker: false,
      OnColor: "#ff0000",
      OnColorPicker: false,
      OffColor: "#ff0000",
      OffColorPicker: false,
      parameter: "",
      api: "",
      scriptCode: "",
      contentText: "",
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    if (prop.actionType != null) {
      vm.actionType = prop.actionType;
    }
    if (prop.bgColor != null) {
      vm.bgColor = prop.bgColor;
    }
    if (prop.OnColor != null) {
      vm.OnColor = prop.OnColor;
    }
    if (prop.OffColor != null) {
      vm.OffColor = prop.OffColor;
    }
    if (prop.parameter != null) {
      vm.parameter = prop.parameter;
    }
    if (prop.api != null) {
      vm.api = prop.api;
    }
    if (prop.scriptCode != null) {
      vm.scriptCode = prop.scriptCode;
    }
    if (prop.labelText != "") {
      vm.labelText = prop.labelText;
    }
    if (prop.contentText) {
      vm.contentText = prop.contentText;
    }
    vm.labelText = prop.labelText;
  },
  methods: {
    updateParent(event) {
      this.$emit("updateParent", {
        index: this.componentIndex,
        value: event.target.value,
      });
      console.log(this.handlerList);
    },
    labelTextChange(event) {
      this.labelText = event.target.value;
      const item = this.otherProp;
      item.labelText = this.labelText;
    },
    removeItem() {
      this.$emit("removeItem");
    },
  },
  watch: {
    labelText() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    contentText() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    actionType() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    bgColor() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    OnColor() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    OffColor() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    parameter() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    api() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
    scriptCode() {
      const object = {
        actionType: this.actionType,
        bgColor: this.bgColor,
        OnColor: this.OnColor,
        OffColor: this.OffColor,
        parameter: this.parameter,
        api: this.api,
        scriptCode: this.scriptCode,
        contentText: this.contentText,
        labelText: this.labelText,
      };
      this.$emit("updateButton", { index: this.componentIndex, value: object });
    },
  },
};
</script>

<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height: 100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      input[type="text"] {
        margin: 0 20px;
        border-right: 1px solid #d5dae3 !important;
        border-radius: 10px !important;
      }
    }
  }
}
.labelText {
  width: 50%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}

.range {
  width: 25%;
  height: 100%;
  line-height: 45px;
  box-sizing: border-box;
  border: 1px solid #d5dae3;
  border-radius: 10px;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 15px;
}
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.divide {
  min-height: 50px;
  margin: 0;
  &:first-child {
    .second {
      border-top: 1px solid #d5dae3;
    }
  }
  &:last-child {
    .first {
      border: 0;
    }
  }
  .first {
    width: 40%;
    background-color: #00b4ed;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-weight: 600;
  }
  .second {
    border-right: 1px solid #d5dae3;
    border-bottom: 1px solid #d5dae3;
    .btn_plus {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border-radius: 4px;
    }
  }
}
select {
  background-position: center right 15px;
}
.filter_box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    &:first-child {
      .date_period_second {
        border-top: 1px solid #d5dae3;
      }
    }
    .date_period {
      width: 240px;
      display: flex;
      justify-content: flex-end; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first {
      width: 335px;
      display: flex;
      height: 100%;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      background-color: #00b4ed;
      color: #fff;
      font-weight: 600;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-right: 1px solid #d5dae3;
      border-bottom: 1px solid #d5dae3;
      box-sizing: border-box;
      gap: 10px;
      input[type="text"] {
        padding: 0 10px;
        border: 1px solid #d5dae3;
        border-radius: 10px;
        height: 40px !important;
        box-sizing: border-box;
      }
      select {
        background-position: center right 15px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px; /* label과 input 사이 간격 */
  }
  input[type="number"] {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d5dae3;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
.close {
  font-size: 20px;
  color: #bbb;
}
</style>
