<template>
  <HeaderLayout />
  <GlobalNavBar />
  <div id="container">
    <div id="contents">
      <BreadcrumbLayout
        pageId="apiMng_apiManageDetail"
        :checkRole="manageRole"
      />
      <div class="item_info">
        <div class="item_title">
          <strong>{{ apiData.name }}</strong>
          <div class="item_info_btn_box">
            <button
              type="button"
              class="btn_fold"
              v-bind:style="prjInfoToggle ? 'display:none' : ''"
              @click="clickTogglePrj()"
            >
              접기</button
            ><button
              type="button"
              class="btn_fold active"
              v-bind:style="!prjInfoToggle ? 'display:none' : ''"
              @click="clickTogglePrj()"
            >
              펼치기</button
            ><button
              type="button"
              class="btn_evt_group"
              @click="evtBox($event)"
            ></button>
            <ul class="evt_btn_box radiusbox">
              <li class="share_item">
                <button type="button" @click="clipCopy">공유하기</button>
              </li>
              <li class="retouch_item">
                <button type="button" @click="goModPage">API 수정</button>
              </li>
              <li class="delete_item">
                <button type="button" @click="showModalAlert">API 삭제</button>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="item_table radiusbox"
          v-bind:style="prjInfoToggle ? 'display:none' : ''"
        >
          <table>
            <thead>
              <tr>
                <th>API 주소</th>
                <th>API 유형</th>
                <th>프로젝트명</th>
                <th>API Logger 주소</th>
                <th>등록일자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ apiData.url }}</td>
                <td>
                  {{
                    apiData.type === "001"
                      ? "REST"
                      : apiData.type === "002"
                      ? "HTTP"
                      : "Websocket"
                  }}
                </td>
                <td>{{ apiData.projectNm }}</td>
                <td>{{ apiData.loggerUrl }}</td>
                <td>{{ apiData.created }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="list_wrap">
          <div class="item_table radiusbox">
            <table>
              <thead>
                <tr>
                  <th colspan="2">Method</th>
                  <th class="before_left_border" rowspan="2">
                    {{ apiData.type != "003" ? "Parameter" : "Custom 경로" }}
                  </th>
                  <th
                    class="before_left_border"
                    rowspan="2"
                    style="min-width: 100px"
                  >
                    과금유무
                  </th>
                </tr>
                <tr>
                  <th>함수명</th>
                  <th style="min-width: 100px">
                    {{ apiData.type != "003" ? "API Path" : "함수정의" }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in apiData.type != '003'
                    ? apiData.rest
                    : apiData.webSocket"
                  :key="index"
                >
                  <td>{{ item.funcName }}</td>
                  <template v-if="apiData.type != '003'">
                    <td>{{ item.method }}</td>
                  </template>
                  <template v-else>
                    <td>
                      <template v-if="item.protocol == 1">$connect</template>
                      <template v-if="item.protocol == 2">$disconnect</template>
                      <template v-if="item.protocol == 3">$default</template>
                      <template v-if="item.protocol == 4">$custom</template>
                    </td>
                  </template>
                  <td>
                    {{
                      apiData.type != "003"
                        ? JSON.stringify(item.parameter)
                            .replaceAll('\\"', "")
                            .replaceAll("[", "")
                            .replaceAll("]", "")
                            .replaceAll('"', "")
                        : item.msg
                    }}
                  </td>
                  <td>{{ item.billingYn != 0 ? "Y" : "N" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 삭제 Modal -->
    <div id="modalWrap" ref="modalWrap" style="display: none">
      <div
        class="radiusbox modal_contents message_alert message_modal"
        ref="modalAlert"
      >
        <div class="message_box">해당 API를 삭제 하시겠습니까?</div>
        <button type="button" class="btn_check mcbtn" @click="removeApi()">
          확인
        </button>
        <button type="button" class="btn_close" @click="closeModal()"></button>
      </div>
    </div>
  </div>
  <FooterLayout />
</template>
<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import "@/assets/js/filter";
import common from "@/assets/js/common";
//import sampleJsonData from "@/assets/js/sample_json";

export default {
  name: "apiManageDetail",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
  },
  data() {
    return {
      apiData: {},
      apiId: 0,
      prjInfoToggle: false,
    };
  },
  created() {},
  mounted() {
    this.setRole();
    this.apiId = this.$route.params.apiId;
    this.setApiDetail(this.apiId);
  },

  methods: {
    async setRole() {
      // this.manageRole = await common.getUserRole('type3');
      this.manageRole = true;
      // this.viewRole = await common.getUserRole('type2');
      this.viewRole = true;
    },
    clickTogglePrj() {
      const toggle = !this.prjInfoToggle;
      this.prjInfoToggle = toggle;
    },
    evtBox(e) {
      const btn = e.target;
      if (btn.classList.contains("active")) {
        btn.classList.remove("active");
        btn.nextElementSibling.style.display = "none";
      } else {
        btn.classList.add("active");
        btn.nextElementSibling.style.display = "block";
      }
    },
    //공유하기 버튼 클릭시 url 복사
    async clipCopy() {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);

      try {
        const url = window.document.location.href;
        dummy.value = url;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        alert("url copy success");
      } catch (error) {
        console.log(error);
      }
    },
    async setApiDetail(apiId) {
      try {
        const response = await common.apiGet("api-mng/" + apiId);
        this.apiData = response.data.data;
        console.log(this.apiData);
      } catch (error) {
        console.error("error", error);
      }
    },
    goModPage() {
      this.$router.push({
        name: "modApiManage",
        params: { apiId: this.apiId },
      });
    },
    // Modal
    showModalAlert() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      $modal.style.display = "block";
      $modalAlert.style.display = "block";
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      const $modalAlert = this.$refs.modalAlert;
      // const $modalMember = this.$refs.modalMember;
      $modal.style.display = "none";
      $modalAlert.style.display = "none";
      // $modalMember.style.display = "none";
    },
    async removeApi() {
      try {
        const response = await common.apiDelete("api-mng/" + this.apiId);

        if (response.status == 200 || response.status == "200")
          this.$router.push("/sysOps/apiManageList");
      } catch (error) {
        console.error("error", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list_wrap {
  position: relative;
  .table_list {
    margin-top: 20px;
  }
  .list_filter_wrap {
    position: absolute;
    top: -90px;
  }
}
.item_info .item_table table th.before_left_border {
  vertical-align: middle;
}
.item_info .item_table table th.before_left_border::before {
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #e5e9ef;
  position: absolute;
  bottom: 16px;
  left: 0;
}
.list_wrap .table_list .mando_table thead th .col_name {
  // min-width: auto !important;
  padding-right: 15px;
}
.item_info .item_title {
  min-height: 21px;
}
</style>
