<template>
  <div id="container">
    <div id="">
      <div class="port_wrap">
        <div class="top_control radiusbox">
          <input
            type="text"
            :value="template.name"
            @change="(e) => (templateName = e.target.value)"
            placeholder="템플릿 저장 이름을 입력해주세요."
            class="tempname"
            :disabled="true"
          />
          <select
            @change="(e) => (customYn = e.target.value)"
            :value="template.customYn"
            :disabled="true"
          >
            <option :value="null">개인화 선택</option>
            <option :value="'Y'">개인화 가능</option>
            <option :value="'N'">개인화 불가능</option>
          </select>

          <select
            name="prjType"
            ref="prjType"
            :value="template.type"
            @change="setPrjType($event)"
            :disabled="true"
          >
            <option value="" disabled selected>선택해주세요.</option>
            <option
              v-for="(item, index) in prjTypeList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <div class="btn_area">
            <button id="data_set_button" class="mcbtn" @click="dataOpen">
              Data Set 설정
            </button>
            <button class="final_save mcbtn" @click="finalSave">
              Task 저장
            </button>
          </div>
        </div>
        <div class="component_pannel radiusbox">
          <button @click="() => addItem(Component1)" class="mcbtn">
            Filter
          </button>
          <button @click="() => addItem(Component2)" class="mcbtn">
            Button
          </button>
          <button @click="() => addItem(Component3)" class="mcbtn">Map</button>
          <button @click="() => addItem(Component4)" class="mcbtn">
            Label
          </button>
          <button @click="() => addItem(Component5)" class="mcbtn">
            Timeline
          </button>
          <button @click="() => addItem(Component6)" class="mcbtn">
            Remote
          </button>
          <button @click="() => addItem(Component7)" class="mcbtn">
            Video
          </button>
          <button @click="() => addItem(Component8)" class="mcbtn">
            Chart
          </button>
          <button @click="() => addItem(Component9)" class="mcbtn">
            Table
          </button>
          <button @click="() => addItem(Component10)" class="mcbtn">
            htmlBox
          </button>
        </div>
        <div class="rightCell radiusbox">
          <GridLayout
            v-model:layout="layout"
            :col-num="30"
            :row-height="30"
            class="templet_layout"
          >
            <grid-item
              v-for="(item, index) in layout"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :key="item.key"
              :type="item.type"
              @resized="resizedEvent"
              @resize="resizeEvent(item)"
              :style="
                item.type === 'Filter' && item.boxLayer === false
                  ? 'border: 0'
                  : '  border: 1px solid #D5DAE3'
              "
              :drag-allow-from="
                item.type === 'Map' || item.type === 'Timeline'
                  ? '.draggable_handle'
                  : ''
              "
              :drag-ignore-from="
                item.type === 'Map' || item.type === 'Timeline'
                  ? '.no_drag'
                  : ''
              "
            >
              <span
                class="text draggable_handle"
                v-if="itemTitle(item) !== 'TimelineSection'"
                >{{
                  !item.components.filter((e) => e.displayTitle)[0]
                    ?.displayTitle
                    ? itemTitle(item)
                    : item.components.filter((e) => e.displayTitle)[0]
                        ?.displayTitle
                }}
                {{ index }}</span
              >
              <span
                v-if="itemTitle(item) !== 'TimelineSection'"
                class="remove mcbtn"
                @click="removeItem(item.key)"
              >
                <font-awesome-icon :icon="['fas', 'xmark']" />
              </span>
              <span
                v-if="itemTitle(item) !== 'TimelineSection'"
                class="setting_button mcbtn"
                @click="settingOpen(item, index)"
                >설정</span
              >
              <component
                class="no_drag"
                v-if="item && item.key && item.type"
                :is="
                  item.type === 'Filter'
                    ? 'FilterComponent'
                    : item.type === 'Button'
                    ? 'ButtonComponent'
                    : item.type === 'Map'
                    ? 'MapController'
                    : item.type === 'Label'
                    ? 'LabelComponent'
                    : item.type === 'Timeline'
                    ? 'TimelineController'
                    : item.type === 'TimelineSection'
                    ? 'TimelineSection'
                    : item.type === 'Remote'
                    ? 'RemoteController'
                    : item.type === 'Video'
                    ? 'VideoController'
                    : item.type === 'Chart'
                    ? 'ChartController'
                    : item.type === 'Table'
                    ? 'TableController'
                    : item.type === 'HtmlBox'
                    ? 'HtmlBoxComponent'
                    : null
                "
                :resize="resi1ze"
                :mapHeight="item.newHPx"
                :key="comp_view_data_key"
                :otherProp="item"
                :handlerList="handlerList"
                :useButton="item.useButton"
                :filterDataSet="item.filterDataSet"
                :style="{ height: item.newHPx }"
                :dataSet="dataSet"
              ></component>
            </grid-item>
          </GridLayout>
        </div>
      </div>
      <div class="data_pop">
        <div class="data_pop_cell">
          <div class="header">
            <h4>Data setting</h4>
            <div class="btn_area">
              <div class="data_pop_cell_button" @click="dataSetInsert">
                <span class="data_set_button mcbtn"
                  ><font-awesome-icon :icon="['fas', 'plus']"
                /></span>
              </div>
              <div class="data_pop_cell_button">
                <span class="data_set_button mcbtn" @click="dataClose"
                  >저장</span
                >
              </div>
            </div>
            <div class="data_pop_cell_x" @click="dataClose">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </div>
          </div>
          <div class="body">
            <div class="body_frist" v-if="dataSetComponent.dataSet.length > 0">
              <div
                class="body_first_span"
                v-for="(item, index) in dataSetComponent.dataSet"
                :key="index"
              >
                <div class="body_first_warp">
                  <button
                    type="button"
                    class="mcbtn"
                    @click="dataSetSelect(index)"
                  >
                    {{ item.title }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="body_second"
              v-if="dataSetIndex > -1"
              :key="dataSetIndex"
            >
              <div
                class="row"
                v-for="(item, index) in dataSetComponent.dataSet[dataSetIndex]
                  .device"
                :key="index"
              >
                <input
                  :id="item.edge_device_id"
                  :key="index"
                  v-model="item.check"
                  type="checkbox"
                  @click="edgeDeviceSetting($event)"
                />
                <label :for="item.edge_device_id"></label
                ><span>{{ item.edge_device_name }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="footer" style="padding: 5px">
            <div>
              api:
              <input
                type="text"
                style="width: 90%"
                v-model="dataSetComponent.api"
              />
            </div>
          </div> -->
        </div>
      </div>
      <div class="setting_pop">
        <div class="setting_pop_cell">
          <div class="setting_pop_cell_x" @click="optionClose">
            <font-awesome-icon :icon="['fas', 'xmark']" />
          </div>
          <h4>{{ comp_name }} Setting</h4>

          <component
            :is="
              this.settingRender === 'Filter'
                ? 'FilterComponentSetting'
                : this.settingRender === 'Button'
                ? 'ButtonComponentSetting'
                : this.settingRender === 'Label'
                ? 'LabelCompentSetting'
                : this.settingRender === 'Map'
                ? 'MapControllerSetting'
                : this.settingRender === 'Timeline'
                ? 'TimelineControllerSetting'
                : this.settingRender === 'Remote'
                ? 'RemoteControllerSetting'
                : this.settingRender === 'Video'
                ? 'VideoControllerSetting'
                : this.settingRender === 'Chart'
                ? 'ChartControllerSetting'
                : this.settingRender === 'Table'
                ? 'TableControllerSetting'
                : this.settingRender === 'HtmlBox'
                ? 'HtmlBoxSetting'
                : null
            "
            :key="comp_data_key"
            ref="layoutComponent"
            :otherProp="comp_data"
            :nowIndex="comp_index"
            :layout="layout"
            :handlerList="handlerList"
            @updateHandler="bfsAddHandler"
            @removeHandler="delHandler"
            :dataSet="dataSet"
            :dataSetComponent="dataSetComponent"
            :filterDataList="filterDataList"
          ></component>

          <div class="setting_pop_cell_bottom_button_box">
            <button @click="optionSave">수정</button
            ><button @click="optionClose">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="lodingWrap" style="display: none" ref="lodingWrap">
    <div class="loading-container">
      <div class="loding-animation-holder">
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="loading-animator"></div>
        <div class="middle-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLayout from "@/components/HeaderLayout.vue";
import GlobalNavBar from "@/components/GlobalNavBar.vue";
import FooterLayout from "@/components/FooterLayout.vue";
import BreadcrumbLayout from "@/components/BreadcrumbLayout.vue";
import common from "@/assets/js/common";
import { GridLayout, GridItem } from "vue-grid-layout-v3";
import FilterComponent from "../templateComponent/filter.vue";
import FilterComponentSetting from "../templateComponent/filter_setting.vue";
import ButtonComponent from "../templateComponent/button.vue";
import ButtonComponentSetting from "../templateComponent/button_setting.vue";
import MapController from "@/components/MapController2.vue";
import MapControllerSetting from "@/components/MapControllerSetting.vue";
import TimelineController from "@/components/TimelineController2.vue";
import TimelineControllerSetting from "@/components/TimelineControllerSetting.vue";
import TimelineSection from "@/components/TimelineSection2.vue";
import ChartController from "@/components/ChartController2.vue";
import ChartControllerSetting from "@/components/ChartcontrollerSetting.vue";
import RemoteController from "@/components/RemoteController2.vue";
import RemoteControllerSetting from "@/components/RemoteControllerSetting.vue";
import VideoController from "@/components/VideoController2.vue";
import VideoControllerSetting from "@/components/VideoControllerSetting.vue";
import TableController from "@/components/TableController2.vue";
import TableControllerSetting from "@/components/TableControllerSetting.vue";
import PaginationUi from "@/components/PagenationUi.vue";
import LabelCompentSetting from "../templateComponent/label_setting.vue";
import LabelComponent from "../componentType/labelView.vue";
import apiTemplet from "@/assets/js/apiTemplet";
import HtmlBoxSetting from "../templateComponent/htmlBox_setting.vue";
import HtmlBoxComponent from "../componentType/htmlBoxView";

import _ from "lodash";
export default {
  name: "PortletTemplate",
  components: {
    HeaderLayout,
    GlobalNavBar,
    FooterLayout,
    BreadcrumbLayout,
    GridLayout,
    LabelComponent,
    GridItem,
    FilterComponent,
    FilterComponentSetting,
    ButtonComponent,
    ButtonComponentSetting,
    MapController,
    MapControllerSetting,
    TimelineController,
    TimelineControllerSetting,
    TimelineSection,
    RemoteController,
    RemoteControllerSetting,
    VideoController,
    VideoControllerSetting,
    ChartController,
    ChartControllerSetting,
    TableController,
    TableControllerSetting,
    PaginationUi,
    LabelCompentSetting,
    HtmlBoxSetting,
    HtmlBoxComponent
  },
  props: {
    query: {
      type: Object,
    },
    deviceList: {
      type: Object,
    },
    dataSet: {
      type: Object,
    },
    templateData: {
      type: Object,
    },
  },
  data() {
    const filterList = [];
    const filterRowData = [];
    return {
      filterList,
      filterRowData,
      layout: [],
      draggable: true,
      resizable: true,
      index: 0,
      totalItems: 100,
      itemsPerPage: 10,
      currentPage: 1,
      comp_data_key: 0, // 동적 key 값

      Component1: "Filter",
      Component2: "Button",
      Component3: "Map",
      Component4: "Label",
      Component5: "Timeline",
      Component5_1: "TimelineSection",
      Component6: "Remote",
      Component7: "Video",
      Component8: "Chart",
      Component9: "Table",
      Component10: "HtmlBox",

      comp_name: "",
      settingRender: "",

      baseUrl: "https://x9eylgsqmg.execute-api.ap-northeast-2.amazonaws.com",
      customYn: null,

      vessle: [],
      allData: [],
      dataList: [],
      dataListSliced: [],
      prjTypeList: [],

      prjData: {
        prjId: "",
        prjType: "",
      },
      loadpop: true,
      didopen: false,
      templateName: "",
      isloaded: false,
      onload: true,
      comp_data: {},
      comp_index: -1,
      template: {},
      comp_view_data_key: 1,
      handlerList: [],
      resize: false,
      dataSetComponent: {
        dataSet: [],
        api: "",
      },
      dataSetIndex: -1,
      bodySecondKey: 0,
      filterDataList: [],
    };
  },
  async mounted() {
    const loading = this.$refs.lodingWrap;
    loading.style.display = "block";
    this.setRole();
    await this.getTemplate();
    this.setPrjTypeList(this.getPrjTypeData());
    loading.style.display = "none";
  },
  methods: {
    async dataSetting(data) {
      const param = {
        type: "data",
        edge_id: "1",
        date_from: data.endDt,
        date_to: data.startDt,
        limit: 15,
        offset: 0,
        order: {
          key: "create_date",
          value: "desc",
        },
        keyword: {
          key: "title",
          value: "",
        },
        select: [
          {
            key: "",
            value: "",
          },
        ],
      };
      const response = await apiTemplet.post("/test/dataset", param);
      const dataList = response.data.items;
      const object = {};
      for (let i = 0; i < dataList.length; i++) {
        const key = dataList[i].category;
        if (object[key] === undefined || object[key] === null) {
          object[key] = [];
        }
        object[key].push(dataList[i]);
      }
      return object;
    },
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },
    edgeDeviceSetting(event) {
      this.dataSetComponent.dataSet[this.dataSetIndex].check =
        event.target.checked;
    },
    loadingTemplate(index) {
      let comp = JSON.parse(this.allData[index].components);
      this.layout.push(...comp);
      this.isloaded = true;
      this.onload = false;
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.displayDataPerPage(this.dataList);
    },
    displayDataPerPage(rowData) {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.dataListSliced = rowData.slice(startIndex, endIndex);
    },
    async getTemplate() {
      try {
        const query = this.query;
        console.log(query);
        console.log("mount edit");
        console.log("dataSet");
        console.log(this.dataSet);
        if (this.templateData) {
          // const response = await common.apiGet(`/template/` + query.id);
          this.template = _.cloneDeep(this.templateData);
          // this.test();
          const components = JSON.parse(this.templateData.components);
          this.components = components;
          this.layout = components;
          this.customYn = this.template.customYn;
          if (this.template.componentsDataset != null) {
            this.dataSetComponent = JSON.parse(this.template.componentsDataset);
          }
          const deviceList = await this.deviceList;
          this.dataSetComponent.dataSet.forEach(
            (ele) => (ele.device = _.cloneDeep(deviceList))
          );
          this.dataSetComponent.dataSet.forEach((ele) => {
            ele.check = false;
          });
          this.templateName = this.template.name;
          //layout 값을 가져오고 handlerId 값 셋팅하기
          const layoutCopy = this.layout;
          const handlerListCp = [];
          for (let i = 0; i < layoutCopy.length; i++) {
            if (layoutCopy[i].components !== undefined) {
              handlerListCp.push(...layoutCopy[i].components);
            }
          }
          for (let i = 0; i < handlerListCp.length; i++) {
            this.bfsAddHandler(handlerListCp[i]);
          }

          //필터 dataSet 수정시 마다 올라가기 mount 에도 적용해야함...
          let filterList = [];
          for (let i = 0; i < this.layout.length; i++) {
            const component = this.layout[i];
            component.index = i;
            if (component.type === "Filter") {
              filterList.push(component);
            }
          }
          this.filterDataList = filterList;
          console.log("filterList");
          console.log(filterList);

          return this.templateData;
        } else if (query.id) {
          const response = await common.apiGet(`/template/` + query.id);
          this.template = _.cloneDeep(response.data.data);
          this.test();
          const components = JSON.parse(this.template.components);
          this.components = components;
          this.layout = components;
          this.customYn = this.template.customYn;
          this.dataSetComponent = JSON.parse(this.template.componentsDataset);
          const deviceList = await this.deviceList;
          this.dataSetComponent.dataSet.forEach(
            (ele) => (ele.device = _.cloneDeep(deviceList))
          );
          this.dataSetComponent.dataSet.forEach((ele) => {
            ele.check = false;
          });
          this.templateName = this.template.name;
          //layout 값을 가져오고 handlerId 값 셋팅하기
          const layoutCopy = this.layout;
          const handlerListCp = [];
          for (let i = 0; i < layoutCopy.length; i++) {
            if (layoutCopy[i].components !== undefined) {
              handlerListCp.push(...layoutCopy[i].components);
            }
          }
          for (let i = 0; i < handlerListCp.length; i++) {
            this.bfsAddHandler(handlerListCp[i]);
          }

          //필터 dataSet 수정시 마다 올라가기 mount 에도 적용해야함...
          let filterList = [];
          for (let i = 0; i < this.layout.length; i++) {
            const component = this.layout[i];
            component.index = i;
            if (component.type === "Filter") {
              filterList.push(component);
            }
          }
          this.filterDataList = filterList;
          return response.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getPrjTypeData() {
      try {
        const response = await common.apiGet("/project-type");
        return response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async setPrjTypeList(prjTypeList) {
      prjTypeList.then((list) => {
        const dataList = [];
        list.forEach((element) => {
          dataList.push({
            id: element.projectTypeId,
            name: element.name,
          });
        });
        this.prjTypeList = dataList;
      });
    },
    setPrjType(event) {
      this.prjData["prjType"] = event.target.value;
    },
    test() {
      console.log(this.allData);
    },
    dataSetSelect(index) {
      console.log("dd");
      console.log(this.dataSetIndex);
      this.dataSetIndex = index;
      this.dataSetComponent = _.cloneDeep(this.dataSetComponent);
    },
    async finalSave() {
      if (this.customYn === null) {
        alert("layout 개인화 여부를 선택해 주세요.");
        return false;
      }
      if (this.templateName === "") {
        alert("저장할 템플릿 이름을 입력해주세요.");
        return false;
      }
      console.log("final Save!!! :: ", this.layout);
      let data = {
        templateId: this.template.id,
        name: this.templateName,
        customYn: this.template.customYn,
        components: JSON.stringify(this.layout, (key, value) => {
          return value === undefined ? null : value;
        }),
        componentsDataset: JSON.stringify(_.cloneDeep(this.dataSetComponent)),
        type:
          this.prjData.prjType === ""
            ? this.template.type
            : this.prjData.prjType,
      };

      this.$emit("templteData", data);

      try {
        // const id = this.template.id;
        // const response = await common.apiPatch("/template/" + id, data);
        // console.log(response);
        // alert("저장되었습니다.");
        // this.$router.push({
        //   path: "/sysOps/portleTemplate",
        // });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    itemTitle(item) {
      let result = item.type;
      if (item.static) {
        result += " - Static";
      }
      return result;
    },
    async optionSave() {
      console.log("수정");
            const components = this.$refs.layoutComponent.components;
      let check = true;
      for (let i = 0; i < components.length; i++) {
        switch (components[i].type) {
          case "calendar":
            console.log(components[i].calenderType);
            if (
              components[i].calenderType === null ||
              components[i].calenderType === undefined ||
              components[i].calenderType === ""
            ) {
              check = false;
            }

            if(components[i].calenderType === "period" && components[i].period === 0){
                check = false;
            }
            break;
          case "select_value":
            if (components[i].keyValueList.length === 0) {
              check = false;
            }
            break;
          default:
            break;
        }
      }
      if (check === false) {
        alert("세팅하지 않은 값이 있습니다.");
        return;
      }
      this.layout[this.comp_index].boxLayer = _.cloneDeep(
        this.$refs.layoutComponent.childBoxLayer
      );
      this.layout[this.comp_index].parentKey = _.cloneDeep(
        this.$refs.layoutComponent.handlerKey
      );
      this.layout[this.comp_index].useButton = _.cloneDeep(
        this.$refs.layoutComponent.childUseButton
      );

      this.layout[this.comp_index].filterDataSet = _.cloneDeep(
        this.$refs.layoutComponent.filterDataSet
      );

      this.layout[this.comp_index].selectSectionYn = _.cloneDeep(
        this.$refs.layoutComponent.selectSectionYn
      );
      if (
        this.layout[this.comp_index].type === "Map" &&
        this.layout[this.comp_index].parentKey !== null
      ) {
        const txt = this.layout[this.comp_index].parentKey;
        for (let i = 0; i < this.layout.length; i++) {
          if (this.layout[i].key === txt) {
            this.layout[i].setMapData = _.cloneDeep(
              this.$refs.layoutComponent.setMapData
            );
          }
        }
      }
      this.layout[this.comp_index].setMapData = _.cloneDeep(
        this.$refs.layoutComponent.setMapData
      );

      this.layout[this.comp_index].remoteKey = _.cloneDeep(
        this.$refs.layoutComponent.remoteKey
      );
      console.log("this.$refs.layoutComponent :: ", this.$refs.layoutComponent);
      const vm = this;
      const selectSectionYn = this.layout[this.comp_index].selectSectionYn;
      const parentKey = this.layout[this.comp_index].key;

      if (selectSectionYn === "Y") {
        this.addItem(vm.Component5_1, parentKey);
      } else {
        this.removeTimeLineSec(parentKey);
      }
      //labelText
      const labelHtml = this.$refs.layoutComponent.labelData;

      this.layout[this.comp_index].labelHtml = labelHtml;
      console.log(this.layout[this.comp_index]);
      console.log("여기서 저장이 되어야함");
      if (
        this.layout[this.comp_index].filterDataSet === true &&
        this.layout[this.comp_index].type === "Filter" &&
        this.layout[this.comp_index].components.filter(
          (ele) => ele.type === "calendar"
        )[0] !== undefined
      ) {
        console.log(
          this.layout[this.comp_index].components.filter(
            (ele) => ele.type === "calendar"
          )[0]
        );
        // this.dataSetComponent.dataSet.push();
        const calenderData = this.layout[this.comp_index].components.filter(
          (ele) => ele.type === "calendar"
        )[0];
        console.log("2222");

        if (calenderData.calenderType === "period") {
          const startDt = calenderData.startDate;
          const endDt = calenderData.endDate;
          let object = { startDt, endDt };

          const dataSetData = await this.dataSetting(object);
          this.layout[this.comp_index].dataSetCustom = dataSetData;
          for (let i = 0; i < this.dataSetComponent.dataSet.length; i++) {
            const date = this.dataSetComponent.dataSet.date;
            if (
              date !== undefined &&
              JSON.stringify(date) === JSON.stringify(object)
            ) {
              alert("이미 저장된 같은 날짜 필터가 있습니다");
              return;
            }
          }
          //이부분 수정 해야함... date 조건으로 중복 체크.
          this.dataSetComponent.dataSet.push({
            title: "data Set period filter",
            value: dataSetData,
            date: object,
          });
        } else {
          const startDt = calenderData.singleDate;
          const endDt = calenderData.singleDate;
          let object = { startDt, endDt };
          const dataSetData = await this.dataSetting(object);
          this.layout[this.comp_index].dataSetCustom = dataSetData;
          //이부분 수정 해야함... date 조건으로 중복 체크.

          for (let i = 0; i < this.dataSetComponent.dataSet.length; i++) {
            const date = this.dataSetComponent.dataSet[i].date;
            if (
              date !== undefined &&
              JSON.stringify(date) === JSON.stringify(object)
            ) {
              alert("이미 저장된 같은 날짜 필터가 있습니다");
              return;
            }
          }
          this.dataSetComponent.dataSet.push({
            title: "data Set singleDate filter",
            value: dataSetData,
            date: object,
          });
        }
        this.dataSetComponent = _.cloneDeep(this.dataSetComponent);
      }

      // const components = this.$refs.layoutComponent.components;
      // let check = true;
      // for(let i=0;i<components.length;i++){
      //   switch(components[i].type){
      //     case 'calendar':
      //       console.log(components[i].calenderType)
      //       if(components[i].calenderType === null || components[i].calenderType === undefined || components[i].calenderType ===''){
      //         check = false
      //        }
      //        break;
      //     case 'select_value':
      //       if(components[i].keyValueList.length===0){
      //         check = false;
      //       }
      //     break;
      //     default:
      //       break;
      //   }
      // }
      // if(check===false){
      //   alert('세팅하지 않은 값이 있습니다.')
      //   return;
      // }
      this.layout[this.comp_index].components = _.cloneDeep(
        this.$refs.layoutComponent.components
      );

      const layout = this.$refs.layoutComponent.layoutComponent;
      if (this.layout[this.comp_index].type === "Label") {
        const selectDataTitle2 = layout.selectDataTitle2;
        const dataMapCategory = layout.dataMapCategory;
        const dataSetType = layout.dataSetType;
        this.layout[this.comp_index].selectDataTitle2 = selectDataTitle2;
        this.layout[this.comp_index].dataMapCategory = dataMapCategory;
        this.layout[this.comp_index].dataSetType = dataSetType;
      }
      const filterType = layout.filterType;
      if (filterType !== undefined) {
        this.layout[this.comp_index].filterType = filterType;
      }

      //필터 dataSet 수정시 마다 올라가기 mount 에도 적용해야함...
      let filterList = [];
      for (let i = 0; i < this.layout.length; i++) {
        const component = this.layout[i];
        component.index = i;
        if (component.type === "Filter") {
          filterList.push(component);
        }
      }
      this.filterDataList = filterList;
      console.log(this.filterDataList);
      this.comp_view_data_key += 1;
      document.querySelector(".setting_pop").style.display = "none";
    },
    optionClose() {
      document.querySelector(".setting_pop").style.display = "none";
    },
    dataClose() {
      document.querySelector(".data_pop").style.display = "none";
    },
    addItem: function (type, parentKey) {
      const leng = this.layout.length;
      //w,h 는 각 컴포넌트별로 크기를 결국 분류해야한다.
      this.layout.push({
        x: (this.layout.length % 6) * 5,
        y: Math.floor(this.layout.length / 6) * 5,
        w: 5,
        h: 5,
        i: leng + "-" + Math.floor(Date.now()),
        type: type,
        key: leng + "-" + Math.floor(Date.now()),
        components: [],
        newWPx: 0,
        boxLayer: false,
        useButton: false,
        parentKey: parentKey,
      });
      console.log("edit addITem");
      console.log(this.layout);
      console.log(
        (this.layout.length % 3) * 4 +
          ", " +
          Math.floor(this.layout.length / 3) * 5
      );
    },
    removeItem: function (key) {
      // key 값과 일치하는 항목의 인덱스를 찾음
      const index = this.layout.findIndex((item) => item.key === key);

      // 유효한 인덱스가 있는 경우 해당 항목을 삭제
      if (index !== -1) {
        this.layout.splice(index, 1);
      }
      // this.layout = this.layout.sort((a,b)=>{
      //   return a.key.localeCompare(b.key);
      // })
      // console.log(this.layout)
      let filterList = [];
      for (let i = 0; i < this.layout.length; i++) {
        const component = this.layout[i];
        component.index = i;
        if (component.type === "Filter") {
          filterList.push(component);
        }
      }
      this.filterDataList = filterList;
    },
    removeTimeLineSec: function (key) {
      // Timeline 선택 구간 제거
      const tsIndex = this.layout.findIndex((item) => item.parentKey === key);
      console.log("!!!!!!!!!!!!!!!! :: ", this.layout);
      if (tsIndex !== -1) {
        this.layout.splice(tsIndex, 1);
      }
    },
    settingOpen(item, index) {
      this.comp_data = _.cloneDeep(this.layout[index]);
      console.log("setting open");
      console.log(this.comp_data);

      this.comp_name = item.type;
      this.settingRender = item.type;
      this.comp_index = index;
      this.comp_data_key += 1; // key 값 변경으로 컴포넌트 재렌더링 유도

      console.log(this.layout);
      document.querySelector(".setting_pop").style.display = "block";
    },
    dataOpen() {
      console.log(this.deviceList);
      document.querySelector(".data_pop").style.display = "block";
    },
    showFilter(event) {
      const _this = event.target;

      const $filterBtn =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".btn_filter");
      const $filterWrap = document.querySelector("#filterWrap");
      const $filterInput = $filterWrap.querySelectorAll(".filter_input");

      const hasShowClass = $filterWrap.classList.contains("show");
      const hasActiveClass = _this.classList.contains("active");

      for (var i = 0; i < $filterBtn.length; i++) {
        $filterBtn[i].classList.remove("active");
      }
      _this.classList.add("active");
      if (hasShowClass) {
        if (hasActiveClass) {
          $filterWrap.classList.remove("show");
          _this.classList.remove("active");
        }
      } else {
        $filterWrap.classList.add("show");
      }
      let activeIndex = Array.from($filterBtn).findIndex((element) =>
        element.classList.contains("active")
      );
      // console.log(activeIndex);
      // input
      for (var j = 0; j < $filterInput.length; j++) {
        $filterInput[j].querySelector("div").classList.remove("active");
      }
      // console.log($filterInput[activeIndex]);
      if (activeIndex > -1) {
        $filterInput[activeIndex].querySelector("div").classList.add("active");
      }
    },
    setActive(event) {
      const _this = event.target;
      const $filterDiv =
        _this.parentNode.parentNode.parentNode.querySelectorAll("div");
      const $filterIcon =
        _this.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          ".btn_filter"
        );

      for (var i = 0; i < $filterDiv.length; i++) {
        $filterDiv[i].classList.remove("active");
      }
      _this.parentNode.classList.add("active");

      // filter icon
      let activeIndex = Array.from($filterDiv).findIndex((element) =>
        element.classList.contains("active")
      );

      for (var j = 0; j < $filterIcon.length; j++) {
        $filterIcon[j].classList.remove("active");
      }
      $filterIcon[activeIndex].classList.add("active");
    },

    async addFilter(event) {
      // console.log("addFilter");

      const _this = event.target;
      const $thTag = _this.parentNode.parentNode;
      const $addBtn = $thTag.querySelector("button");
      const $input = $thTag.querySelector("input");
      // input 글자수
      const trimVal = $input.value.trim();

      // 컬럼 이름 가져오기
      const keyNameArr = _this.parentNode.parentNode.parentNode.parentNode
        .querySelector("tr:first-child")
        .querySelectorAll(".btn_filter");
      let activeIndex = Array.from(keyNameArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyName = String(keyNameArr[activeIndex].parentNode.textContent);

      const dataKeyArr =
        _this.parentNode.parentNode.parentNode.querySelectorAll(".input_box");
      const dataKeyindex = Array.from(dataKeyArr).findIndex((element) =>
        element.classList.contains("active")
      );
      const keyId = String(dataKeyArr[dataKeyindex].getAttribute("data-id"));

      const obj = {};
      // console.log();
      // console.log(this.getAttribute('data-index'))
      // console.log($input);

      window.filterData.taget = _this.getAttribute("data-index");

      if (0 < trimVal.length) {
        obj["keyId"] = keyId;
        obj["value"] = trimVal;
        obj["keyName"] = keyName;
        $input.value = "";
        this.filterList.push(obj);
        this.$refs.filterAllBtn.style.display = "block";
        $addBtn.parentNode.classList.add("disable");
        $addBtn.previousElementSibling.disabled = true;
        this.filterAction();
      }
    },

    filterAction(remove) {
      const vm = this;
      // 필터 리스트
      const _filterLsit = vm.filterList;
      // 테이블 리스트
      let _tableList = vm.dataList;
      if (vm.isfilter) {
        vm.filterDataList.origin = _tableList;
        vm.isfilter = false;
      }

      if (_filterLsit.length > 0) {
        const lodash = require("lodash");

        // 필터 삭제
        if (remove) {
          let _filterDataListOrign = [];
          vm.filterDataList.origin.forEach((item) => {
            _filterDataListOrign.push(item);
          });

          for (var j = 0; j < _filterLsit.length; j++) {
            const searchString = common.getUpper(_filterLsit[j].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[j].keyId;
            const filteredData = lodash.filter(_filterDataListOrign, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
            _filterDataListOrign = filteredData;
          }
          // 필터 추가
        } else {
          for (var i = 0; i < _filterLsit.length; i++) {
            const searchString = common.getUpper(_filterLsit[i].value);
            const regexPattern = new RegExp(searchString);

            // _tableList > 검색 1 리트스 > 검색 2 리스트

            const keyid = _filterLsit[i].keyId;
            const filteredData = lodash.filter(_tableList, (item) =>
              regexPattern.test(common.getUpper(item[keyid]))
            );
            vm.dataList = filteredData;
          }
        }

        // console.log("필터 동작 시작");
      } else {
        vm.dataList = vm.filterDataList.origin;
      }
      vm.currentPage = 1;
      vm.totalItems = vm.dataList.length;
      vm.displayDataPerPage(vm.dataList);
    },
    /*
      resizedEvent(i, newX, newY, newHPx, newWPx) {
  
        const copy = _.cloneDeep(this.layout);
        copy.find((ele) => ele.i === i).newWPx = newWPx;
        if (_.isEqual(copy, this.layout) === false) {
          this.layout = _.cloneDeep(this.layout);
          this.comp_view_data_key += 1;
          console.log("resized");
        }
      },
      resizeEvent(i, newX, newY, newHPx, newWPx) {
        if (this.layout.find((ele) => ele.i === i).newWPx === undefined) {
          this.layout.find((ele) => ele.i === i).newWPx = newWPx;
          this.layout = _.cloneDeep(this.layout);
          this.comp_view_data_key += 1;
        }
        console.log("resize");
      },
      */

    resizedEvent(i, newX, newY, newHPx) {
      const copy = this.layout.find((ele) => ele.i === i);

      if (this.layout.find((ele) => ele.i === i).type === "Map") {
        copy.newHPx = newHPx;
        this.resize = false;
        this.layout = _.cloneDeep(this.layout);
        console.log("this.layout :: ", this.layout);
        //this.comp_view_data_key += 1;
      }
    },
    resizeEvent(item) {
      if (item.type === "Map") {
        this.resize = true;
      }

      console.log("resize");
    },
    //삭제되었을때..
    delHandler(newChild) {
      const handlerTree = this.handlerList;
      // BFS 탐색을 위한 큐
      const queue = [...handlerTree]; // handlerTree 배열 복사해서 큐로 사용
      console.log("delHandeler");

      // BFS 탐색 시작
      while (queue.length > 0) {
        const current = queue.shift(); // 큐에서 첫번째 요소 제거 및 반환

        // 자기 자신일 때
        if (current.handlerKey === newChild.handlerKey) {
          // 자기 자신을 처리 (예: 삭제나 업데이트)
          this.handlerList = _.cloneDeep(
            this.handlerList.filter(
              (item) => item.handlerKey !== newChild.handlerKey
            )
          );
          break;
        }

        // 자식 노드가 있을 때 자식들을 큐에 추가
        if (current.children) {
          queue.push(...current.children);
        }
      }
      return null;
    },
    //추가되었을때..
    bfsAddHandler(newChild) {
      const handlerTree = this.handlerList;
      // BFS 탐색을 위한 큐
      const queue = [...handlerTree]; // handlerTree 배열 복사해서 큐로 사
      // BFS 탐색 시작
      console.log("addHandler");
      while (queue.length > 0) {
        const current = queue.shift(); // 큐에서 첫번째 요소 제거 및 반환

        // 부모 노드를 찾았을 때
        if (
          current.child !== undefined &&
          current.child.handlerKey === newChild.handlerKey
        ) {
          // 부모의 자식이 없을 때 새로운 자식 노드를 추가
          current.child(newChild);
          return newChild;
        }
        // 자식이 있으면 큐에 추가
        if (
          current.child !== undefined &&
          current.child.handlerKey !== undefined
        ) {
          queue.push(...current.child);
        }
      }

      // 부모를 찾지 못한 경우
      this.handlerList.push(newChild);
    },
    //값이 변경되었을때...
    bfsEditHandler(newChild) {
      const handlerTree = this.handlerList;
      // BFS 탐색을 위한 큐
      const queue = [...handlerTree]; // handlerTree 배열 복사해서 큐로 사용
      console.log("delHandeler");

      // BFS 탐색 시작
      while (queue.length > 0) {
        const current = queue.shift(); // 큐에서 첫번째 요소 제거 및 반환

        // 자기 자신일 때
        if (current.handlerKey === newChild.handlerKey) {
          // 자기 자신을 처리 (예: 삭제나 업데이트)
          this.handlerList = _.cloneDeep(
            this.handlerList.map((item) => {
              if (item.handlerKey === newChild.handlerKey) {
                return newChild;
              } else {
                return item;
              }
            })
          );
          break;
        }

        // 자식 노드가 있을 때 자식들을 큐에 추가
        if (current.children) {
          queue.push(...current.children);
        }
      }
      return null;
    },
    dataSetInsert() {
      const object = {
        title: "data Set" + this.dataSetComponent.dataSet.length,
        device: [],
      };
      this.dataSetComponent.dataSet.push(object);
    },
    removeDataSet(index) {
      this.dataSetComponent.dataSet.splice(index, 1);
    },
  },
  watch: {
    comp_data() {
      //layout 블럭 현재 설정 관련해서 선택된 값
    },
  },
};
</script>

<style scoped>
.vue-grid-layout {
  background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
<style scoped>
* {
  box-sizing: border-box;
}

.port_wrap {
  width: calc(100% - 0px);
  height: 100vh;
  padding: 20px;
  background: #f4f4f4;
  position: relative;
  top: auto;
  left: auto;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.port_wrap .component_pannel {
  width: 240px;
  height: calc(100% - 40px - 60px - 40px);
  position: absolute;
  top: 120px;
  left: 20px;
  background: #fff;
  padding: 20px;
  border: 1px solid#d5dae3;
  overflow-y: auto;
}
.port_wrap .component_pannel button {
  width: 100%;
  height: 48px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.port_wrap .rightCell {
  width: calc(100% - 40px - 240px - 10px);
  right: 20px;
  height: calc(100% - 40px - 60px - 40px);
  position: absolute;
  top: 120px;
  background: #fff;
  border: 1px solid #d5dae3;
  overflow-y: auto;
}
.port_wrap .rightCell .control_pannel {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d4d4d4;
  background: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 70px;
  text-align: right;
  padding-right: 20px;
}
.port_wrap .rightCell .grid_box_wrap {
  width: 100%;
  height: calc(100% - 70px);
  top: 0px;
  left: 0px;
  position: absolute;
  display: grid;
  overflow: auto;
  gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
  padding: 20px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell {
  height: 90px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px;
}
.port_wrap .rightCell .grid_box_wrap.batch_grid .grid_cell:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.batch_grid {
  z-index: 4;
  background: rgba(0, 0, 0, 0.4);
  height: auto !important;
  cursor: crosshair !important;
}
.batch_grid:after {
  position: absolute;
  z-index: -1;
  color: White;
  display: block;

  font-size: 16px;
  font-weight: normal;
  content: "마우스 드래그로 배치할 영역을 설정해 주세요";
  text-align: center;
  padding-top: 400px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
}
.real .grid_cell {
  border: none !important;
  background: none !important;
}
.real .grid_cell:hover {
  border: none !important;
  background: none !important;
}
.batch_close {
  position: Absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background: white;
  z-index: 9;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.batch_grid .grid_cell.marked {
  background: rgba(0, 0, 0, 0.1) !important;
}
.real_grid_cell {
  background: white;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  position: relative;
  padding: 10px;
  padding-top: 0px;
}
.real_grid_cell:hover {
  z-index: 9999;
}
.real_grid_cell h4 {
  display: block;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d4d4d4;
  text-align: left;
  line-height: 40px;
  font-size: 15px;
  font-weight: normal;
  color: black;
  padding: 0px;
}
.real_grid_cell h4 button {
  width: auto;
  background: #00b4ed;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: none;
  margin-top: 4px;
  margin-left: 4px;
}
.real_grid_cell h4 button:hover {
  background: #00b4ed;
}
.mustblock {
  display: block !important;
}
.control_pannel button {
  border: none;
  background: #00b4ed;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}

.control_pannel button {
  border: none;
  background: #00b4ed;
  border-radius: 4px;
  text-align: center;
  height: 40px;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: White;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
}
.control_pannel button:hover {
  background: #00b4ed;
}
.top_control {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 20px;
  text-align: left;
  border: 1px solid #d5dae3;
}
.top_control select {
  width: auto;
  height: 40px;
  padding-right: 40px;
  background-position: center right 15px;
  border-radius: 10px;
  margin-left: 10px;
}
.top_control .btn_area {
  position: absolute;
  top: 20px;
  right: 20px;
}
.top_control .btn_area button {
  height: 40px;
  margin-left: 10px;
  padding: 0 15px;
  border-radius: 10px;
  color: #fff;
}
.grid_box_wrap.real.layout_mode .moving_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real.layout_mode .bottom_hei_pannel {
  display: block;
}
.moving_pannel {
  width: 90px;
  height: 90px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 5px;
  display: none;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
.right_wid_pannel {
  position: Absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 1px;
  overflow: visible;
  display: none;
}
.right_wid_pannel .left_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/right_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .left_ar:hover {
  opacity: 1;
}
.right_wid_pannel .right_ar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/left_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  opacity: 0.5;
  position: absolute;
  top: calc(50% + 40px);
  right: 4px;
  cursor: pointer;
}
.right_wid_pannel .right_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel {
  position: absolute;
  width: 100%;
  height: 1px;
  overflow: visible;
  bottom: 0px;
  left: 0px;
  display: none;
}
.bottom_hei_pannel .up_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/up_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
}
.bottom_hei_pannel .up_ar:hover {
  opacity: 1;
}
.bottom_hei_pannel .down_ar {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  background: white url("@/assets/down_ar.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  bottom: 4px;
  left: 44px;
  cursor: pointer;
}
.bottom_hei_pannel .down_ar:hover {
  opacity: 1;
}
.moving_pannel .left_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/right_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .left_ar:hover {
  opacity: 1;
}
.moving_pannel .right_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  background: url("@/assets/left_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .right_ar:hover {
  opacity: 1;
}
.moving_pannel .top_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  top: 0px;
  cursor: pointer;
  background: url("@/assets/up_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .top_ar:hover {
  opacity: 1;
}
.moving_pannel .down_ar {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -20px;
  bottom: 0px;
  cursor: pointer;
  background: url("@/assets/down_ar.png") no-repeat;
  background-position: Center center;
  opacity: 0.5;
}
.moving_pannel .down_ar:hover {
  opacity: 1;
}
.grid_box_wrap.real.layout_mode .grid_cell {
  border: 1px solid #e6e6e6 !important;
}
.setting_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.setting_pop_cell {
  width: 700px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  background: White;
  padding: 40px;
}
.setting_pop_cell .setting_pop_cell_x {
  width: 40px;
  height: 40px;
  color: #bbb;
  font-size: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 34px;
  right: 36px;
}
.setting_pop_cell h4 {
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
  border-bottom: 1px solid #d5dae3;
  padding-bottom: 18px;
}
.setting_pop_cell table {
  border-collapse: collapse;
  width: 100%;
  height: auto;
}
.setting_pop_cell table th {
  background: #f4f4f4;
  border: 1px solid #d4d4d4;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: black;
  vertical-align: middle;
}
.setting_pop_cell table td {
  padding: 4px;
  background: white;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  font-weight: normal;
  color: black;
  text-align: left;
  vertical-align: middle;
}
.setting_pop_cell table td select {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-right: 40px;
  background-position: right 10px center;
  font-size: 14px;
  padding-left: 10px;
  font-weight: normal;
  color: black;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
}
.setting_pop_cell table td textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  border: 1px solid #d4d4d4;
  height: 200px;
}
.setting_pop_cell table td input[type="text"] {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.checks {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}
.checks span {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box {
  width: 100%;
  height: 60px;
  border-top: 1px solid #d4d4d4;
  text-align: right;
  position: absolute;
  line-height: 60px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  padding-right: 20px;
  padding-left: 20px;
}
.setting_pop_cell_bottom_button_box button {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: white;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 4px;
  background: #00b4ed;
  border: none;
  cursor: pointer;
  margin-left: 4px;
}
.setting_pop_cell_bottom_button_box button:hover {
  background: #00b4ed;
}
.setting_pop_cell_contents_wrap {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
  overflow: auto;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .moving_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .right_wid_pannel {
  display: block;
}
.grid_box_wrap.real .grid_box_wrap.batch_grid .bottom_hei_pannel {
  display: block;
}
.vue-grid-layout {
  background: white;
}
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.setting_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 40px;
}
.vue-grid-item {
  background: white !important;
}
.vue-grid-item span.text {
  display: block;
  height: 40px;
  /* border-bottom: 1px solid #d4d4d4; */
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: left;
  line-height: 40px;
  font-size: 14px;
  font-weight: normal;
  color: black;
  margin: 0px;
  padding-left: 12px;
}
.vue-grid-item span.remove {
  width: 32px;
  height: 32px;
  top: 4px;
  right: 4px;
  position: absolute;
  color: #fff;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  font-weight: normal;
}
.vue-grid-item span.remove:hover {
  background: #00b4ed;
}
.vue-grid-item span.setting_button:hover {
  background: #00b4ed;
}
.vue-grid-item {
  padding: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}

.load_btn {
  cursor: pointer;
  width: auto;
  float: right;
  height: 40px;
  background: #00b4ed;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: Bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
  right: 130px !important;
}
.load_pop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.load_pop .load_pop_cell {
  width: 1000px;
  height: 600px;
  background: white;
  position: relative;
  padding: 20px;
}
.load_pop .load_pop_cell select {
  width: 150px;
  height: 40px;
  border: 1px solid #d4d4d4;
  margin-left: 20px;
  display: inline-block;
  background-position: right 4px center;
  vertical-align: top;
}
.load_pop .load_pop_cell .search {
  display: inline-block;
  width: 240px;
  height: 40px;
  border-radius: 0px;
  padding-right: 60px;
  position: relative;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}
.load_pop .load_pop_cell .search input[type="text"] {
  width: 100%;
  height: 38px;
  background: white;
  border: none;
  padding-left: 10px;
}
.load_pop .load_pop_cell .search button {
  width: 60px;
  height: 38px;
  background: #f4f4f4;
  border-left: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
}
.load_pop .load_pop_cell .new_open {
  width: 100px;
  height: 40px;
  background: #00b4ed;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  float: right;
}
.load_pop .load_pop_cell .new_open:hover {
  background: #00b4ed;
}
.load_pop .load_pop_cell h4 {
  height: 50px;
  line-height: 50px;
  font-size: 45px;
  font-weight: lighter;
  color: black;
  padding: 0px;
  margin: 0px;
  margin-bottom: 7px;
}
.load_pop .load_pop_cell .sc_wrap {
  width: 100%;
  height: 490px;
  background: #f4f4f4;
  overflow: auto;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}
.load_pop .load_pop_cell .sc_wrap:after {
  clear: both;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell {
  width: 100%;
  height: 50px;
  float: left;
  position: relative;
  padding: 0px;
  padding-bottom: 10px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real {
  width: 100%;
  height: 40px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  cursor: pointer;
  position: Relative;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real:hover {
  border: 1px solid skyblue;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .new_data:after {
  content: "...New Data";
  font-size: 17px;
  font-weight: bold;
  color: black;
  display: block;
  width: 100%;
  text-align: center;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved {
  position: Absolute;
  top: 0px;
  text-align: center;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 38px;
  font-size: 17px;
  font-weight: bold;
  color: black;
  text-align: left;
  padding-left: 14px;
}
.load_pop .load_pop_cell .sc_wrap .sc_wrap_cell .cell_real .saved span {
  display: block;
  width: 200px;
  height: 38px;
  position: absolute;
  text-align: center;
  bottom: 0px;
  right: 0px;
  line-height: 38px;
  font-size: 11px;
  font-weight: normal;
  color: gray;
}
.load_pop .load_pop_cell .load_pop_cell_x {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: -25px;
  z-index: 22;
  background: #444;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
}
.load_pop .load_pop_cell .load_pop_cell_x:hover {
  background: black;
}
.tempname {
  width: 236px;
  height: 40px;
  border: 1px solid #d5dae3;
  padding: 0 10px;
  padding-bottom: 0px;
  border-radius: 10px;
  box-sizing: border-box;
}
.list_wrap {
  position: relative;
}
.list_wrap .table_list {
  height: 715px;
}

.templet_layout {
  position: absolute;
  inset: 0;
  width: 100%;
  min-height: 100%;
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.select_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: #00b4ed;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 40px;
}
.body {
  display: flex;
  width: 100%;
  height: calc(100% - 110px);
  border: 1px solid #d5dae3;
  box-sizing: border-box;
  border-radius: 10px;
}
.body_frist {
  width: 50%;
  height: 100%;
  border-right: 1px solid #d5dae3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  gap: 10px;
  overflow: auto;
}
.body_second {
  width: 50%;
  height: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.body_second .row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.body_first_span .body_first_warp {
  position: relative;
}
.body_first_span .body_first_warp input {
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
}
.body_first_span .body_first_warp span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}
.body_first_span .body_first_warp button {
  height: 40px;
  padding: 0 24px;
  border-radius: 10px;
}
.data_set_button {
  width: 60px;
  height: 28px;
  background: blue;
  text-align: center;
  padding: 5px;
  color: white;
}
.data_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  display: none;
}
.data_pop_cell {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 600px;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
  text-align: center;
}
.data_pop_cell h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
  border-bottom: 1px solid #d5dae3;
  padding-bottom: 25px;
}
.data_pop_cell .btn_area {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.data_pop_cell_button span {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}

.data_pop_cell .data_pop_cell_x {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #bbb;
}
.port_wrap {
  width: calc(100% - 0px);
  height: 67vh;
  padding: 20px;
  background: #f4f4f4;
  position: relative;
  top: auto;
  left: auto;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.templet_layout::before {
  position: absolute;
  left: 0;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  margin: 5px;
  content: "";
  background-image: linear-gradient(to right, lightgrey 1px, transparent 1px),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
  background-repeat: repeat;
  background-size: calc(calc(100% - 5px) / 30) 40px;
}
</style>
