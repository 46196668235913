<template>
  <div
    class="radiusbox"
    style="overflow: hidden; height: 100%"
    :id="otherProp != null ? 'remote_' + otherProp.key : 'remote'"
  >
    <div class="remote_wrap">
      <div class="top">
        <div class="title">
          <i></i>
          <span>Remote Control</span>
        </div>
        <!-- <div class="switch" @click="showModal()">
          <div class="on_off" :class="isRemoteControl ? 'on' : 'off'">
            <span>{{ isRemoteControl ? "On" : "Off" }}</span>
          </div>
        </div> -->
      </div>
      <div class="control">
        <div class="left">
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_s btn_s_top pst_top"
              @click="setspd('angular', 0.1)"
            >
              <span id="button1"></span>
            </button>
          </div>
          <div class="btn_wrap">
            <div class="txt pst_middle">
              {{ angularspd === 0 ? (0.0).toFixed(1) : angularspd.toFixed(1) }}
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_s btn_s_bottom pst_bottom"
              @click="setspd('angular', -0.1)"
            >
              <span id="button9"></span>
            </button>
          </div>
        </div>
        <div class="middle">
          <div class="pst_top">
            <button
              type="button"
              class="btn_b btn_b_lt"
              @click="socketSendDirection('fl')"
            >
              <span id="button2"></span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_mt"
              @click="socketSendDirection('f')"
            >
              <span id="button3"></span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_rt"
              @click="socketSendDirection('fr')"
            >
              <span id="button4"></span>
            </button>
          </div>
          <div class="pst_middle">
            <button
              type="button"
              class="btn_b btn_b_lm"
              @click="socketSendDirection('l')"
            >
              <span id="button5"></span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_mm"
              @click="socketSendDirection('s')"
            >
              <span>■</span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_rm"
              @click="socketSendDirection('r')"
            >
              <span id="button6"></span>
            </button>
          </div>
          <div class="pst_bottom">
            <button
              type="button"
              class="btn_b btn_b_lb"
              @click="socketSendDirection('bl')"
            >
              <span id="button7"></span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_mb"
              @click="socketSendDirection('b')"
            >
              <span id="button8"></span>
            </button>
            <button
              type="button"
              class="btn_b btn_b_rb"
              @click="socketSendDirection('br')"
            >
              <span id="button10"></span>
            </button>
          </div>
        </div>
        <div class="right">
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_s btn_s_top pst_top"
              @click="setspd('linear', 0.1)"
            >
              <span id="button11"></span>
            </button>
          </div>
          <div class="btn_wrap">
            <div class="txt pst_middle">
              {{ linearspd === 0 ? (0.0).toFixed(1) : linearspd.toFixed(1) }}
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_s btn_s_bottom pst_bottom"
              @click="setspd('linear', -0.1)"
            >
              <span id="button12"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="stop">
        <button type="button" @click="emergencyStop()">Emergency Stop</button>
      </div>
    </div>
  </div>
  <div id="modalWrap" ref="modalWrap" style="display: none">
    <div class="radiusbox modal_contents message_modal">
      <div class="message_box">
        아래 Device의 원격제어를
        <span class="txt" :class="isRemoteControl ? 'txt_r' : 'txt_b'">{{
          isRemoteControl ? "종료하시겠습니까?" : "실행하시겠습니까?"
        }}</span>
      </div>
      <div class="input_box">
        <div class="input_text">
          <p>- {{ confirmedSelectedDevice.edge_device_id }}</p>
        </div>
      </div>
      <div class="message_box" style="padding: 0">
        <ul>
          <li style="text-align: left; padding: 0 10px">
            - 원격 제어 실행 시, 미션은 일시 중지 됩니다.
          </li>
          <li style="text-align: left; padding: 0 10px">
            - 원격 제어 완료 후, 다중 관계 페이지에서 미션을 직접 재개해주세요.
          </li>
        </ul>
      </div>
      <div class="button_bottom_box">
        <button
          type="button"
          class="btn_check btn_cancel"
          @click="closeModal()"
        >
          취소
        </button>
        <button type="button" class="btn_check mcbtn" @click="remoteOnOff">
          확인
        </button>
      </div>
      <button type="button" class="btn_close" @click="closeModal()"></button>
    </div>
  </div>
</template>

<script>
import apiRobot from "@/assets/js/apiRobot";
export default {
  name: "RemoteController",
  computed: {},
  props: {
    tblData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isRemoteControl: false,
      confirmedSelectedDevice: {},
      angularspd: 0,
      linearspd: 0,
      gamepadIndex: null,
      gamepadStatus: "No gamepad connected",
      lastAxisInputTime: { x: 0, y: 0 },
      keySetInterval: null,
      isPressKey: false,
    };
  },
  watch: {},
  created() {},
  beforeUnmount() {
    window.removeEventListener("gamepadconnected", this.onGamepadConnected);
    window.removeEventListener(
      "gamepaddisconnected",
      this.onGamepadDisconnected
    );
    cancelAnimationFrame(this.pollingId);
  },
  mounted() {
    const vm = this;
    window.addEventListener("gamepadconnected", this.onGamepadConnected);
    window.addEventListener("gamepaddisconnected", this.onGamepadDisconnected);
    this.startPolling();
    window.addEventListener("keypress", function (e) {
      vm.isPressKey = true;
      if (vm.isRemoteControl) {
        const keyCode = e.key;

        if (vm.keySetInterval) {
          clearInterval(vm.keySetInterval);
        }
        vm.keySetInterval = setInterval(vm.keyACtion(keyCode), 500);
      }
    });

    window.addEventListener("keyup", function () {
      clearInterval(vm.keySetInterval);
    });
  },
  methods: {
    async emergencyStop() {
      if (this.isRemoteControl) {
        const deviceId = this.confirmedSelectedDevice.edge_device_name;
        try {
          const param = {
            device_id: deviceId,
          };
          const response = await apiRobot.post("/remote/emergency_stop", param);
          console.log(
            "신규 API 결과/remote/emergency_stop ::----------------------------- ",
            response
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    async setspd(spdType, val) {
      if (this.isRemoteControl) {
        const deviceId = this.confirmedSelectedDevice.edge_device_name;
        try {
          if (spdType === "linear") {
            if (val > 0 && this.linearspd <= 1.5) {
              this.linearspd = this.linearspd + val;
            } else if (val < 0 && this.linearspd >= 0.1) {
              this.linearspd = this.linearspd + val;
            }
          } else if (spdType === "angular") {
            if (val > 0 && this.angularspd <= 1.5) {
              this.angularspd = this.angularspd + val;
            } else if (val < 0 && this.angularspd >= 0.1) {
              this.angularspd = this.angularspd + val;
            }
          }

          const param = {
            device_id: deviceId,
            linear_velocity: this.linearspd.toFixed(1),
            angular_velocity: this.angularspd.toFixed(1),
          };
          const response = await apiRobot.post(
            "/remote/remote_control_velocity",
            param
          );
          console.log(
            "신규 API 결과 remote_control_velocity::----------------------------- ",
            response
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    // 단축키 액션
    keyACtion(e) {
      if (e === "q") {
        this.setspd("angular", 0.1);
      } else if (e === "z") {
        this.setspd("angular", -0.1);
      } else if (e === "w") {
        this.setspd("linear", 0.1);
      } else if (e === "x") {
        this.setspd("linear", -0.1);
      } else if (e === "u") {
        this.socketSendDirection("fl");
      } else if (e === "i") {
        this.socketSendDirection("f");
      } else if (e === "o") {
        this.socketSendDirection("fr");
      } else if (e === "j") {
        this.socketSendDirection("l");
      } else if (e === "k") {
        this.socketSendDirection("s");
      } else if (e === "l") {
        this.socketSendDirection("r");
      } else if (e === "m") {
        this.socketSendDirection("bl");
      } else if (e === ",") {
        this.socketSendDirection("b");
      } else if (e === ".") {
        this.socketSendDirection("br");
      }
    },
    onGamepadConnected(event) {
      this.gamepadIndex = event.gamepad.index;
      this.gamepadStatus = `Gamepad ${event.gamepad.index} connected`;
    },
    onGamepadDisconnected() {
      this.gamepadStatus = "No gamepad connected";
      this.gamepadIndex = null;
    },
    startPolling() {
      const poll = () => {
        if (this.gamepadIndex !== null) {
          const gamepad = navigator.getGamepads()[this.gamepadIndex];
          if (gamepad) {
            this.handleGamepadInput(gamepad);
          }
        }
        this.pollingId = requestAnimationFrame(poll);
      };
      poll();
    },
    handleGamepadInput(gamepad) {
      const currentTime = Date.now();
      gamepad.buttons.forEach((button, index) => {
        if (button.pressed) {
          console.log(`Button ${index} pressed`);
        }
      });
      const x = gamepad.axes[0];
      const y = gamepad.axes[1];
      if (Math.abs(x) > 0.1 || Math.abs(y) > 0.1) {
        // Deadzone check
        if (
          currentTime - this.lastAxisInputTime.x > 500 ||
          currentTime - this.lastAxisInputTime.y > 500
        ) {
          let direction = "Center";
          if (y < -0.5) {
            if (x < -0.5) {
              direction = "Up-Left";
              this.socketSendDirection("fl");
            } else if (x > 0.5) {
              direction = "Up-Right";
              this.socketSendDirection("fr");
            } else {
              direction = "Up";
              this.socketSendDirection("f");
            }
          } else if (y > 0.5) {
            if (x < -0.5) {
              direction = "Down-Left";
              this.socketSendDirection("bl");
            } else if (x > 0.5) {
              direction = "Down-Right";
              this.socketSendDirection("br");
            } else {
              direction = "Down";
              this.socketSendDirection("b");
            }
          } else {
            if (x < -0.5) {
              direction = "Left";
              this.socketSendDirection("l");
            } else if (x > 0.5) {
              direction = "Right";
              this.socketSendDirection("r");
            }
          }
          console.log(`Direction: ${direction}`);
          this.lastAxisInputTime.x = currentTime;
          this.lastAxisInputTime.y = currentTime;
        }
      }
    },
    async remoteOnOffRest() {
      const deviceId = this.confirmedSelectedDevice.edge_device_name;
      try {
        const loading = this.$refs.lodingWrap;
        loading.style.display = "block";
        const param = {
          device_id: deviceId,
          value: this.isRemoteControl ? 1 : 0,
        };
        const response = await apiRobot.post("/remote/remote_active", param);
        loading.style.display = "none";
        console.log("신규 API 결과 ::----------------------------- ", response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    remoteOnOff() {
      if (this.isRemoteControl) {
        this.isRemoteControl = false;
      } else {
        this.isRemoteControl = true;
      }
      this.remoteOnOffRest();
      this.closeModal(10);
    },
    showModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "block";
    },
    closeModal() {
      const $modal = this.$refs.modalWrap;
      $modal.style.display = "none";
    },
  },
};
</script>
<style scoped lang="scss">
// Remote Controller
.remote_wrap {
  position: relative;
  height: 100%;
  & > .top {
    display: flex;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    background-color: #12a1d5;
    box-sizing: border-box;
    & > .title {
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        width: 12px;
        height: 12px;
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: #02a744;
      }
      span {
        position: relative;
        top: 1px;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
      }
    }
    & > .switch {
      position: relative;
      width: 63px;
      padding: 5px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 13px;
      cursor: pointer;
      .on_off {
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #12a1d5;
        }
        &.on {
          text-align: right;
          &::before {
            left: 3px;
          }
        }
        &.off {
          text-align: left;
          &::before {
            right: 3px;
          }
        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #12a1d5;
        }
      }
    }
  }
  & > .control {
    display: flex;
    height: 60%;
    margin-top: 8px;
    padding: 0 15px;
    .left,
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      box-sizing: border-box;
      .btn_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 35%;
        &:nth-child(2) {
          height: 30%;
        }
        .txt {
          display: flex;
          align-items: center;
          width: 80%;
          height: 80%;
          border: 1px solid #e5e9ef;
          font-size: 20px;
          font-weight: 600;
          border-radius: 10px;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 85%;
          background-color: #12a1d5;
          border-radius: 10px;
          span {
            color: #fff;
            font-size: 26px;
            font-weight: 600;
            border-radius: 10px;
          }
        }
      }
    }
    .left {
      margin-right: 5px;
    }
    .middle {
      width: 60%;
      min-width: 53px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .right {
      margin-left: 5px;
    }
    .pst_top {
      display: flex;
      gap: 5px;
      height: calc(100% / 3);
    }
    .pst_middle {
      display: flex;
      width: 100%;
      gap: 5px;
      height: calc(100% / 3);
      justify-content: center;
    }
    .pst_bottom {
      display: flex;
      gap: 5px;
      height: calc(100% / 3);
    }
    .btn_b {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 3);
      height: 100%;
      background-color: #d9d9d9;
      border-radius: 10px;
      span {
        display: inline-block;
        color: #02a744;
        font-size: 35px;
      }
      &.btn_b_lt {
        span {
          transform: rotate(80deg);
        }
      }

      &.btn_b_rt {
        span {
          transform: rotate(-80deg);
        }
      }
      &.btn_b_lm {
        span {
        }
      }
      &.btn_b_mm {
        background-color: #fde5e3;
        span {
          color: #f15046;
        }
      }
      &.btn_b_rm {
      }
      &.btn_b_lb {
        span {
          transform: rotate(-80deg);
        }
      }

      &.btn_b_mb {
      }
      &.btn_b_rb {
        span {
          transform: rotate(80deg);
        }
      }
    }
  }
  .stop {
    width: 100%;
    height: calc(35% - 60px);
    margin-top: 2.5%;
    text-align: center;
    button {
      width: 80%;
      height: 100%;
      border-radius: 10px;
      background-color: #f15046;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.status_single {
  width: 100%;
  flex: 1;
  .health_status {
    height: calc(100% - 50px);
    & > div {
      display: flex;
      height: 100%;
      padding: 0 15px;
      gap: 20px;
      box-sizing: border-box;
      .item_wrap {
        width: 50%;
        height: 100%;
        .item_wrap_inner {
          display: flex;
          align-items: center;
          text-align: center;
          height: 50%;
          .title,
          .data {
            font-size: 22px;
            font-weight: 600;
          }
          .title {
            width: 70%;
            line-height: 1.2;
          }
          .data {
            width: 59px;
            height: 17px;
            text-align: center;
            background-color: #12a1d5;
            color: #fff;
            padding: 4px;
            overflow: hidden;
            font-size: 100%;
            align-items: center;
            border-radius: 10px;
          }
        }
      }
    }
  }
  h3 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: #12a1d5;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.message_modal {
  width: 500px;
  height: auto;
  margin-top: 20px;
  .message_box {
    position: static;
    margin-bottom: 20px;
    padding-top: 20px;
    transform: translate(0);
    .txt {
      font-size: 18px;
      font-weight: 600;
      &.txt_b {
        color: #12a1d5;
      }
      &.txt_r {
        color: #f15046;
      }
    }
  }
  .input_box {
    margin-bottom: 12px;
    .title {
      font-size: 16px;
      margin-bottom: 8px;
      text-align: left;
    }
    .input_list {
      width: 100%;
      height: 150px;
      overflow: auto;
      border: 1px solid #d5dae3;
      border-radius: 10px;
      .task_list {
        li {
          background-color: #fff;
          display: inline-flex;
          align-items: center;
          gap: 5px;
          width: 100%;
          height: 40px;
          text-align: left;
          padding: 0 15px;
          box-sizing: border-box;
          label {
            font-size: 16px;
            cursor: pointer;
          }
          input {
            width: 13px;
            height: 13px;
            margin: 0;
          }
          &:hover {
            background-color: #00b4ed;
            label {
              color: #fff;
            }
          }
        }
      }
      .no_task {
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .input_select_button {
      display: flex;
      gap: 10px;
      padding-bottom: 20px;
      button {
        width: 90px;
        height: 45px;
        border-radius: 10px;
      }
    }
    input,
    .input_text {
      width: 100%;
      height: 40px;
      padding: 0 20px;
      border-radius: 10px;
      border: 1px solid #d5dae3;
      background-color: #fff;
      box-sizing: border-box;
    }
    select {
      width: 100%;
      background-position: center right 15px;
    }
    .input_text {
      height: auto;
      padding: 20px;
      text-align: left;
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .button_box {
    margin-bottom: 30px;
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      justify-content: space-between;
      li {
        width: calc((100% - 15px) / 4);
        height: 40px;
        div {
          display: inline-flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          border: 1px solid #d5dae3;
          background-color: #eee;
          border-radius: 10px;
          &.active_idle {
            background: #ffa800;
            color: #fff;
          }
          &.active_fail {
            background: #f15046;
            color: #fff;
          }
        }
      }
    }
  }
  .button_bottom_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    button {
      position: static;
      transform: translate(0);
    }
  }
}
#button1 {
  background-image: url($baseURL + "/arrow/redo_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}
#button2 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
#button3 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}
#button4 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(1);
}
#button5 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
#button6 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}
#button7 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(-1) scaleY(-1);
}
#button8 {
  background-image: url($baseURL + "/arrow/east_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}
#button9 {
  background-image: url($baseURL + "/arrow/redo_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}
#button10 {
  background-image: url($baseURL + "/arrow/shortcut_26dp_5F6368.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: scaleX(1) scaleY(-1);
}
#button11 {
  background-image: url($baseURL + "/arrow/forward_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(270deg);
}
#button12 {
  background-image: url($baseURL + "/arrow/forward_26dp_FFFFFF.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}
</style>
