<template>
  <div id="table_wrap" class="table_wrap">
    <div class="title_box">
      <!-- <input class="" type="text" :placeholder="labelText" disabled="true"> -->
      <div class="filter_box search_from">
        <input
          v-model="searchtxt"
          class=""
          type="text"
          :placeholder="labelText"
          :disabled="readOnly === true ? false : true"
        />
        <button
          type="button"
          class="btn_search"
          @click="searchBarClick"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      DataMapCateGory: "Data",
      parentHandlerKey: "",
      parameter: "",
      searchtxt: "",
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    vm.labelText = prop.labelText;
    vm.parameter = prop.parameter;
  },
  methods: {
    searchBarClick() {
      alert(this.searchtxt);
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
#table_wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title_box {
    height: 45px;
    position: relative;
    .search_from {
      width: 100%;
      box-sizing: border-box;
      padding-right: 50px;
      .btn_search {
        width: 50px;
      }
    }
  }
}
</style>
