<template :disabled="isDisabled">
  <div class="component">
    <div class="divide" v-for="item in components" :key="item.key">
      <component
        v-if="item && item.key && item.type"
        :is="
          item.type === 'calendar'
            ? 'CalendarBar'
            : item.type === 'search'
            ? 'SearchBar'
            : item.type === 'select'
            ? 'SelectBar'
            : item.type === 'select_value'
            ? 'SelectValueBar'
            : item.type === 'range'
            ? 'rangeViewBarVue'
            : null
        "
        :otherProp="item"
        :newWPx="otherProp.newWPx"
        :readOnlyProp="true"
        :readOnly="readOnly"
        :handlerList="handlerList"
        :dataSet = dataSet
      ></component>
    </div>
     <div style=" justify-content: right; display:flex;" v-if="useButton===true">
         <span class="select_button" @click="filterSelect">조회</span>
     </div>
  </div>
  <div>
    
  </div>
</template>

<script>
import common from "@/assets/js/common";
import CalendarBar from "../componentType/newDatePickerView.vue";
// import Datepicker from "vue3-datepicker";
import _ from "lodash";
import SearchBar from "../componentType/searchBarView.vue";
import SelectBar from "../componentType/selectBarView.vue";
import SelectValueBar from "../componentType/selectValueBarView.vue";
import rangeViewBarVue from '../componentType/rangeViewBar.vue';

export default {
  components: {
    // Datepicker,
    CalendarBar,
    SearchBar,
    SelectBar,
    SelectValueBar,
    rangeViewBarVue,
  },
  name: "FilterComponent",

  props: {
    otherProp: {
      type: Object,
      required: true, // 전달받는 값이 반드시 있어야 한다면 required를 true로 설정
    },
    editYn: {
      type: Object,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    useButton:{
      type:Boolean
    },
    dataSet:{
      type:Object
    },
    layout:{
      type:Object
    },
    readOnly:{
      type:Boolean
    }
  },

  data() {
    return {
      picked: new Date(),
      picked2: new Date(),
      divisionList: [],
      division: {},
      components: [],
      layoutComponent: {},
    };
  },

  mounted() {
    this.setRole();
    this.getDivisionList();
    this.layoutComponent = _.cloneDeep(this.otherProp);
    this.components = _.cloneDeep(this.layoutComponent.components);
  },
  methods: {
    async setRole() {
      this.manageRole = true;
      this.viewRole = true;
    },
    async getDivisionList() {
      const response = await common.apiGet("/division");
      this.divisionList = response.data.data;
      //여기에서 가져와야하는데..
    },
    setDivision(event) {
      this.division["name"] = event.target.value;
    },
    filterSelect(){
        this.$emit('filterSelectUse','');
    }
  },
  computed: {
    isDisabled() {
      return this.editYn === "N";
    },
  },
  //.filter_box  widtch 값을 drag 할떄 고정되는 width 값으로 설정하도록 변경해야함.
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.comp_line {
  display: flex;
  width: 100%;
  height: auto;
  min-height: 40px;
  flex-direction: row;
}
.comp_line .data_key {
  flex-grow: 1;
  height: 40px;
  line-height: 40px;
  padding-right: 20px;
}
.comp_line .data_val {
  flex-grow: 4;
}
.comp_line .data_val select {
  width: 100%;
  height: 40px;
  background-position: right 10px center;
}
</style>

<style>
.datePeriod {
  width: 100%;
  height: 40px;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  position: relative;
  padding-right: 80px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: black;
  text-align: Center;
}
.datePeriod button.dateon {
  width: 60px;
  height: 38px;
  border: none;
  border-left: 1px solid #d4d4d4;
  background: #f4f4f4;
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.datePeriod input[type="text"] {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
}
.divide {
  margin-bottom: 30px;
}
.component {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
.v3dp__datepicker {
  width: calc(50% - 14px);
  height: 30px;
  border: 0px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: black;
  display: inline-block;
  vertical-align: middle;
}
.v3dp__input_wrapper {
  width: 100% !important;
}
.v3dp__input_wrapper input[type="text"] {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #d4d4d4 !important;
}
.select_button {
  display: block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  background: #00b4ed;
  color: white;
  font-size: 14px;
  font-weight: normal;
  border-radius: 4px;
  cursor: pointer;
}
</style>
