<template>
    <div style="width:100%; margin-left: 7%;margin-right: 7%;">
        <div>{{labelText}}</div>
        <div class="slider-value">{{ this.start }} ~{{this.end}}</div>
        <div ref="slider"></div>
    </div>
</template>

<script>
import noUiSlider from "nouislider";
import 'nouislider/dist/nouislider.css';

export default {
     props: {
      otherProp: {
        type: Object,
        required: true,
      },
      readOnlyProp:{
        type: Boolean,
        require:true,
      },
      handlerList:{
        type:Object,
        require:true
      },
      componentIndex:{
        type:Number
      },
      keyValueList:{
        type:Object
      },
      keyValueType:{
        type:String
      }
    },
    data() {
        return {
            labelText:'',
            handlerKey :'',
            color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
            picStartDate: null,
            picEndDate: null,
            start_cal_key:0,
            end_cal_key:0,
            picStartDateCalendar: null,
            picEndDateCalendar: null,
            picColor: {
                startStatus: false,
                endStatus: false,
                active: "#00B4ED",
                default: "#020A0A",
            },
            onlyRead : false,
            remove : false,
            DataMapCateGory:'Data',
            parentHandlerKey : '',
            barKeyValueType:'',
            inputKey:'',
            inputValue:'',
            barKeyValueList:[],
            startRange : 0,
            endRange : 0,
            step:  0,
            start : 0,
            end : 0 ,

        };
    },
    mounted(){

        
        const vm = this;
        const prop = this.otherProp;
        const range = prop.range;
        vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
        vm.handlerKey = prop.handlerKey; //handlerKey
        if(vm.parentHandlerKey!==undefined){
          vm.parentHandlerKey = prop.parentHandlerKey; // 부모키 
        }
        console.log(range)
        vm.labelText = prop.labelText;
        vm.start = range.startRange;
        vm.end = range.endRange;
        vm.step = range.step;      

        const slider = this.$refs.slider;
        noUiSlider.create(slider, {
        start: [range.startRange, range.endRange],  // 초기 값
        connect: true,    // 범위 연결
        range: {
            min: range.startRange,
            max: range.endRange
        },
        step:range.step,
         pips: {                 // 눈금 표시
        mode: 'steps',        // step 값에 맞춰 눈금을 표시
        density: 1           // 눈금 간격 조정 (높을수록 촘촘해짐)
      }
        });

        // 슬라이더 값이 변경될 때 이벤트 발생 (update)
        slider.noUiSlider.on('update', (values) => {
        this.start = Math.round(values[0]);  // 첫 번째 핸들 값 (start)
        this.end = Math.round(values[1]);    // 두 번째 핸들 값 (end)
        });
    },
    methods:{
      updateParent(event){
        this.$emit('updateParent',{index:this.componentIndex, value:event.target.value})
        console.log(this.handlerList)
     },
      labelTextChange(event){
      this.labelText =  event.target.value;
      const item  = this.otherProp;
      item.labelText = this.labelText;
      },

      removeItem(){
        this.$emit('removeItem');
      },
      updateRange(){
        const range = {
            startRange : this.startRange,
            endRange : this.endRange,
            step : this.step
        }
        this.$emit('updateRange',{index:this.componentIndex, value:range})
      },
    },
    watch:{
    }
}
</script>

<style scoped lang="scss">
.list_filter_wrap {
  height: 80px;
  overflow: visible;
  .filter_list_box {
    &::after {
      border-radius: 10px;
    }
  }
}
.chart_wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  .chart {
    width: 100%;
  }
}
.code_search_table {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  & > div {
    flex: 1;
  }

  select {
    width: 100%;
    height: 45px;
    background-position: 96%;
  }
}
.filter_box {
  height:100%;
  box-sizing: border-box;
  .search_from_box {
    position: absolute;
    right: -120px;
    .btn_send {
      height: 40px;
      padding: 0 15px;
      border-radius: 10px;
      line-height: 37px;
      font-size: 15px;
    }
  }
  .date_filter {
    position: relative;
    margin-bottom:15px;
    width: 100%;
    height: 100%;
    display: flex;
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    padding-left: 0;
    &::after {
      display: none;
    }
    .date_period {
      width: 240px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_first{
      width: 335px;
      left: 10px;
      display: flex;
      justify-content: center; /* 가로 중앙 정렬 */
      align-items: center; /* 세로 중앙 정렬 */
      text-align: center; /* 텍스트 가로 정렬 */
    }
    .date_period_second{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }
}
</style>


