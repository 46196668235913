<template>
  <div class="table_wrap">
    <div v-if="dataMapCategory === 'Data'">
      <select
        style="width: 200px; background-position: 178px center"
        name="prjType"
        ref="prjType"
      >
        <option v-for="item in dataSet" :key="item.key">
          {{ item.value }}
        </option>
      </select>
    </div>
    <div v-if="dataMapCategory === 'API'">
      <select
        style="width: 200px; background-position: 178px center"
        name="prjType"
        ref="prjType"
      >
        <option v-for="item in apiSet" :key="item.key">{{ item.value }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    otherProp: {
      type: Object,
      required: true,
    },
    readOnlyProp: {
      type: Boolean,
      require: true,
    },
    handlerList: {
      type: Object,
      require: true,
    },
    componentIndex: {
      type: Number,
    },
  },
  data() {
    return {
      labelText: "",
      handlerKey: "",
      color: ["#36a2eb", "#ff6384", "#ff9f40", "#ffcd56", "#4bc0c0"],
      picStartDate: null,
      picEndDate: null,
      start_cal_key: 0,
      end_cal_key: 0,
      picStartDateCalendar: null,
      picEndDateCalendar: null,
      picColor: {
        startStatus: false,
        endStatus: false,
        active: "#00B4ED",
        default: "#020A0A",
      },
      onlyRead: false,
      remove: false,
      dataMapCategory: "Data",
      parentHandlerKey: "",
      parameter: "",
      dataSet: [],
      apiSet: [],
    };
  },
  mounted() {
    const vm = this;
    const prop = this.otherProp;
    vm.onlyRead = prop.readOnlyProp; //읽기전용 체크
    vm.handlerKey = prop.handlerKey; //handlerKey
    if (vm.parentHandlerKey !== undefined) {
      vm.parentHandlerKey = prop.parentHandlerKey; // 부모키
    }
    vm.labelText = prop.labelText;
    vm.parameter = prop.parameter;
    console.log("selectView");
    console.log(prop);

    if (prop.dataMapCategory !== undefined) {
      vm.dataMapCategory = prop.dataMapCategory;
    }

    vm.dataSet.push({ key: prop.labelText, value: prop.labelText });
    vm.apiSet.push({ key: prop.labelText, value: prop.labelText });
    if (prop.dataSet !== undefined) {
      vm.dataSet.push(...prop.dataSet);
    }
    console.log(prop.dataSetCustom)
    // if(prop.dataSetCustom!==undefined){
    
    // }
    
  },
  methods: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.table_wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title_box {
    height: 45px;
    position: relative;
    margin-bottom: 20px;
    .search_from {
      position: absolute !important;
      padding-right: 40px !important;
      .btn_search {
        width: 40px;
      }
    }
  }
}
</style>
